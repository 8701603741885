import { css } from '@emotion/react';
import { NString, Nullable, TextSubtitle2RBMixin, TextSubtitle3RMMixin, WidgetLayout } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Marketing } from '../../../../marketing/marketing';
import { media } from '../../../../ui/global/media-query';
import { genPaymentBillingPath } from '../../../../utils/formatter/path.formatter';
import { PlanT } from '../../payment.types';
import { getMixpanelPlanName } from '../../utils/get-mixpanel-plan-name-utils';
import { PlanExtraFeature, TreasuryPaymentsGame } from '../../../../__generated/treasury/types';
import { tftFreeEgg } from '../../../../utils/formatter/images';
import { Benefits } from './benefits/benefits.component';
import { NextPayment } from './next-payment/next-payment.component';
import { PlansList } from './plans-list/plans-list.component';
import { PricePerMonth } from './price-per-month/price-per-month.component';
import { TotalPrice } from './total-price/total-price.component';

interface Props {
  className?: string;
  game: TreasuryPaymentsGame;
  plan: PlanT;
  currentPlanId: NString;
  plans: Nullable<PlanT[]>;
}

export const PlanDetails: React.FC<Props> = props => {
  const { className, game, plan, plans, currentPlanId } = props;
  const { t } = useTranslation();
  const [isPlanSelectionVisible, setIsPlanSelectionVisible] = React.useState(false);
  const { push } = useHistory();
  const { search } = useLocation();
  const isTftFreeEggPlan = plan.planFeatures?.find(it => it === PlanExtraFeature.EGG_SKIN);
  return (
    <WidgetLayout className={className} css={wrapperCss} bodyOverrideCss={widgetLayoutBodyCss}>
      {isTftFreeEggPlan && <img src={tftFreeEgg()} alt="" css={eggImageCss} />}
      <div css={contentCss}>
        <div css={lineCss}>
          <p css={headerCss}>{t('Plan summary')}</p>
          {plans && plans.length > 1 && (
            <span
              css={[ctaCss, isPlanSelectionVisible && ctaActiveCss]}
              onClick={() => setIsPlanSelectionVisible(true)}
            >
              {t('Change')}
            </span>
          )}
        </div>
        <PricePerMonth plan={plan} />
        <Benefits plan={plan} />
        <div css={delimiterCss} />
        <TotalPrice plan={plan} />
      </div>
      <NextPayment css={nextPaymentCss} plan={plan} />
      {isPlanSelectionVisible && plans && (
        <PlansList
          plans={plans}
          selectedPlanId={plan.id}
          currentPlanId={currentPlanId}
          onSelect={(plan: PlanT) => {
            Marketing.planChanged(getMixpanelPlanName(plan, game, t));
            setIsPlanSelectionVisible(false);
            push(genPaymentBillingPath(plan.id, search));
          }}
          onClose={() => setIsPlanSelectionVisible(false)}
          css={plansCss}
        />
      )}
    </WidgetLayout>
  );
};

const wrapperCss = css`
  flex: 1 1 0%;
  max-width: 400px;
  width: 100%;
  min-height: 456px;
  overflow: hidden;
  ${media.medium} {
    min-height: auto;
  }
`;

const contentCss = css`
  position: relative;
`;

const widgetLayoutBodyCss = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  padding: 40px;
  flex: 1 1 auto;
  ${media.medium} {
    padding: 16px 12px;
  }
`;

const plansCss = css`
  position: absolute;
  left: 24px;
  right: 24px;
  top: 80px;
  ${media.medium} {
    top: 52px;
  }
`;

const lineCss = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const headerCss = css`
  ${TextSubtitle2RBMixin};
`;

const ctaCss = css`
  ${TextSubtitle3RMMixin};
  cursor: pointer;
  color: var(--general-grey-300);
`;

const ctaActiveCss = css`
  color: var(--general-white-500);
`;

const delimiterCss = css`
  width: 100%;
  height: 1px;
  background: var(--general-grey-300);
  opacity: 0.3;
  margin: 24px 0;
`;

const nextPaymentCss = css`
  ${media.medium} {
    display: none;
  }
`;

const eggImageCss = css`
  position: absolute;
  bottom: 0;
  right: -40px;
  width: 226px;
  height: 226px;
`;
