import { filterNonNull, Nullable, numberComparator, processUnionEntries } from '@mobalytics/shared';
import i18next from 'i18next';
import { PlanFragment } from '../_gql/fragments/__generated/plan.fragment.gql.generated';
import { processPlan } from './process-plans.utils';

export const processPlansInfo = (plans: Nullable<PlanFragment[]>, t: i18next.TFunction) => {
  const rawPlans = processUnionEntries<PlanFragment>(plans, 'TreasuryPaymentsPlan');
  const allPlans = rawPlans?.map(plan => processPlan(plan, t)).filter(filterNonNull);
  const regularPlans = allPlans
    ?.filter(plan => !plan?.isTrial)
    .sort((a, b) => numberComparator(a.basePrice, b.basePrice));
  const plansWithDiscount = regularPlans?.filter(plan => plan?.discount?.coupon) ?? null;

  return {
    allPlans,
    plans: regularPlans,
    plansWithDiscount,
  };
};
