import * as React from 'react';
import { Loader, NString } from '@mobalytics/shared';
import { useTranslation } from 'react-i18next';
import { TreasuryPaymentsGame } from '../../../../../../__generated/treasury/types';
import { AllowedActionsT } from '../../../../payment.types';
import { StripeExistingFlowConfirmation } from '../stripe-existing-flow/stripe-existing-flow-confirmation/stripe-existing-flow-confirmation.component';
import { StripeFlowElementsProvider } from '../stripe-flow-elements-provider.component';
import { useStripeFlowConfirmationInfo } from '../stripe-flow-confirmation-info.hook';
import { MascotLayout } from '../../../../../../ui/layout/mascot-layout/mascot-layout.component';

interface Props {
  className?: string;
  planId: string;
  game: TreasuryPaymentsGame;
  allowedActions: AllowedActionsT;
  coupon: NString;
}

export const StripeFlowProcessing: React.FC<Props> = props => {
  const { allowedActions, planId, game, coupon } = props;
  const { t } = useTranslation();
  const { confirmationInfo, fetchConfirmationInfo } = useStripeFlowConfirmationInfo({
    allowedActions,
    planId,
    game,
    coupon,
  });

  React.useEffect(() => {
    fetchConfirmationInfo(false);
  }, [fetchConfirmationInfo]);

  if (confirmationInfo)
    return (
      <StripeFlowElementsProvider confirmationInfo={confirmationInfo}>
        <MascotLayout
          title={t('Processing payment')}
          description={t('Please wait for a moment...')}
          mascotEmotion="calm"
        >
          <Loader />
        </MascotLayout>
        <StripeExistingFlowConfirmation confirmationInfo={confirmationInfo} />
      </StripeFlowElementsProvider>
    );

  return (
    <MascotLayout title={t('Processing payment')} description={t('Please wait for a moment...')} mascotEmotion="calm">
      <Loader />
    </MascotLayout>
  );
};
