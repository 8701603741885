import { figmaIcon, Input } from '@mobalytics/shared';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStore } from '../../../store/app-store.hook';
import { AccountsAppPath } from '../../../types/app.types';
import { AuthTabs } from '../../../ui/blocks/auth-tabs/auth-tabs.component';
import { AccountsButton } from '../../../ui/buttons/accounts-button/accounts-button.component';
import { FormError } from '../../../ui/layout/form-layout';
import { FormBody } from '../../../ui/layout/form-layout/form-body.component';
import { FormControlls } from '../../../ui/layout/form-layout/form-controlls.component';
import { FormLayout } from '../../../ui/layout/form-layout/form-layout.component';
import { FormSeparator } from '../../../ui/layout/form-layout/form-separator.component';
import { ConnectProviderForm } from '../connect-provider-form/connect-provider-form.component';
import { TermsAndEmailsAgreementCheckbox } from '../terms-and-service-agreement-checkbox/terms-and-service-agreement-checkbox.component';

interface Props {
  className?: string;
}

export const SignUpForm: React.FC<Props> = observer(props => {
  const { className } = props;
  const { t } = useTranslation();

  const { inputStore, authStore } = useAppStore();
  const { isLoading, name, nameError, onChangeName, termsAndServiceAgreement, reset } = inputStore;
  const { email, emailError, onChangeEmail } = inputStore;
  const { password, passwordError, onChangePassword } = inputStore;
  const { generalError } = inputStore;
  const { signUp } = authStore;

  React.useEffect(() => reset, [reset]);

  const submitHandler = React.useCallback(
    (e: React.FormEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      signUp({});
    },
    [signUp]
  );

  return (
    <FormLayout className={className}>
      <AuthTabs activeTab={AccountsAppPath.SIGN_UP} />
      <ConnectProviderForm />
      <FormSeparator>{t('or use email')}</FormSeparator>
      {generalError && <FormError>{t(generalError)}</FormError>}
      <FormBody onSubmit={submitHandler}>
        <FormControlls>
          <Input
            id="email"
            value={email}
            placeholder={t('Email')}
            onChange={onChangeEmail}
            iconUrl={figmaIcon('system-icons', 'email')}
            errorMessage={emailError ? t(emailError) : undefined}
            autoComplete="current-email"
            name="current-email"
            disabled={isLoading}
          />
          <Input
            id="name"
            value={name}
            placeholder={t('ui-your-name')}
            onChange={onChangeName}
            iconUrl={figmaIcon('system-icons', '24-user')}
            errorMessage={nameError ? t(nameError) : undefined}
            autoComplete="off"
            disabled={isLoading}
          />
          <Input
            id="password"
            type="password"
            iconUrl={figmaIcon('system-icons', '24-locked')}
            value={password}
            placeholder={t('ui-password')}
            onChange={onChangePassword}
            errorMessage={passwordError ? t(passwordError) : undefined}
            autoComplete="current-password"
            name="current-password"
            disabled={isLoading}
          />
        </FormControlls>
        <AccountsButton
          analyticsButtonName="Sign Up"
          disabled={!!(emailError || passwordError || nameError || !termsAndServiceAgreement)}
          type="submit"
          loading={isLoading}
        >
          {t('ui-sign-up')}
        </AccountsButton>
        <TermsAndEmailsAgreementCheckbox />
      </FormBody>
    </FormLayout>
  );
});
