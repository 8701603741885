import { css } from '@emotion/react';
import { TextButton2CAPSRBMixin, TextSubtitle3RMMixin } from '@mobalytics/shared';
import { AllowedActionsT, PlanT } from '../../pages/payment/payment.types';
import { PlanExtraFeature } from '../../__generated/treasury/types';
import { lolFreeSkin, tftFreeEgg } from '../../utils/formatter/images';
import { Duration } from './duration/duration.component';
import { CouponController } from './discount-controller/discount-controller.component';
import { PricePerMonth } from './price-per-month/price-per-month.component';
import { PriceDetailsController } from './price-details-controller/price-details-controller.component';
import { ButtonController } from './button-controller/button-controller.component';

interface Props {
  className?: string;
  plan: PlanT;
  isCurrentPlan: boolean;
  onClick: (plan: PlanT) => void;
  allowedActions: AllowedActionsT;
}

export const PlanCard = (props: Props) => {
  const { className, plan, isCurrentPlan, allowedActions, onClick } = props;
  const isTftFreeEggPlan = plan.planFeatures?.find(it => it === PlanExtraFeature.EGG_SKIN);
  const isLolFreeSkinPlan = plan.planFeatures?.find(it => it === PlanExtraFeature.SKIN_CODE);
  return (
    <div className={className} css={[wrapperCss, plan.bestChoiceLabel && wrapperBestChoiceCss]}>
      {!!plan.bestChoiceLabel && <div css={bestChoiceCss}>{plan.bestChoiceLabel}</div>}
      <div css={[contentCss, plan.bestChoiceLabel && contentBestChoiceCss]}>
        {isTftFreeEggPlan && <img src={tftFreeEgg()} alt="" css={eggImageCss} />}
        {isLolFreeSkinPlan && <img src={lolFreeSkin()} alt="" css={skinImageCss} />}
        <div css={slotCss}>
          <Duration interval={plan.interval} intervalCount={plan.intervalCount} />
        </div>
        <div css={slotCss}>
          <CouponController
            couponDiscount={plan.discount.coupon}
            delimiterVariant={isLolFreeSkinPlan ? 'bright' : 'dark'}
          />
        </div>
        <div css={slotCss}>
          <PricePerMonth
            hasDiscount={!!plan.discount.coupon}
            valueInCents={plan.finalPricePerMonth}
            currency={plan.currency}
          />
        </div>
        <div css={[slotCss, footerCss, (isTftFreeEggPlan || isLolFreeSkinPlan) && specialFooterCss]}>
          <PriceDetailsController plan={plan} />
        </div>
        <div>
          <ButtonController
            plan={plan}
            onClick={() => onClick(plan)}
            isCurrentPlan={isCurrentPlan}
            allowedActions={allowedActions}
          />
        </div>
      </div>
    </div>
  );
};

const wrapperCss = css`
  width: 250px;
  border: 1px solid var(--general-base-100);
  border-radius: 6px;
  box-shadow: 0 4px 10px rgba(21, 11, 37, 0.5);
  position: relative;
`;

const wrapperBestChoiceCss = css`
  position: relative;
  background-clip: border-box;
  border-color: transparent;
  border-radius: 0 0 6px 6px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -1px;
    border-radius: 6px;
    background: linear-gradient(to right, #fb571e, #f9942b);
  }
`;

const contentCss = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;
  position: relative;
  border-radius: inherit;
  background-color: var(--general-base-200);
  overflow: hidden;
`;

const slotCss = css`
  display: flex;
  justify-content: center;
  position: relative;
`;

const contentBestChoiceCss = css`
  border-radius: inherit;
  background: linear-gradient(180deg, #2d2155 0%, #403663 100%);
`;

const bestChoiceCss = css`
  ${TextButton2CAPSRBMixin};
  text-align: center;
  position: relative;
`;

const footerCss = css`
  ${TextSubtitle3RMMixin};
  color: var(--general-grey-200);
  margin-top: 48px;
  margin-bottom: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 20px;
  position: relative;
`;

const specialFooterCss = css`
  color: var(--general-white-100);
`;

const eggImageCss = css`
  position: absolute;
  bottom: 0;
  right: -40px;
  width: 226px;
  height: 226px;
`;

const skinImageCss = css`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
`;
