import { oldPaymentsApolloClient } from '../../../old-payments-apollo-client';
import { useAccountQuery } from '../../../api/accounts/query/__generated/account-query.gql.generated';

export const useAccount = () => {
  const { data, loading } = useAccountQuery({
    client: oldPaymentsApolloClient,
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const isDataLoaded = !!data && !loading;
  const account = data?.account;

  return { account, isDataLoaded };
};
