import { css } from '@emotion/react';
import { TextSubtitle3RMMixin } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { usePaymentFilters } from '../../hooks/payment-filters.hook';
import { useCoupon } from './coupon.hook';
import { ApplyCouponForm } from './apply-coupon-form/apply-coupon-form.component';

interface Props {
  className?: string;
  isCouponApplied: boolean;
  loading: boolean;
}

export const ApplyCoupon: React.FC<Props> = props => {
  const { className, isCouponApplied, loading } = props;
  const { t } = useTranslation();
  const { couponQueryParam } = usePaymentFilters();
  const { couponField, couponFieldState, setIsFormVisible, isFormVisible, applyCoupon, removeCoupon } = useCoupon({
    coupon: couponQueryParam,
    loading,
    isCouponApplied,
  });

  const onClickOutside = () => {
    setIsFormVisible(false);
  };

  if (isFormVisible) {
    return (
      <div className={className}>
        <ApplyCouponForm
          couponField={couponField}
          couponFieldState={couponFieldState}
          couponLoading={loading}
          onApplyCoupon={applyCoupon}
          onClickOutside={onClickOutside}
        />
      </div>
    );
  }
  if (isCouponApplied) {
    return (
      <div className={className}>
        <p css={labelCss} onClick={removeCoupon}>
          {t('Remove coupon?')}
        </p>
      </div>
    );
  }

  return (
    <div className={className}>
      <p onClick={() => setIsFormVisible(true)} css={labelCss}>
        {t('Do you have a coupon?')}
      </p>
    </div>
  );
};

const labelCss = css`
  ${TextSubtitle3RMMixin};
  color: var(--general-grey-300);
  cursor: pointer;
  &:hover {
    color: var(--general-gold-300);
  }
`;
