import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NString } from '@mobalytics/shared';
import { PaypalOverviewInfo } from '../paypal-overview-info/paypal-overview-info.component';
import { PaymentConfirmationLayout } from '../../../payment-confirmation-layout/payment-confirmation-layout.component';
import { TreasuryPaymentsGame } from '../../../../../../__generated/treasury/types';
import { AllowedActionsT, BillingInfoT } from '../../../../payment.types';
import { usePaypalFlowExisting } from './paypal-flow-existing.hook';

interface Props {
  className?: string;
  planId: NString;
  game: TreasuryPaymentsGame;
  coupon: NString;
  allowedActions: AllowedActionsT;
  paypal: BillingInfoT['paypal'];
  submitButtonLabel: string;
}

export const PaypalFlowExisting: React.FC<Props> = props => {
  const { className, game, coupon, allowedActions, submitButtonLabel, paypal, planId } = props;
  const { loading, processPayment } = usePaypalFlowExisting({ planId, game, coupon, allowedActions });
  const { t } = useTranslation();
  return (
    (paypal && (
      <PaymentConfirmationLayout
        className={className}
        header={t('Your payment details')}
        loading={loading}
        buttonLabel={submitButtonLabel}
        onSubmit={processPayment}
        errorMessage={null}
      >
        <PaypalOverviewInfo email={paypal.email} />
      </PaymentConfirmationLayout>
    )) ||
    null
  );
};
