import { css } from '@emotion/react';
import { NString } from '@mobalytics/shared';
import { observer } from 'mobx-react-lite';
import * as queryString from 'query-string';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DESKTOP_REDIRECT_OPEN_URL } from '../../../config';
import { useAppStore } from '../../../store/app-store.hook';
import { FormBody } from '../../../ui/layout/form-layout/form-body.component';
import { FormFooter } from '../../../ui/layout/form-layout/form-footer.component';
import { FormLayout } from '../../../ui/layout/form-layout/form-layout.component';
import { formLinkButtonCss } from '../../../ui/layout/form-layout/form-link-button.component';
import { FormSubTitle } from '../../../ui/layout/form-layout/form-subtitle.component';
import { FormTitle } from '../../../ui/layout/form-layout/form-title.component';
import { CopyContainer } from './copy-container/copy-container.component';

interface DesktopSignInRedirectFormProps {
  className?: string;
}

export const DesktopSignInRedirectForm: React.FC<DesktopSignInRedirectFormProps> = observer(props => {
  const { t } = useTranslation();
  const { routerStore } = useAppStore();
  const { className } = props;
  const [isManualMode, setManualMode] = React.useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = React.useState<string | null>(null);

  React.useEffect(() => {
    const query = queryString.parse(window.location.search) as { redirect_uri: NString };
    query.redirect_uri ? setRedirectUrl(query.redirect_uri) : setRedirectUrl(`${DESKTOP_REDIRECT_OPEN_URL}`);
  }, [setRedirectUrl, routerStore]);

  React.useEffect(() => {
    redirectUrl && (window.location.href = redirectUrl);
  }, [redirectUrl]);

  const toggleManualModeHandler = React.useCallback(() => {
    setManualMode(!isManualMode);
  }, [isManualMode, setManualMode]);

  const tryAgainHandler = React.useCallback(() => {
    redirectUrl && (window.location.href = redirectUrl);
  }, [redirectUrl]);

  return isManualMode ? (
    <FormLayout className={className}>
      <FormTitle>{t('ui-desktop-sign-in')}</FormTitle>
      <FormSubTitle>{t('ui-desktop-sign-in-manual-description-1')}</FormSubTitle>
      <FormSubTitle>{t('ui-desktop-sign-in-manual-description-2')}</FormSubTitle>
      <FormSubTitle>{t('ui-desktop-sign-in-manual-description-3')}</FormSubTitle>
      <FormBody>
        <p onClick={tryAgainHandler} css={redirectLinkUrl}>
          {redirectUrl}
        </p>
      </FormBody>
      {redirectUrl && <CopyContainer redirectUrl={redirectUrl} />}
    </FormLayout>
  ) : (
    <>
      <FormLayout className={className}>
        <FormTitle>{t('ui-desktop-sign-in-redirect')}</FormTitle>
        <FormSubTitle>{t('ui-desktop-sign-in-redirect-description')}</FormSubTitle>
      </FormLayout>
      <FormFooter css={formFooterCss}>
        <p>
          {t('ui-app-did-not-open')}
          <span onClick={tryAgainHandler} css={formLinkButtonCss}>
            {t('ui-try-again')}
          </span>
          {t('ui-or')}
          <span onClick={toggleManualModeHandler} css={formLinkButtonCss}>
            {t('ui-make-it-manual')}
          </span>
        </p>
      </FormFooter>
    </>
  );
});

const formFooterCss = css`
  margin-bottom: 16px;
`;

const redirectLinkUrl = css`
  ${formLinkButtonCss};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
