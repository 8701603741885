import { ButtonColor } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AccountsButton } from '../../../buttons/accounts-button/accounts-button.component';

interface Props {
  className?: string;
  isBestChoice: boolean;
  onClick?: () => void;
}

export const ChangeButton: React.FC<Props> = props => {
  const { className, isBestChoice, onClick } = props;
  const { t } = useTranslation();
  return (
    <AccountsButton
      analyticsButtonName="Change plan"
      className={className}
      onClick={onClick}
      color={isBestChoice ? ButtonColor.Plus : ButtonColor.Secondary}
    >
      {t('change plan')}
    </AccountsButton>
  );
};
