import { figmaIcon, Input, Link } from '@mobalytics/shared';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useAppStore } from '../../../store/app-store.hook';
import { GQLAPIErrorMessage } from '../../../types/error.types';
import { AccountsButton } from '../../../ui/buttons/accounts-button/accounts-button.component';
import { FormError } from '../../../ui/layout/form-layout';
import { FormBody } from '../../../ui/layout/form-layout/form-body.component';
import { FormLayout } from '../../../ui/layout/form-layout/form-layout.component';
import { FormSubTitle } from '../../../ui/layout/form-layout/form-subtitle.component';
import { FormTitle } from '../../../ui/layout/form-layout/form-title.component';
import { genSignInPath } from '../../../utils/formatter/path.formatter';
import { parseAppQueryParams } from '../../../utils/url';

interface SignUpDetailsFormProps {
  className?: string;
}

export const SignUpDetailsForm: React.FC<SignUpDetailsFormProps> = observer((props: SignUpDetailsFormProps) => {
  const { className } = props;
  const { t } = useTranslation();
  const { inputStore, authStore } = useAppStore();
  const { email, emailError, emailErrorCode, onChangeEmail } = inputStore;
  const { isLoading, generalError, reset } = inputStore;
  const { termsAndServiceAgreement } = inputStore;
  const { sendSignUpDetails } = authStore;
  const { search } = useLocation();

  const onSubmitHandler = React.useCallback(
    (e: React.FormEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const queryParams = parseAppQueryParams();
      sendSignUpDetails(queryParams.name || '', queryParams.continue_from || '');
    },
    [sendSignUpDetails]
  );

  React.useEffect(() => reset, [reset]);

  return (
    <FormLayout className={className}>
      <FormTitle>{t('ui-sign-up-details')}</FormTitle>
      <FormSubTitle>{t('ui-sign-up-details-description')}</FormSubTitle>

      {generalError && <FormError>{t(generalError)}</FormError>}
      {emailError && emailErrorCode === GQLAPIErrorMessage.EMAIL_ALREADY_EXISTS && (
        <FormError>
          <span>{t('error-email-already-exists')}.&nbsp;</span>
          <span>{t('ui-if-it-is-your-account-please')}</span>
          <Link to={genSignInPath(search)}>{t('ui-sign-in')}.</Link>
        </FormError>
      )}
      <FormBody onSubmit={onSubmitHandler}>
        <Input
          id={'email'}
          value={email}
          placeholder={t('Email')}
          onChange={onChangeEmail}
          iconUrl={figmaIcon('system-icons', 'email')}
          errorMessage={
            emailError && emailErrorCode !== GQLAPIErrorMessage.EMAIL_ALREADY_EXISTS ? t(emailError) : undefined
          }
          autoComplete="current-email"
          name="current-email"
          disabled={isLoading}
        />
        <AccountsButton
          analyticsButtonName="Provide email"
          type="submit"
          disabled={!!(emailError || isLoading || !termsAndServiceAgreement)}
        >
          {t('ui-proceed')}
        </AccountsButton>
      </FormBody>
    </FormLayout>
  );
});
