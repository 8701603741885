import { css } from '@emotion/react';
import { HBoxCCMixin } from '@mobalytics/shared';
import * as React from 'react';
import { ErrorForm } from '../../components/forms/error-form/error-form.component';
import { PageMeta } from '../../components/page-meta';

export const Error: React.FC = () => {
  return (
    <div css={wrapperCss}>
      <PageMeta title="ui-error" description="ui-error" />
      <ErrorForm />
    </div>
  );
};

const wrapperCss = css`
  ${HBoxCCMixin};
  width: 100%;
`;
