import { css } from '@emotion/react';
import {
  colorToF2BF43Filter,
  figmaIcon,
  linkButtonStyles,
  Nullable,
  TextBody2RRMixin,
  TextSubtitle2RBMixin,
  TextSubtitle3RMMixin,
} from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Marketing } from '../../../../marketing/marketing';
import { media } from '../../../../ui/global/media-query';
import { genPaymentBillingPath } from '../../../../utils/formatter/path.formatter';
import { AvailableStepT, PlanT } from '../../payment.types';
import { getIntervalName } from '../../utils/get-interval-name-utils';
import { getMoneyAsString } from '../../utils/get-money-as-string.utils';
import { getMixpanelPlanName } from '../../utils/get-mixpanel-plan-name-utils';
import { OneTimeOfferModal } from '../one-time-offer-modal/one-time-offer-modal.component';
import { useTrialOneTimeOffer } from '../../hooks/trial-one-time-offer.hook';
import { TreasuryFlowInfoData, TreasuryPaymentsGame } from '../../../../__generated/treasury/types';

interface Props {
  className?: string;
  plan: PlanT;
  game: TreasuryPaymentsGame;
  flowInfo: Nullable<TreasuryFlowInfoData>;
  flowStep: Nullable<AvailableStepT>;
  refetchWithCoupon: (coupon: string, game: TreasuryPaymentsGame) => Promise<unknown>;
}

export const TrialBlock: React.FC<Props> = props => {
  const { className, plan, game, flowStep, flowInfo, refetchWithCoupon } = props;
  const { t } = useTranslation();
  const { search } = useLocation();
  const { push } = useHistory();
  const { offerCoupon, isOneTimeOfferAvailable, offerPlanId, offerPlan, onApplyCoupon } = useTrialOneTimeOffer({
    flowStep,
    flowInfo,
  });
  const [isOneTimeOfferModalOpen, setOneTimeOfferModalOpen] = React.useState(false);

  const price = getMoneyAsString(plan.currency, plan.finalPricePerMonth);
  const intervalName = getIntervalName(plan.interval, plan.intervalCount, t);

  const onTrialClick = async () => {
    if (isOneTimeOfferAvailable && offerCoupon && offerPlanId) {
      await onApplyCoupon(offerCoupon, game);
      setOneTimeOfferModalOpen(true);
    } else {
      Marketing.trialPlanSelected(getMixpanelPlanName(plan, game, t));
      push(genPaymentBillingPath(plan.id, search));
    }
  };

  const onApplyOffer = async () => {
    if (offerCoupon && offerPlanId) {
      await refetchWithCoupon(offerCoupon, game);
    }
  };

  const onRejectOffer = () => {
    Marketing.trialPlanSelected(getMixpanelPlanName(plan, game, t));
    push(genPaymentBillingPath(plan.id, search));
  };

  return (
    <>
      {isOneTimeOfferModalOpen && offerPlan && (
        <OneTimeOfferModal plan={offerPlan} game={game} onApplyOffer={onApplyOffer} onRejectOffer={onRejectOffer} />
      )}
      <div className={className} css={wrapperCss}>
        <div>
          <p css={titleCss}>{t('FREE 7-day Trial')}</p>
          <div css={subTitleCss}>
            {t('After the free trial, your {{price}} {{interval}} subscription will begin.', {
              price: price,
              interval: intervalName,
            })}
          </div>
        </div>
        <div css={linkCss} onClick={onTrialClick}>
          <p css={linkTextCss}>{t('try for free')}</p>
          <img src={figmaIcon('system-icons', 'right-line-arrow')} alt="" />
        </div>
      </div>
    </>
  );
};

const wrapperCss = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border: 1px solid var(--general-base-100);
  border-radius: 6px;
  background-color: var(--general-base-200);
  box-shadow: 0px 4px 10px rgba(21, 11, 37, 0.5);
  ${media.xsmall} {
    flex-direction: column;
    text-align: center;
  }
`;

const titleCss = css`
  ${TextSubtitle2RBMixin};
`;

const subTitleCss = css`
  ${TextBody2RRMixin};
  color: var(--general-grey-100);
  margin-top: 8px;
`;

const linkCss = css`
  ${linkButtonStyles};
  ${TextSubtitle3RMMixin};
  filter: ${colorToF2BF43Filter};
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  ${media.xsmall} {
    margin-top: 24px;
  }
`;

const linkTextCss = css`
  &:first-letter {
    text-transform: uppercase;
  }
`;
