import { action, computed, observable } from 'mobx';
import { AppThemeConfig } from '../types/app.types';
import { TestimonialItem } from '../types/testimonials.types';
import { Theme } from '../types/theme';

export class ConfigStore {
  @observable theme: Theme = Theme.Default;
  @observable config: AppThemeConfig | null = null;

  constructor(theme: Theme) {
    this.setTheme(theme);
  }

  @action
  setConfig = (config: AppThemeConfig) => {
    this.config = config;
  };

  @action
  setTheme = (theme: Theme) => {
    this.theme = theme;
  };

  @computed
  get wallpaper(): string | null {
    return this.config ? this.config.wallpaper : null;
  }

  @computed
  get paymentWallpaper(): string {
    return this.config?.paymentWallpaper!;
  }

  @computed
  get paymentCongratulationWallpaper(): string {
    return this.config?.paymentCongratulationWallpaper!;
  }

  @computed
  get redirectUri(): string | null {
    return this.config ? this.config.fallbackRedirectUri : null;
  }

  @computed
  get testimonials(): TestimonialItem[] | null {
    return this.config ? this.config.testimonials : null;
  }

  @computed
  get errorWallpaper(): string | null {
    return this.config ? this.config.errorWallpaper || this.config.wallpaper : null;
  }

  @computed
  get isPremiumSubscriptionAvailable() {
    return this.config?.isPremiumSubscriptionAvailable ?? null;
  }
}
