import { action, observable } from 'mobx';
import { Nullable } from '@mobalytics/shared';
import { AccountApi } from '../api/account-api';
import { Mixpanel } from '../marketing/mixpanel';
import { GqlResponse } from '../types/api.types';
import { GQLAPIErrorMessage } from '../types/error.types';
import { AccountFragment } from '../api/accounts/fragments/__generated/account-fragment.gql.generated';
import { AccountQuery } from '../api/accounts/query/__generated/account-query.gql.generated';
import { SingleDataLoaderStore } from './single-data-loader-store';

export class UserSettingsStore extends SingleDataLoaderStore<GQLAPIErrorMessage> {
  @observable user: Nullable<AccountFragment>;

  fetchUser = () => {
    this.setIsLoading(true);
    return AccountApi.fetchAccount()
      .then(this.onFetch)
      .then(this.onAPISuccess)
      .catch(this.onAPIError);
  };

  onFetch = (response: GqlResponse<AccountQuery>) => {
    const account = response[0].account;
    account && this.setUser(account);
  };

  @action
  setUser = (value: AccountFragment) => {
    this.user = value;
    Mixpanel.onUserStatusChanged(value);
  };
}
