import { Game } from '../../__generated/accounts/types';
import { Theme } from '../../types/theme';

export function mapThemeToGame(theme: Theme): Game {
  switch (theme) {
    case Theme.Bacon:
    case Theme.Lor:
      return Game.LOR;
    case Theme.Csgo:
      return Game.CSGO;
    case Theme.Lol:
      return Game.LOL;
    case Theme.Omen:
      return Game.OMEN;
    case Theme.Tft:
      return Game.TFT;
    case Theme.Valorant:
      return Game.VALORANT;
    case Theme.LostArk:
      return Game.LOST_ARK;
    case Theme.Destiny2:
      return Game.DESTINY_2;
    case Theme.Diablo4:
      return Game.DIABLO_4;
    case Theme.Deadlock:
      return Game.DEADLOCK;
    case Theme.Default:
      return Game.LOL;
  }
}
