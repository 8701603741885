import { css } from '@emotion/react';
import { VBoxSbSMixin } from '@mobalytics/shared';
import * as React from 'react';
import { media } from '../../ui/global/media-query';
import { LogoFull } from '../../ui/icons/common/logo-full';
import { Testimonials } from '../testimonials/testimonials.component';

interface Props {
  className?: string;
}

export const Sidebar: React.FC<Props> = ({ className }) => {
  return (
    <div css={wrapperCss} className={className}>
      <LogoFull />
      <Testimonials />
    </div>
  );
};

export const wrapperCss = css`
  ${VBoxSbSMixin};
  flex: 0 0 auto;
  position: relative;
  padding: 20px 24px;
  box-sizing: border-box;
  height: 100%;
  z-index: 10;
  overflow: hidden;
  background: linear-gradient(0deg, var(--general-base-400) 10%, rgba(29, 21, 70, 0) 30%);
  ${media.small} {
    display: none;
  }
`;
