import { NString } from '@mobalytics/shared';
import { PlanT } from './payment.types';

export type PlanStatusT = 'new' | 'existing' | 'changing';

export const getPlanStatus = (currentPlanId: NString, planId: string): PlanStatusT => {
  if (currentPlanId === planId) {
    return 'existing';
  }

  if (currentPlanId) {
    return 'changing';
  }

  return 'new';
};

export const getPricePerMonthType = (plan: Pick<PlanT, 'discount' | 'profit'>): 'discount' | 'profit' | 'no-profit' => {
  if (plan.discount?.coupon || plan.discount?.prorate) {
    return 'discount';
  }
  if (plan.profit) {
    return 'profit';
  }
  return 'no-profit';
};

export const getCents = (priceInCents: number): string => {
  const cents = priceInCents % 100;
  return cents < 10 ? `0${cents}` : `${cents}`;
};
