import * as React from 'react';
import { SignUpDetailsForm } from '../../components/forms/sign-up-details-form/sign-up-details-form.component';
import { PageMeta } from '../../components/page-meta';
import { AuthPageLayout } from '../../ui/layout/auth-page-layout/auth-page-layout.component';

export const SignUpDetails: React.FC = () => {
  return (
    <AuthPageLayout>
      <PageMeta title="ui-sign-up-details" description="ui-sign-up-details-description" />
      <SignUpDetailsForm />
    </AuthPageLayout>
  );
};
