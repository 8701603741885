import { css } from '@emotion/react';
import { NString, VBoxMixin } from '@mobalytics/shared';
import * as React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
  wallpaper?: NString;
}

export const AppLayout: React.FC<Props> = props => {
  const { children, className } = props;
  return (
    <div css={wrapperCss} className={className}>
      {children}
    </div>
  );
};

const wrapperCss = css`
  ${VBoxMixin};
  width: 100%;
  min-height: 100vh;
  background-color: var(--general-base-500);
`;
