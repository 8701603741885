import * as React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from '../../../payment.component';
import { StripeConfirmationInfo } from './stripe-flow.types';

interface Props {
  className?: string;
  confirmationInfo: StripeConfirmationInfo;
  children: React.ReactNode;
}

export const StripeFlowElementsProvider: React.FC<Props> = props => {
  const { confirmationInfo, children } = props;
  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: confirmationInfo.secret,
        appearance,
      }}
    >
      {children}
    </Elements>
  );
};

const appearance = {
  variables: {
    colorPrimary: '#f2bf43',
    colorText: '#fff',
    colorTextSecondary: '#6b6889',
    colorBackground: '#211d41',
    colorTextPlaceholder: '#6b6889',
    colorDanger: '#E54787',
    fontFamily: 'Roboto, sans-serif',
  },
  rules: {
    '.Label': {
      color: '#8890b5',
    },
    '.Input:focus': {
      boxShadow: 'none',
      borderColor: '#f2bf43',
    },
  },
};
