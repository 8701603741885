import { css } from '@emotion/react';

export const mascotEmotionCss = css`
  width: 128px;
  height: 128px;
`;

export const contentLoadingCss = css`
  opacity: 0.4;
  pointer-events: none;
`;
