import * as React from 'react';
import { TextBody2RRMixin, TextSubtitle2RBMixin, WidgetLayout } from '@mobalytics/shared';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

interface Props {
  className?: string;
  email: string;
  firstName?: string;
  lastName?: string;
}

export const PaypalOverviewInfo: React.FC<Props> = props => {
  const { className, email, firstName, lastName } = props;
  const { t } = useTranslation();
  return (
    <WidgetLayout className={className}>
      <p css={headerCss}>{t('Paypal account')}</p>
      <p css={labelCss}>{email}</p>
      {(firstName || lastName) && (
        <p css={labelCss}>
          {firstName} {lastName}
        </p>
      )}
    </WidgetLayout>
  );
};

const headerCss = css`
  ${TextSubtitle2RBMixin};
  margin-bottom: 12px;
`;

const labelCss = css`
  ${TextBody2RRMixin};
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;
