import { css } from '@emotion/react';
import { SocialButton, SocialButtonVendorType } from '@mobalytics/shared';
import * as React from 'react';
import { AccountsButtonNameAnalytics } from '../../buttons/accounts-button/accounts-button.types';
import { Provider } from '../../../types/provider';

interface ProvidersListItemProps {
  provider: Provider;
  className?: string;
  onClick?: (provider: Provider) => void;
}

const getVendorType = (provider: Provider) => {
  switch (provider) {
    case Provider.Discord:
      return SocialButtonVendorType.Discord;
    case Provider.Google:
      return SocialButtonVendorType.Google;
    case Provider.Twitch:
      return SocialButtonVendorType.Twitch;
    default:
      return null;
  }
};

export const ProvidersListItem: React.FC<ProvidersListItemProps> = function(props: ProvidersListItemProps) {
  const { provider, onClick, className } = props;
  const vendorType = getVendorType(provider);

  const clickHandler = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onClick && onClick(provider);
    },
    [provider, onClick]
  );

  return vendorType ? (
    <SocialButton<AccountsButtonNameAnalytics>
      className={className}
      analyticsButtonName="Social login"
      vendorType={vendorType}
      iconOnly={true}
      onClick={clickHandler}
      css={socialButtonCss}
    />
  ) : null;
};

const socialButtonCss = css`
  flex: 1 1 auto;
`;
