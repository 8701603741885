import { css } from '@emotion/react';
import { CheckBox } from '@mobalytics/shared';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useAppStore } from '../../../store/app-store.hook';
import { TermsAndServiceAgreement } from '../../../ui/terms-and-service-agreement/terms-and-service-agreement.component';

export const TermsAndEmailsAgreementCheckbox: React.FC = observer(() => {
  const { inputStore } = useAppStore();
  const { termsAndServiceAgreement, toggleTermsAndServiceAgreement } = inputStore;
  return (
    <CheckBox
      id="termsAndServiceAgreement"
      isChecked={termsAndServiceAgreement}
      onChange={toggleTermsAndServiceAgreement}
      label={<TermsAndServiceAgreement />}
      css={checkboxCss}
    />
  );
});

const checkboxCss = css`
  &:has(a:hover) > span {
    border-color: var(--general-base-100);
  }
  &:has(a:hover) > span > span {
    background-color: var(--general-light-green-100);
  }
`;
