import { NString, Nullable } from '@mobalytics/shared';
import { ThankYouModalOption } from '../pages/payment/thank-you/survey-modal/survey-modal.types';
import { AccountsAppPath } from '../types/app.types';
import { PaymentSection } from '../types/payment.types';
import { Mixpanel, MPEventName, MPPage, MPPageSection } from './mixpanel';

export class Marketing {
  static trackPageVisited(page: MPPage, section: NString, game: string) {
    Mixpanel.trackPageVisited(page, section, game);
  }

  static appPathToMpPageName(path: AccountsAppPath): MPPage {
    switch (path) {
      case AccountsAppPath.MAIN:
        return MPPage.MAIN;
      case AccountsAppPath.ERROR:
        return MPPage.ERROR;
      case AccountsAppPath.SIGN_IN:
        return MPPage.SIGN_IN;
      case AccountsAppPath.SIGN_UP:
        return MPPage.SIGN_UP;
      case AccountsAppPath.LOL_SUMMONER_NAME:
        return MPPage.LOL_SUMMONER_NAME;
      case AccountsAppPath.PASSWORD_RESET:
        return MPPage.PASSWORD_RESET;
      case AccountsAppPath.DESKTOP_SIGN_IN:
        return MPPage.DESKTOP_SIGN_IN;
      case AccountsAppPath.DESKTOP_SIGN_OUT:
        return MPPage.DESKTOP_SIGN_OUT;
      case AccountsAppPath.DESKTOP_SIGN_IN_REDIRECT:
        return MPPage.DESKTOP_SIGN_IN_REDIRECT;
      case AccountsAppPath.DESKTOP_SIGN_IN_COMPLETE:
        return MPPage.DESKTOP_SIGN_IN_COMPLETE_PAGE;
      case AccountsAppPath.SIGN_UP_DETAILS:
        return MPPage.SIGN_UP_DETAILS;
      case AccountsAppPath.CONNECT_MOBA_ACCOUNT:
        return MPPage.CONNECT_MOBA_ACCOUNT;
      case AccountsAppPath.PASSWORD_RESET_SUCCESS:
        return MPPage.PASSWORD_RESET_SUCCESS;
      case AccountsAppPath.REQUEST_PASSWORD_RESET:
        return MPPage.REQUEST_PASSWORD_RESET;
      case AccountsAppPath.REQUEST_PASSWORD_RESET_SUCCESS:
        return MPPage.REQUEST_PASSWORD_RESET_SUCCESS;
      case AccountsAppPath.PAYMENT:
        return MPPage.PAYMENT;
    }
  }

  static appSectionToMpPageSection(path: NString): MPPageSection | null {
    switch (path) {
      case PaymentSection.PLAN_SELECTION:
        return MPPageSection.PLAN_SELECTION;
      case PaymentSection.PAYMENT_INFO:
        return MPPageSection.PAYMENT_INFO;
      case PaymentSection.THANK_YOU:
        return MPPageSection.THANK_YOU;
    }
    return null;
  }

  // region ---- payment
  static planSelected(planName: string) {
    Mixpanel.track(MPEventName.PLAN_SELECTED, {
      planType: planName,
    });
  }

  static trialPlanSelected(planInternalName: string) {
    Mixpanel.track(MPEventName.TRIAL_SELECTED, {
      planType: planInternalName,
    });
  }

  static planChanged(planInternalName: string) {
    Mixpanel.track(MPEventName.PLAN_CHANGED, {
      planType: planInternalName,
      'Page name': MPPage.PAYMENT,
      Section: PaymentSection.PAYMENT_INFO,
    });
  }

  static couponApplied(coupon: string) {
    Mixpanel.track(MPEventName.COUPON_APPLIED, {
      couponName: coupon,
    });
  }

  static paymentInfoNextStepClicked() {
    Mixpanel.track(MPEventName.PAYMENT_INFO_NEXT_STEP_CLICKED);
  }

  static paymentInfoPayPalClicked() {
    Mixpanel.track(MPEventName.PAYMENT_INFO_PAYPAL_CLICKED);
  }

  static paymentInfoStripeClicked() {
    Mixpanel.track(MPEventName.PAYMENT_INFO_STRIPE_CLICKED);
  }

  static billingInfoNextStepClicked() {
    Mixpanel.track(MPEventName.BILLING_INFO_NEXT_STEP_CLICKED);
  }

  // endregion

  // region ---- thank you modal
  static thankYouModalDisplayed() {
    Mixpanel.track(MPEventName.BANNER_DISPLAYED, {
      Type: 'Modal',
      Slug: 'Thank you',
    });
  }

  static thankYouModalClosed() {
    Mixpanel.track(MPEventName.BANNER_CLOSED, {
      Type: 'Modal',
      Slug: 'Thank you',
    });
  }

  static thankYouModalClicked(value: Nullable<ThankYouModalOption>, text: NString) {
    Mixpanel.track(MPEventName.BANNER_CLICKED, {
      Type: 'Modal',
      Slug: 'Thank you',
      Value: value,
      Text: text,
    });
  }
  // endregion
}
