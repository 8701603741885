import { figmaIcon, Input, Link } from '@mobalytics/shared';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useAppStore } from '../../../store/app-store.hook';
import { AccountsAppPath } from '../../../types/app.types';
import { AuthTabs } from '../../../ui/blocks/auth-tabs/auth-tabs.component';
import { AccountsButton } from '../../../ui/buttons/accounts-button/accounts-button.component';
import { FormError } from '../../../ui/layout/form-layout';
import { FormBody } from '../../../ui/layout/form-layout/form-body.component';
import { FormControlls } from '../../../ui/layout/form-layout/form-controlls.component';
import { FormFooter } from '../../../ui/layout/form-layout/form-footer.component';
import { FormLayout } from '../../../ui/layout/form-layout/form-layout.component';
import { formLinkButtonCss } from '../../../ui/layout/form-layout/form-link-button.component';
import { FormSeparator } from '../../../ui/layout/form-layout/form-separator.component';
import { genRequestPasswordResetPath } from '../../../utils/formatter/path.formatter';
import { ConnectProviderForm } from '../connect-provider-form/connect-provider-form.component';

interface SignInFormProps {
  className?: string;
}

export const SignInForm: React.FC<SignInFormProps> = observer((props: SignInFormProps) => {
  const { className } = props;
  const { t } = useTranslation();
  const { search } = useLocation();
  const { inputStore, authStore } = useAppStore();
  const { email, emailError, onChangeEmail } = inputStore;
  const { password, passwordError, onChangePassword } = inputStore;
  const { isLoading, generalError, reset } = inputStore;
  const { signIn } = authStore;

  const submitHandler = React.useCallback(
    (e: React.FormEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      signIn();
    },
    [signIn]
  );

  React.useEffect(() => reset, [reset]);

  return (
    <FormLayout className={className}>
      <AuthTabs activeTab={AccountsAppPath.SIGN_IN} />
      <ConnectProviderForm />
      <FormSeparator>{t('or use email')}</FormSeparator>
      {generalError && <FormError>{t(generalError)}</FormError>}
      <FormBody onSubmit={submitHandler}>
        <FormControlls>
          <Input
            id="email"
            value={email}
            placeholder={t('Email')}
            onChange={onChangeEmail}
            iconUrl={figmaIcon('system-icons', 'email')}
            errorMessage={emailError ? t(emailError) : undefined}
            autoComplete="current-email"
            name="current-email"
            disabled={isLoading}
          />
          <Input
            id="password"
            type="password"
            iconUrl={figmaIcon('system-icons', '24-locked')}
            value={password}
            placeholder={t('ui-password')}
            onChange={onChangePassword}
            errorMessage={passwordError ? t(passwordError) : undefined}
            autoComplete="current-password"
            name="current-password"
            disabled={isLoading}
          />
        </FormControlls>
        <AccountsButton
          analyticsButtonName="Sign In"
          disabled={!!(emailError || passwordError)}
          type="submit"
          loading={isLoading}
        >
          {t('ui-sign-in')}
        </AccountsButton>
      </FormBody>
      <FormFooter>
        <Link to={genRequestPasswordResetPath(search)} css={formLinkButtonCss}>
          {t('Forgot your password?')}
        </Link>
      </FormFooter>
    </FormLayout>
  );
});
