import * as React from 'react';
import { Loader, NString } from '@mobalytics/shared';
import { TreasuryPaymentsGame } from '../../../../../../__generated/treasury/types';
import { AllowedActionsT } from '../../../../payment.types';
import { useStripeFlowConfirmationInfo } from '../stripe-flow-confirmation-info.hook';
import { StripeFlowElementsProvider } from '../stripe-flow-elements-provider.component';
import { StripeNewFlowConfirmation } from './stripe-new-flow-confirmation.component';

interface Props {
  className?: string;
  planId: string;
  game: TreasuryPaymentsGame;
  coupon: NString;
  allowedActions: AllowedActionsT;
  shouldPrepareBillingInfoFirst: boolean;
  submitButtonLabel: string;
}

export const StripeNewFlow: React.FC<Props> = props => {
  const { className, submitButtonLabel, planId, game, coupon, allowedActions, shouldPrepareBillingInfoFirst } = props;
  const { loading, fetchConfirmationInfo, confirmationInfo } = useStripeFlowConfirmationInfo({
    game,
    planId,
    coupon,
    allowedActions,
  });

  React.useEffect(() => {
    fetchConfirmationInfo(shouldPrepareBillingInfoFirst);
  }, [fetchConfirmationInfo, shouldPrepareBillingInfoFirst]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={className}>
      {confirmationInfo && (
        <StripeFlowElementsProvider confirmationInfo={confirmationInfo}>
          <StripeNewFlowConfirmation
            confirmationInfo={confirmationInfo}
            submitButtonLabel={submitButtonLabel}
            game={game}
          />
        </StripeFlowElementsProvider>
      )}
    </div>
  );
};
