import { isDef, NString } from '@mobalytics/shared';
import { GQLAPIErrorMessage } from '../types/error.types';
import { formatError } from './format';

const PASSWORD_MIN_LENGTH = 5;
const PASSWORD_MAX_LENGTH = 50;

// @ts-ignore
// eslint-disable-next-line
const EMAIL_RE = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

export function isEmailValid(email: string): boolean {
  return !!email.toLowerCase().match(EMAIL_RE);
}

export function validateName(name: NString): string | null {
  let result = null;
  if (!name) {
    result = GQLAPIErrorMessage.NAME_IS_EMPTY;
  }
  return isDef(result) ? formatError(result!) : null;
}

export function validateEmail(email: NString): string | null {
  let result = null;
  if (!email) {
    result = GQLAPIErrorMessage.EMAIL_IS_EMPTY;
  } else if (!isEmailValid(email)) {
    result = GQLAPIErrorMessage.EMAIL_INVALID_FORMAT;
  }
  return isDef(result) ? formatError(result!) : null;
}

export function validatePassword(password: NString): string | null {
  let result = null;
  if (!password) {
    result = GQLAPIErrorMessage.PASSWORD_IS_EMPTY;
  } else if (password.length < PASSWORD_MIN_LENGTH) {
    result = GQLAPIErrorMessage.PASSWORD_TOO_SHORT;
  } else if (password.length > PASSWORD_MAX_LENGTH) {
    result = GQLAPIErrorMessage.PASSWORD_TOO_LONG;
  }
  return isDef(result) ? formatError(result!) : null;
}

export function validateToken(name: NString): string | null {
  let result = null;
  if (!name) {
    result = GQLAPIErrorMessage.TOKEN_INVALID;
  }
  return isDef(result) ? formatError(result!) : null;
}

export function validateAccessCode(code: NString): string | null {
  let result = null;
  if (!code) {
    result = GQLAPIErrorMessage.ACCESS_CODE_EMPTY;
  }
  return isDef(result) ? formatError(result!) : null;
}
