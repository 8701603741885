import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { tComponent } from '@mobalytics/shared';
import { getMoneyAsString } from '../../../pages/payment/utils/get-money-as-string.utils';
import { getIntervalName } from '../../../pages/payment/utils/get-interval-name-utils';
import { TreasuryPaymentsPlanInterval } from '../../../__generated/treasury/types';

interface Props {
  className?: string;
  basePrice: number;
  finalPrice: number;
  currency: string;
  interval: TreasuryPaymentsPlanInterval;
  intervalCount: number;
}

export const PriceWithDiscount: React.FC<Props> = props => {
  const { basePrice, currency, finalPrice, interval, intervalCount } = props;
  const { t } = useTranslation();
  const oldPrice = getMoneyAsString(currency, basePrice);
  const newPrice = getMoneyAsString(currency, finalPrice);
  const intervalName = getIntervalName(interval, intervalCount, t);
  return (
    <p>
      {tComponent('Billed {{price}} {{interval}}', {
        price: (
          <>
            <s>{oldPrice}</s> <span>{newPrice}</span>
          </>
        ),
        interval: intervalName,
      })}
    </p>
  );
};
