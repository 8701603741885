import * as Types from '../../../../__generated/treasury/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllowedActionsQueryVariables = Types.Exact<{
  allowedActionsInput: Types.TreasuryPaymentsCustomerActionsFilter;
}>;

export type AllowedActionsQuery = {
  __typename: 'Query';
  treasury?: {
    __typename: 'TreasuryQuery';
    id: string;
    payments?: {
      __typename: 'TreasuryPaymentsQuery';
      customer?:
        | {
            __typename: 'TreasuryPaymentsCustomer';
            id: string;
            allowedActions?: Array<
              | { __typename: 'TreasuryPaymentCustomerActionCancelSubscription' }
              | {
                  __typename: 'TreasuryPaymentCustomerActionChangeSubscription';
                  isAllowed: boolean;
                  product: Types.TreasuryPaymentsProduct;
                }
              | {
                  __typename: 'TreasuryPaymentCustomerActionCreateSubscription';
                  isAllowed: boolean;
                  product: Types.TreasuryPaymentsProduct;
                }
              | {
                  __typename: 'TreasuryPaymentCustomerActionRenewSubscription';
                  isAllowed: boolean;
                  product: Types.TreasuryPaymentsProduct;
                }
              | { __typename: 'TreasuryPaymentCustomerActionUseTrial' }
            > | null;
          }
        | { __typename: 'TreasuryPaymentsUnauthenticatedError' }
        | { __typename: 'TreasuryPaymentsUnknownError' }
        | null;
    } | null;
  } | null;
};

export const AllowedActionsQueryDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllowedActionsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'allowedActionsInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryPaymentsCustomerActionsFilter' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'treasury' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'TreasuryPaymentsCustomer' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'allowedActions' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'filter' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'allowedActionsInput' },
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {
                                              kind: 'Name',
                                              value: 'TreasuryPaymentCustomerActionCreateSubscription',
                                            },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'isAllowed' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {
                                              kind: 'Name',
                                              value: 'TreasuryPaymentCustomerActionChangeSubscription',
                                            },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'isAllowed' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {
                                              kind: 'Name',
                                              value: 'TreasuryPaymentCustomerActionRenewSubscription',
                                            },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'isAllowed' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;

/**
 * __useAllowedActionsQuery__
 *
 * To run a query within a React component, call `useAllowedActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowedActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowedActionsQuery({
 *   variables: {
 *      allowedActionsInput: // value for 'allowedActionsInput'
 *   },
 * });
 */
export function useAllowedActionsQuery(
  baseOptions: Apollo.QueryHookOptions<AllowedActionsQuery, AllowedActionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllowedActionsQuery, AllowedActionsQueryVariables>(AllowedActionsQueryDocument, options);
}
export function useAllowedActionsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllowedActionsQuery, AllowedActionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllowedActionsQuery, AllowedActionsQueryVariables>(AllowedActionsQueryDocument, options);
}
export type AllowedActionsQueryHookResult = ReturnType<typeof useAllowedActionsQuery>;
export type AllowedActionsQueryLazyQueryHookResult = ReturnType<typeof useAllowedActionsQueryLazyQuery>;
export type AllowedActionsQueryQueryResult = Apollo.QueryResult<AllowedActionsQuery, AllowedActionsQueryVariables>;
