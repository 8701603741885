import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { shade } from 'polished';
import { BaseButton } from '../base-button';

const ButtonDisabled = css`
  opacity: 0.2;
  pointer-events: none;
`;

export const Button = styled(BaseButton)<{ disabled?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  padding: 0 4px;
  transition: color 0.2s ease-out, opacity 0.2s ease-out;
  color: ${({ theme }) => theme.colors.buttons.link};
  ${({ disabled }) => disabled && ButtonDisabled};
  &:hover {
    color: ${({ theme }) => shade(0.25, theme.colors.buttons.link)};
  }
  &:active {
    color: ${({ theme }) => shade(0.5, theme.colors.buttons.link)};
  }
`;
