import { css } from '@emotion/react';

/**
 * @deprecated use figma-colors.mixin.ts
 */
export const ColorsMixin = css`
  :root {
    --nav-main-bg-color: #140e30;
    --nav-hover-bg-color: #271c37;
    --nav-border-color: #251e47;

    --purple: #aaabca;
    --success: #00b972;
    --greeny: #4cb748;
    --greeny-blue: #3cbdc2;
    --gold: #bd9e59;
    --strong-cyan: #18bd9b;
    --pink: #c848c3;
    --bright-pink: #ff2576;
    --twilight: #565697;
    --yellow: #ffc306;
    --dark-violet: #252146;
    --martinique: #2e2a4f;
    --kinky-shark: #392b68;
    --satiric-shark: #312955;
    --arid-shark: #3b2d6a;
    --chosen: #ad4cce;
    --black-russian: #11172c;
    --black: #000;
    --warning: #ffb257;
    --tags-soon: #6c65b4;
  }
`;
