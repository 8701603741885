import * as React from 'react';
import { ConnectMobaAccountForm } from '../../components/forms/connect-moba-account-form/connect-moba-account-form.component';
import { PageMeta } from '../../components/page-meta';
import { AuthPageLayout } from '../../ui/layout/auth-page-layout/auth-page-layout.component';

export const ConnectMobaAccount: React.FC = () => {
  return (
    <AuthPageLayout>
      <PageMeta title="ui-connect-moba-account" description="ui-connect-moba-account-description" />
      <ConnectMobaAccountForm />
    </AuthPageLayout>
  );
};
