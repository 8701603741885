import { css } from '@emotion/react';
import { VBoxMixin } from '@mobalytics/shared';
import * as React from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const FormControlls: React.FC<Props> = props => {
  const { className, children } = props;
  return (
    <div className={className} css={wrapperCss}>
      {children}
    </div>
  );
};

const wrapperCss = css`
  ${VBoxMixin};
  gap: 8px;
`;
