import * as React from 'react';
import { SignUpForm } from '../../components/forms/sign-up-form/sign-up-form.component';
import { PageMeta } from '../../components/page-meta';
import { AuthPageLayout } from '../../ui/layout/auth-page-layout/auth-page-layout.component';

export const SignUp: React.FC = () => {
  return (
    <AuthPageLayout>
      <PageMeta title="ui-sign-up" description="ui-sign-up-description" />
      <SignUpForm />
    </AuthPageLayout>
  );
};
