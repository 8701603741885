import { css } from '@emotion/react';
import { TextBody2RRMixin } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { media } from '../../../../../ui/global/media-query';
import { PlanT } from '../../../payment.types';
import { PlanExtraFeature, TreasuryPaymentsProduct } from '../../../../../__generated/treasury/types';

interface Props {
  className?: string;
  plan: PlanT;
}

export const Benefits: React.FC<Props> = props => {
  const { className, plan } = props;
  const { t } = useTranslation();
  const hasTftEgg = plan.planFeatures?.includes(PlanExtraFeature.EGG_SKIN);
  if (hasTftEgg)
    return (
      <ul css={wrapperCss} className={className}>
        <li>{t('Ad-free experience')}</li>
        <li>{t('All current and future Mobalytics Plus features')}</li>
        <li>{t('Immediately get a free TFT egg skin')}</li>
        <li>{t('The first 4 months are non refundable')}</li>
      </ul>
    );

  const hasLolSkin = plan.planFeatures?.includes(PlanExtraFeature.SKIN_CODE);
  if (hasLolSkin) {
    return (
      <ul css={wrapperCss} className={className}>
        <li>{t('Ad-free experience')}</li>
        <li>{t('All current and future Mobalytics Plus features')}</li>
        <li>{t('Immediately get a free LOL skin')}</li>
        <li>{t('The first 4 months are non refundable')}</li>
      </ul>
    );
  }

  if (plan.planType === TreasuryPaymentsProduct.AD_FREE) {
    return (
      <ul css={wrapperCss} className={className}>
        <li>{t('Remove ads for VALORANT, Lost Ark, Destiny 2, Deadlock, and Diablo 4')}</li>
        <li>{t('See exclusive sneak peeks')}</li>
        <li>{t('Get Ad-slayer Discord role')}</li>
        <li>{t('Support the Mobalytics team <3')}</li>
      </ul>
    );
  }
  return (
    <ul css={wrapperCss} className={className}>
      <li>{t('Ad-free experience')}</li>
      <li>{t('All current and future Mobalytics Plus features')}</li>
    </ul>
  );
};

const wrapperCss = css`
  ${TextBody2RRMixin};
  color: var(--general-grey-100);
  padding-left: 0;
  ${media.medium} {
    display: none;
  }
  & > li {
    display: flex;
    margin-bottom: 8px;
    list-style-type: none;

    &:before {
      content: '•';
      font-size: 12px;
      color: var(--general-grey-300);
      margin-right: 8px;
      vertical-align: middle;
    }
  }
`;
