import { css } from '@emotion/react';
import { TextSubtitle3RMMixin } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { AccountsButton } from '../../../ui/buttons/accounts-button/accounts-button.component';
import {
  genPaymentBillingPath,
  genPaymentPlanSelectionPath,
  genRedirectUrl,
} from '../../../utils/formatter/path.formatter';
import { parseAppQueryParams } from '../../../utils/url';
import { usePaymentFilters } from '../hooks/payment-filters.hook';
import { MascotLayout } from '../../../ui/layout/mascot-layout/mascot-layout.component';
import { PaymentError } from '../payment-error.types';
import { formatPaymentErrorTitle } from '../utils/format-payment-error-title.util';
import { formatPaymentErrorDescription } from '../utils/format-payment-error-description.util';

interface Props {
  className?: string;
}

export const Error: React.FC<Props> = props => {
  const { className } = props;
  const { t } = useTranslation();
  const { replace } = useHistory();
  const { search } = useLocation();
  const queryParams = parseAppQueryParams();
  const errorCode = queryParams.error_code as PaymentError;
  const { planId } = usePaymentFilters();
  return (
    <MascotLayout
      className={className}
      title={formatPaymentErrorTitle(t, errorCode) || t('Payment declined')}
      description={
        formatPaymentErrorDescription(t, errorCode) || t('Please try another card or different payment method.')
      }
      mascotEmotion={'sad'}
      css={wrapperCss}
    >
      <p css={linkCss} onClick={() => (window.location.href = genRedirectUrl())}>
        {t('Go back')}
      </p>
      <AccountsButton
        analyticsButtonName="Try again"
        onClick={() => replace(planId ? genPaymentBillingPath(planId, search) : genPaymentPlanSelectionPath(search))}
      >
        {t('try again')}
      </AccountsButton>
    </MascotLayout>
  );
};

const wrapperCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const linkCss = css`
  ${TextSubtitle3RMMixin};
  cursor: pointer;
  color: var(--general-gold-300);
`;
