import * as React from 'react';
import { Logger } from '@mobalytics/shared';
import { useStripe } from '@stripe/react-stripe-js';
import { useHistory, useLocation } from 'react-router';
import { genPaymentErrorPath } from '../../../../../../utils/formatter/path.formatter';
import { PaymentError } from '../../../../payment-error.types';
import { StripeConfirmationInfo } from '../stripe-flow.types';

export const useStripeFlowExistingConfirmation = (confirmationInfo: StripeConfirmationInfo) => {
  const { search } = useLocation();
  const { replace } = useHistory();
  const stripe = useStripe();
  const [loading, setLoading] = React.useState(false);

  const confirmPayment = React.useCallback(async () => {
    try {
      setLoading(true);
      if (!stripe) {
        Logger.error('[useStripeFlowExistingConfirmation]:[confirmPayment]: stripe is missing');
        replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
        return;
      }
      let response;
      if (confirmationInfo.type === 'payment') {
        response = await stripe.confirmPayment({
          clientSecret: confirmationInfo.secret,
          confirmParams: {
            return_url: confirmationInfo.returnUrl,
          },
        });
      } else if (confirmationInfo.type === 'setup') {
        response = await stripe.confirmSetup({
          clientSecret: confirmationInfo.secret,
          confirmParams: {
            return_url: confirmationInfo.returnUrl,
          },
        });
      }
      if (response?.error) {
        Logger.error('[useStripeFlowExistingConfirmation]:[confirmPayment]: stripe response error', response.error);
        replace(genPaymentErrorPath(search, PaymentError.PAYMENT_DECLINED));
        return;
      }
    } catch (e) {
      Logger.error('[useStripeFlowExistingConfirmation]:[confirmPayment]: error', e);
    } finally {
      setLoading(false);
    }
  }, [confirmationInfo.returnUrl, confirmationInfo.secret, confirmationInfo.type, replace, search, stripe]);

  return {
    loading,
    confirmPayment,
  };
};
