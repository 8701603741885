import { ApolloClient, ApolloError, HttpLink, InMemoryCache, MutationOptions, QueryOptions } from '@apollo/client/core';
import { isDefAndNotEmpty, Logger } from '@mobalytics/shared';
import { ExecutionResult, FetchResult } from 'apollo-link';
import { SupportedLanguage } from '../../i18n/i18n';
import { GqlResponse } from '../../types/api.types';
import { ERROR } from '../../utils/error';

export class GraphqlConnector {
  constructor(
    private httpEndpoint: string,
    private lang: SupportedLanguage,
    private headers?: { [key: string]: string }
  ) {}

  setLang = (value: SupportedLanguage) => {
    this.lang = value;
  };

  mutation = <T>(name: string, mutation: MutationOptions<T>): Promise<GqlResponse<ExecutionResult<T>>> => {
    Logger.debug(`GQL ${name} mutation request:`, mutation.variables);
    return this.createHttpClient(name)
      .mutate({ fetchPolicy: 'no-cache', errorPolicy: 'all', ...mutation })
      .then(result => {
        Logger.debug(`GQL ${name} mutation response`, result);
        return result;
      })
      .then(this.handleMutationResponse)
      .catch(this.handleError);
  };

  private handleMutationResponse = <T>(result: FetchResult<T>): [ExecutionResult<T>, ERROR | null] => {
    if (isDefAndNotEmpty(result.errors)) {
      throw result.errors[0].message;
    }
    return [result, null];
  };

  query = <T>(name: string, query: QueryOptions): Promise<GqlResponse<T>> => {
    Logger.debug(`GQL ${name} query request:`, query.variables);
    return this.createHttpClient(name)
      .query({ fetchPolicy: 'no-cache', errorPolicy: 'all', ...query })
      .then(result => {
        Logger.debug(`GQL ${name} query response`, result);
        return result;
      })
      .then(this.handleResponse)
      .catch(this.handleError);
  };

  private handleResponse = <T>(result: FetchResult<T>): [T, ERROR | null] => {
    if (!result.data) {
      throw ERROR.INTERNAL_ERROR;
    }
    return [result.data, null];
  };

  private handleError = (error: ApolloError) => {
    error.networkError && Logger.error('GQL network error: ', error.networkError);
    isDefAndNotEmpty(error.graphQLErrors) && Logger.error('GQL query error: ', error.graphQLErrors);
    throw error || ERROR.INTERNAL_ERROR;
  };

  private createHttpClient = (name: string) => {
    return new ApolloClient({
      cache: new InMemoryCache(),
      link: new HttpLink({
        uri: this.httpEndpoint,
        headers: this.buildHeaders(name),
        fetchOptions: { credentials: 'include' },
      }),
    });
  };

  private buildHeaders = (name?: string) => {
    const headers: { [key: string]: string } = {
      'Accept-Language': this.lang as string,
      'x-moba-client': 'mobalytics-web',
      ...this.headers,
    };
    name && (headers['x-moba-proxy-gql-ops-name'] = name);
    return headers;
  };
}
