import { action } from 'mobx';
import { deserializeObservable, resetObservable, serializeObservable } from '../utils/store';

export class SerializableStore {
  isSerializable: boolean = true;
  isDeserializable: boolean = true;
  isResetable: boolean = true;

  serialize = () => {
    return this.serializeInternal();
  };

  protected serializeInternal() {
    return serializeObservable(this);
  }

  deserialize = (obj: any) => {
    this.deserializeInternal(obj);
  };

  @action
  protected deserializeInternal(obj: any) {
    deserializeObservable(this, obj);
  }

  reset = () => {
    this.resetInternal();
  };

  @action
  protected resetInternal() {
    resetObservable(this);
  }
}
