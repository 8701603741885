import { Nullable } from '@mobalytics/shared';
import { useTranslation } from 'react-i18next';
import { treasuryApolloClient } from '../../../treasury-apollo-client';
import { processPlan } from '../utils/process-plans.utils';
import { AvailableStepT } from '../payment.types';
import { usePlansWithCouponQueryLazyQuery } from '../_gql/__generated/plans-with-coupon.query.gql.generated';
import { TreasuryFlowInfoData, TreasuryPaymentsGame } from '../../../__generated/treasury/types';

type ParamsT = {
  flowInfo: Nullable<TreasuryFlowInfoData>;
  flowStep: Nullable<AvailableStepT>;
};
export const useTrialOneTimeOffer = (params: ParamsT) => {
  const { t } = useTranslation();
  const { flowInfo, flowStep } = params;
  const coupon = flowInfo?.couponID;
  const planId = flowInfo?.planID;

  const [applyCoupon, { data, loading, error }] = usePlansWithCouponQueryLazyQuery({
    client: treasuryApolloClient,
    fetchPolicy: 'no-cache',
  });

  const onApplyCoupon = (coupon: string, game: TreasuryPaymentsGame) => {
    return applyCoupon({
      variables: {
        plansInput: {
          couponName: coupon,
          game,
        },
      },
    });
  };

  const rawPlan = data?.treasury?.payments?.plansWithCoupon?.plans?.find(plan => plan.id === planId);
  const offerPlan = rawPlan ? processPlan(rawPlan, t) : null;
  const isOneTimeOfferAvailable =
    flowStep?.id === 'step_trial_offer_tft_proposal' || flowStep?.id === 'step_trial_offer_lol_proposal';
  return {
    error,
    loading,
    offerPlan,
    offerCoupon: coupon,
    offerPlanId: planId,
    isOneTimeOfferAvailable,
    onApplyCoupon,
  };
};
