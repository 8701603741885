import { css } from '@emotion/react';
import { HBoxSCMixin, TextSubtitle4RMMixin } from '@mobalytics/shared';
import * as React from 'react';

interface FormSeparatorProps {
  children?: React.ReactNode;
  className?: string;
}

export const FormSeparator: React.FC<FormSeparatorProps> = function(props: FormSeparatorProps) {
  const { children, className } = props;
  return (
    <div css={wrapperCss} className={className}>
      <span css={lineCss} />
      <span css={labelCss}>{children}</span>
      <span css={lineCss} />
    </div>
  );
};

const wrapperCss = css`
  ${HBoxSCMixin};
  color: var(--general-base-100);
  gap: 16px;
`;

const lineCss = css`
  position: relative;
  flex: 1 1 auto;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    height: 1px;
    background: var(--general-base-100);
  }
`;

const labelCss = css`
  ${TextSubtitle4RMMixin};
  text-transform: uppercase;
  color: var(--general-grey-300);
`;
