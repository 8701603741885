import { ExecutionResult } from 'apollo-link';
import { Region } from '@mobalytics/shared';
import { GQL_HTTP_URL } from '../config';
import { SupportedLanguage } from '../i18n/i18n';
import { GqlResponse } from '../types/api.types';
import { SettingsKey } from '../__generated/accounts/types';
import { GraphqlConnector } from './base/graphql-connector';
import { normalizeError } from './error';
import { UpdateAccountInfoQuery } from './accounts/mutations/update-account-info-mutation.gql';
import { UpdateAccountInfoMutation } from './accounts/mutations/__generated/update-account-info-mutation.gql.generated';

export class LolApi {
  private static gqlConnector: GraphqlConnector | null = null;

  private static getInstance(lang?: SupportedLanguage): GraphqlConnector {
    this.gqlConnector = new GraphqlConnector(GQL_HTTP_URL, lang || SupportedLanguage.EN_US);
    return this.gqlConnector;
  }

  static setSummoner(
    summoner: string,
    region: Region,
    lang: string
  ): Promise<GqlResponse<ExecutionResult<UpdateAccountInfoMutation>>> {
    return this.getInstance(lang as SupportedLanguage)
      .mutation<UpdateAccountInfoMutation>('updateAccountInfo', {
        mutation: UpdateAccountInfoQuery,
        variables: {
          settings: [
            {
              key: SettingsKey.game_lol_summoner_name,
              value: summoner,
            },
            {
              key: SettingsKey.game_lol_summoner_region,
              value: region,
            },
          ],
        },
      })
      .catch(normalizeError);
  }
}
