import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PageMeta } from '../../components/page-meta';
import { AuthPageLayout } from '../../ui/layout/auth-page-layout/auth-page-layout.component';
import { FormLayout } from '../../ui/layout/form-layout/form-layout.component';
import { FormSubTitle } from '../../ui/layout/form-layout/form-subtitle.component';
import { FormTitle } from '../../ui/layout/form-layout/form-title.component';

export const DesktopSignInComplete: React.FC = () => {
  const { t } = useTranslation();
  return (
    <AuthPageLayout>
      <PageMeta title="ui-desktop-sign-in-complete-title" description="ui-desktop-sign-in-complete-description" />
      <FormLayout>
        <FormTitle>{t('ui-desktop-sign-in-complete-title')}</FormTitle>
        <FormSubTitle>{t('ui-desktop-sign-in-complete-description')}</FormSubTitle>
      </FormLayout>
    </AuthPageLayout>
  );
};
