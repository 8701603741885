import { css } from '@emotion/react';
import { TextButton1CAPSRBMixin, TextH2OMMixin } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getCents } from '../../../pages/payment/payment.utils';
import { getCurrencySymbol } from '../../../pages/payment/utils/get-currency-symbol.utils';
import { getMoneyBaseUnit } from '../../../pages/payment/utils/get-money-base-unit.utils';

interface Props {
  className?: string;
  valueInCents: number;
  currency: string;
  hasDiscount: boolean;
}

export const PricePerMonth: React.FC<Props> = props => {
  const { className, valueInCents, currency, hasDiscount } = props;
  const dollars = getMoneyBaseUnit(valueInCents, false);
  const cents = getCents(valueInCents);
  const currencySymbol = getCurrencySymbol(currency);
  const { t } = useTranslation();

  return (
    <div className={className} css={hasDiscount && wrapperDiscountCss}>
      <span css={supCss}>{currencySymbol}</span>
      <span css={priceCss}>
        {dollars}.{cents}
      </span>
      <span css={perMonthCss}> / {t('month')}</span>
    </div>
  );
};

const wrapperDiscountCss = css`
  color: var(--general-orange-200);
`;

const supCss = css`
  ${TextButton1CAPSRBMixin};
  padding: 6px 4px 0 0;
  vertical-align: top;
  display: inline-block;
`;

const priceCss = css`
  ${TextH2OMMixin};
  font-weight: 500;
`;

const perMonthCss = css`
  ${TextButton1CAPSRBMixin};
  text-transform: initial;
`;
