import { css } from '@emotion/react';
import { TextSubtitle3RMMixin, VBoxCCMixin } from '@mobalytics/shared';
import * as React from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const FormFooter: React.FC<Props> = props => {
  const { className, children } = props;
  return (
    <div className={className} css={wrapperCss}>
      {children}
    </div>
  );
};

const wrapperCss = css`
  ${TextSubtitle3RMMixin};
  ${VBoxCCMixin};
  color: var(--general-grey-100);
`;
