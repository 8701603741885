import { css } from '@emotion/react';
import * as React from 'react';
import { AllowedActionsT, PlanT } from '../../../pages/payment/payment.types';
import { NewButton } from './new-button/new-button.component';
import { ChangeButton } from './change-button/change-button.component';
import { ExistingButton } from './existing-button/existing-button.component';
import { RenewButton } from './renew-button/renew-button.component';

interface Props {
  className?: string;
  plan: PlanT;
  onClick: (plan: PlanT) => void;
  isCurrentPlan: boolean;
  allowedActions: AllowedActionsT;
}

export const ButtonController: React.FC<Props> = props => {
  const { className, plan, isCurrentPlan, allowedActions, onClick } = props;
  if (allowedActions.renew) {
    return <RenewButton className={className} css={buttonCss} onClick={() => onClick(plan)} />;
  }
  if (allowedActions.create) {
    return (
      <NewButton
        planType={plan.planType}
        className={className}
        isBestChoice={!!plan.bestChoiceLabel}
        css={buttonCss}
        onClick={() => onClick(plan)}
      />
    );
  }

  if (allowedActions.change) {
    if (isCurrentPlan) {
      return <ExistingButton className={className} css={buttonCss} />;
    }
    return (
      <ChangeButton
        className={className}
        css={buttonCss}
        onClick={() => onClick(plan)}
        isBestChoice={!!plan.bestChoiceLabel}
      />
    );
  }

  return null;
};

const buttonCss = css`
  width: 100%;
`;
