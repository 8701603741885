import { css } from '@emotion/react';
import { TextBody2RRMixin, TextSubtitle3RMMixin } from '@mobalytics/shared';
import * as React from 'react';
import { visuallyHidden } from '../global/mixins';

interface Props {
  id: string;
  label: string;
  isActive?: boolean;
  className?: string;
  onChange?: React.ChangeEventHandler;
}

/** @deprecated, change to radio from mobashared */
export const Radio: React.FC<Props> = props => {
  const { className } = props;
  const { id, label, isActive, onChange } = props;

  return (
    <label css={wrapperCss} className={className} htmlFor={id}>
      <input css={inputCss} type="radio" id={id} value={id} onChange={onChange} checked={isActive} />
      <div css={[thumbCss, isActive && thumbActiveCss]} />
      <span css={[textCss, isActive && textActiveCss]}>{label}</span>
    </label>
  );
};

const wrapperCss = css`
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
`;

const inputCss = css`
  ${visuallyHidden}
`;

const thumbCss = css`
  flex: 0 0 auto;
  position: relative;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 1px solid var(--general-base-100);
  background: #211d41;
  border-radius: 100%;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 12px;
    height: 12px;
    transform: translate(-50%, -50%);
    background: var(--general-light-green-100);
    border-radius: 100%;
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }
`;

const thumbActiveCss = css`
  &:after {
    opacity: 1;
  }
`;

const textCss = css`
  ${TextBody2RRMixin};
  text-align: left;
  color: var(--general-grey-100);
  transition: color 0.2s ease-out;
`;

const textActiveCss = css`
  ${TextSubtitle3RMMixin};
  color: var(--general-white-500);
`;
