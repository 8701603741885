import { css } from '@emotion/react';
import { NString, useOnClickOutside } from '@mobalytics/shared';
import * as React from 'react';
import { PlanT } from '../../../payment.types';
import { PlanItem } from './plan-item/plan-item.component';

interface Props {
  className?: string;
  plans: PlanT[];
  selectedPlanId: string;
  currentPlanId: NString;
  onSelect: (plan: PlanT) => void;
  onClose: () => void;
}

export const PlansList: React.FC<Props> = props => {
  const { className, plans, selectedPlanId, currentPlanId, onSelect, onClose } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, onClose);
  return (
    <div className={className} css={wrapperCss} ref={ref}>
      {plans.map(plan =>
        currentPlanId === plan.id ? null : (
          <PlanItem key={plan.id} plan={plan} selected={selectedPlanId === plan.id} onSelect={onSelect} />
        )
      )}
    </div>
  );
};

const wrapperCss = css`
  background-color: var(--general-base-100);
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(21, 11, 37, 0.5);
`;
