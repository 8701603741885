import * as React from 'react';
import { CloseButtonWrapper, CloseIcon } from './close-button.styles';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

export const CloseButton: React.FC<Props> = props => {
  const { className, ...rest } = props;
  return (
    <CloseButtonWrapper className={className} {...rest}>
      <CloseIcon />
    </CloseButtonWrapper>
  );
};
