import { NString } from '@mobalytics/shared';
import * as React from 'react';
import { treasuryApolloClient } from '../../../../treasury-apollo-client';
import { useCreateSubscriptionMutation } from '../../_gql/__generated/create-subscription.mutation.gql.generated';
import { useChangeSubscriptionMutation } from '../../_gql/__generated/change-subscription.mutation.gql.generated';
import { useRenewSubscriptionMutation } from '../../_gql/__generated/renew-subscription.mutation.gql.generated';
import { TreasuryPaymentsGame, TreasuryPaymentsProvider } from '../../../../__generated/treasury/types';
import { useUpdateBillingInfoMutation } from '../../_gql/__generated/update-payment-info.mutation.gql.generated';
import { usePrepareUpdateBillingInfoMutation } from '../../_gql/__generated/prepare-update-billing-info.mutation.gql.generated';

export const usePaymentFlow = () => {
  const [createSubscription] = useCreateSubscriptionMutation({
    client: treasuryApolloClient,
  });

  const [changeSubscription] = useChangeSubscriptionMutation({
    client: treasuryApolloClient,
  });

  const [renewSubscription] = useRenewSubscriptionMutation({
    client: treasuryApolloClient,
  });

  const [updateBillingInfo] = useUpdateBillingInfoMutation({
    client: treasuryApolloClient,
  });

  const [prepareBillingInfoMutation] = usePrepareUpdateBillingInfoMutation({
    client: treasuryApolloClient,
  });

  //
  const createNewSubscription = React.useCallback(
    async (planId: string, game: TreasuryPaymentsGame, couponName: NString, provider: TreasuryPaymentsProvider) => {
      const { data: subscriptionData } = await createSubscription({
        variables: {
          planId,
          game,
          couponName,
          provider,
        },
      });
      return subscriptionData?.treasury?.payments?.createSubscription;
    },
    [createSubscription]
  );

  const changeCurrentSubscription = React.useCallback(
    async (planId: string, game: TreasuryPaymentsGame, couponName: NString) => {
      const { data: subscriptionData } = await changeSubscription({
        variables: {
          planId,
          game,
          couponName,
        },
      });
      return subscriptionData?.treasury?.payments?.changeSubscription;
    },
    [changeSubscription]
  );

  const renewCurrentSubscription = React.useCallback(
    async (planId: string, game: TreasuryPaymentsGame, couponName: NString) => {
      const { data: subscriptionData } = await renewSubscription({
        variables: {
          planId,
          game,
          couponName,
        },
      });
      return subscriptionData?.treasury?.payments?.renewSubscription;
    },
    [renewSubscription]
  );

  const prepareBillingInfo = React.useCallback(async () => {
    const { data: subscriptionData } = await prepareBillingInfoMutation();
    return subscriptionData?.treasury?.payments?.prepareUpdateBillingInfo;
  }, [prepareBillingInfoMutation]);

  const updatePaymentInfo = async (token: string, provider: TreasuryPaymentsProvider) => {
    const { data: paymentInfoData } = await updateBillingInfo({
      variables: {
        updateBillingInfoInput: {
          token,
          provider,
        },
      },
    });
    return paymentInfoData?.treasury?.payments?.updateBillingInfo;
  };

  return {
    createNewSubscription,
    changeCurrentSubscription,
    renewCurrentSubscription,
    prepareBillingInfo,
    updatePaymentInfo,
  };
};
