import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useAppStore } from '../../../store/app-store.hook';
import { AccountsButton } from '../../../ui/buttons/accounts-button/accounts-button.component';
import { FormBody } from '../../../ui/layout/form-layout/form-body.component';
import { FormLayout } from '../../../ui/layout/form-layout/form-layout.component';
import { FormSubTitle } from '../../../ui/layout/form-layout/form-subtitle.component';
import { FormTitle } from '../../../ui/layout/form-layout/form-title.component';
import { genSignInPath } from '../../../utils/formatter/path.formatter';

interface RequestPasswordResetSuccessFormProps {
  className?: string;
}

export const RequestPasswordResetSuccessForm: React.FC<RequestPasswordResetSuccessFormProps> = observer(
  (props: RequestPasswordResetSuccessFormProps) => {
    const { className } = props;
    const { t } = useTranslation();
    const { inputStore } = useAppStore();
    const { reset } = inputStore;
    const { search } = useLocation();

    React.useEffect(() => reset, [reset]);

    return (
      <FormLayout className={className}>
        <FormBody>
          <FormTitle>{t('ui-email-was-sent')}</FormTitle>
          <FormSubTitle>{t('ui-email-was-sent-disclaimer')}</FormSubTitle>
          <Link to={genSignInPath(search)}>
            <AccountsButton analyticsButtonName="Got it">{t('ui-ok-i-got-it')}</AccountsButton>
          </Link>
        </FormBody>
      </FormLayout>
    );
  }
);
