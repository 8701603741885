import * as React from 'react';
import { matchPath, Route as ReactRoute, RouteProps as ReactRouteProps, useLocation } from 'react-router';
import { Marketing } from '../../marketing/marketing';
import { useAppStore } from '../../store/app-store.hook';
import { AccountsAppPath } from '../../types/app.types';

interface RouteProps extends ReactRouteProps {
  path: AccountsAppPath;
}

export const Route: React.FC<RouteProps> = function(props: RouteProps) {
  const {
    configStore: { theme },
  } = useAppStore();

  const { pathname } = useLocation();
  const match = matchPath<{ section?: string | undefined }>(pathname, {
    path: props.path,
    exact: true,
    strict: false,
  });
  const hasMatch = !!match;
  const mpPageName = Marketing.appPathToMpPageName(props.path);
  const section = Marketing.appSectionToMpPageSection(match?.params.section);

  React.useEffect(() => {
    if (hasMatch) {
      Marketing.trackPageVisited(mpPageName, section, theme);
    }
  }, [theme, mpPageName, section, hasMatch]);

  return <ReactRoute {...props} />;
};
