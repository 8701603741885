import { ExecutionResult } from 'apollo-link';
import { GQL_HTTP_URL } from '../config';
import { SupportedLanguage } from '../i18n/i18n';
import { GqlResponse } from '../types/api.types';
import { SingUpMarketingParams } from '../__generated/accounts/types';
import { GraphqlConnector } from './base/graphql-connector';
import { normalizeError } from './error';
import { RequestPasswordResetQuery } from './accounts/mutations/request-pasword-reset-mutation.gql';
import { ResetPasswordQuery } from './accounts/mutations/reset-pasword-mutation.gql';
import { SignInQuery } from './accounts/mutations/sign-in-mutation.gql';
import { SignOutQuery } from './accounts/mutations/sign-out-mutation.gql';
import { SignUpQuery } from './accounts/mutations/sign-up-mutation.gql';
import { AccountQueryGql } from './accounts/query/account-query.gql';
import {
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables,
} from './accounts/mutations/__generated/request-pasword-reset-mutation.gql.generated';
import { ResetPasswordMutation } from './accounts/mutations/__generated/reset-pasword-mutation.gql.generated';
import { SignInMutation } from './accounts/mutations/__generated/sign-in-mutation.gql.generated';
import { SignOutMutation } from './accounts/mutations/__generated/sign-out-mutation.gql.generated';
import { SignUpMutation } from './accounts/mutations/__generated/sign-up-mutation.gql.generated';
import { AccountQuery } from './accounts/query/__generated/account-query.gql.generated';
import { SessionQueryGql } from './accounts/query/session-query.gql';
import { SessionQuery } from './accounts/query/__generated/session-query.gql.generated';

export class AccountApi {
  private static gqlConnector: GraphqlConnector | null = null;

  private static getInstance(lang?: SupportedLanguage): GraphqlConnector {
    this.gqlConnector = new GraphqlConnector(GQL_HTTP_URL, lang || SupportedLanguage.EN_US);
    return this.gqlConnector;
  }

  static requestPasswordReset(
    variables: RequestPasswordResetMutationVariables
  ): Promise<GqlResponse<ExecutionResult<RequestPasswordResetMutation>>> {
    return this.getInstance(variables.lang as SupportedLanguage)
      .mutation<RequestPasswordResetMutation>('requestPasswordReset', {
        mutation: RequestPasswordResetQuery,
        variables,
      })
      .catch(normalizeError);
  }

  static resetPassword(token: string, password1: string): Promise<GqlResponse<ExecutionResult<ResetPasswordMutation>>> {
    return this.getInstance(SupportedLanguage.EN_US)
      .mutation<ResetPasswordMutation>('resetPassword', {
        mutation: ResetPasswordQuery,
        variables: {
          token,
          password1,
        },
      })
      .catch(normalizeError);
  }

  static signIn(
    email: string,
    password: string,
    continueFrom?: string
  ): Promise<GqlResponse<ExecutionResult<SignInMutation>>> {
    return this.getInstance(SupportedLanguage.EN_US)
      .mutation<SignInMutation>('signIn', {
        mutation: SignInQuery,
        variables: {
          email,
          password,
          continueFrom,
        },
      })
      .catch(normalizeError);
  }

  static signOut(): Promise<GqlResponse<ExecutionResult<SignOutMutation>>> {
    return this.getInstance(SupportedLanguage.EN_US)
      .mutation<SignOutMutation>('signOut', {
        mutation: SignOutQuery,
      })
      .catch(normalizeError);
  }

  static signUp({
    name,
    email,
    password,
    continueFrom,
    marketing,
    mobaReferrerId,
  }: {
    name: string;
    email: string;
    password: string;
    continueFrom?: string;
    marketing?: SingUpMarketingParams | null;
    mobaReferrerId?: string | null;
  }): Promise<GqlResponse<ExecutionResult<SignUpMutation>>> {
    return this.getInstance(SupportedLanguage.EN_US)
      .mutation<SignUpMutation>('signUp', {
        mutation: SignUpQuery,
        variables: {
          name,
          email,
          password,
          continueFrom,
          marketing,
          mobaReferrerId,
        },
      })
      .catch(normalizeError);
  }

  static getAuthSession(): Promise<GqlResponse<SessionQuery>> {
    return this.getInstance()
      .query<SessionQuery>('updateAccountInfo', {
        query: SessionQueryGql,
      })
      .catch(normalizeError);
  }

  static fetchAccount(): Promise<GqlResponse<AccountQuery>> {
    return this.getInstance()
      .query<AccountQuery>('updateAccountInfo', {
        query: AccountQueryGql,
      })
      .catch(normalizeError);
  }
}
