import i18next from 'i18next';
import { ThankYouModalOption } from './survey-modal.types';

export function formatThankYouModalOption(option: ThankYouModalOption): string {
  switch (option) {
    case 'NO_ADS':
      return i18next.t('ui-thank-you-modal-option-no-ads');
    case 'LOL_SMART_HIGHLIGHTS':
      return i18next.t('ui-thank-you-modal-option-lol-smart-highlights');
    case 'LOL_CHAMPION_EXPERT_GUIDES':
      return i18next.t('ui-thank-you-modal-option-lol-champion-expert-guides');
    case 'LOL_CHAMPION_COMBO_VIDEOS':
      return i18next.t('ui-thank-you-modal-option-lol-champion-combo-videos');
    case 'LOL_GPI_SKILL_PROGRESS':
      return i18next.t('ui-thank-you-modal-option-lol-gpi-skill-progress');
    case 'LOL_MATCHUP_SPECIFIC_ADVICE':
      return i18next.t('ui-thank-you-modal-option-lol-matchup-specific-advice');
    case 'LOL_CHALLENGES':
      return i18next.t('ui-thank-you-modal-option-lol-challenges');
    case 'TFT_RECOMMENDED_TEAM_COMPS':
      return i18next.t('ui-thank-you-modal-option-tft-recommended-team-comps');
    case 'TFT_TEAM_COMP_EXPERT_GUIDES':
      return i18next.t('ui-thank-you-modal-option-tft-team-comp-expert-guides');
    case 'TFT_UNLIMITED_TEAM_COMPS':
      return i18next.t('ui-thank-you-modal-option-tft-unlimited-team-comps');
    case 'TFT_AUGMENT_RECOMMENDATIONS':
      return i18next.t('ui-thank-you-modal-option-tft-augment-recommendations');
  }
}

export const surveyModalOptions: ThankYouModalOption[] = [
  'LOL_SMART_HIGHLIGHTS',
  'LOL_CHAMPION_EXPERT_GUIDES',
  'LOL_CHAMPION_COMBO_VIDEOS',
  'LOL_CHALLENGES',
  'LOL_MATCHUP_SPECIFIC_ADVICE',
  'LOL_GPI_SKILL_PROGRESS',
  'TFT_RECOMMENDED_TEAM_COMPS',
  'TFT_TEAM_COMP_EXPERT_GUIDES',
  'TFT_UNLIMITED_TEAM_COMPS',
  'TFT_AUGMENT_RECOMMENDATIONS',
  'NO_ADS',
];
