import { css } from '@emotion/react';
import { HBoxCCMixin } from '@mobalytics/shared';
import * as React from 'react';

interface Props {
  className?: string;
  isActive: boolean;
  index: number;
  onClick: (index: number) => void;
}

export const TestimonialDot: React.FC<Props> = props => {
  const { className, isActive, index, onClick } = props;

  const clickHandler = React.useCallback(() => {
    onClick(index);
  }, [onClick, index]);

  return <div className={className} css={[wrapperCss, isActive && wrapperActiveCss]} onClick={clickHandler}></div>;
};

const wrapperCss = css`
  ${HBoxCCMixin};
  width: 6px;
  height: 6px;
  background-color: var(--general-grey-300);
  transition: background-color 0.3s ease-out;
  border-radius: 50%;
  &:after {
    content: '';
    width: 14px;
    height: 14px;
    display: block;
    position: absolute;
    cursor: pointer;
  }
`;

const wrapperActiveCss = css`
  background-color: var(--general-white-100);
`;
