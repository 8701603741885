import styled from '@emotion/styled';
import { PrimaryButton } from '../../../ui/buttons/primary-button';
import { FormSubTitle, FormTitle } from '../../../ui/layout/form-layout';

export const ProviderErrorFormTitle = styled(FormTitle)`
  margin-top: 40px;
`;

export const CleanUpAndLetMeInButton = styled(PrimaryButton)`
  margin: 16px auto;
  display: inline-block;
  vertical-align: middle;
  padding-left: 38px;
  padding-right: 38px;
`;

export const DisclaimerAfterCleanUp = styled(FormSubTitle)`
  color: #746b9b;
`;

export const DiscordSupportDisclaimer = styled(FormSubTitle)`
  margin-bottom: 36px;
  color: #b3b4d0;
`;
