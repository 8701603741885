import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Region } from '@mobalytics/shared';
import { useAppStore } from '../../../store/app-store.hook';
import { AccountsButton } from '../../../ui/buttons/accounts-button/accounts-button.component';
import { FormBody } from '../../../ui/layout/form-layout/form-body.component';
import { FormControlls } from '../../../ui/layout/form-layout/form-controlls.component';
import { FormLayout } from '../../../ui/layout/form-layout/form-layout.component';
import { FormSubTitle } from '../../../ui/layout/form-layout/form-subtitle.component';
import { FormTitle } from '../../../ui/layout/form-layout/form-title.component';
import { RegionModal } from '../../../ui/modals/region-modal';
import { SummonerInput } from './summoner-input';
import { SummonerNotFound } from './summoner-not-found';

interface Props {
  className?: string;
}

export const SetSummonerForm: React.FC<Props> = observer(() => {
  const { t } = useTranslation();
  const { inputStore, authStore } = useAppStore();
  const { summonerName, onChangeSummonerName } = inputStore;
  const { summonerNameError, setSummonerNameError } = inputStore;
  const { region, onChangeRegion } = inputStore;
  const { isLoading } = inputStore;
  const { setSummoner } = authStore;

  const [regionPopup, setRegionPopup] = React.useState(false);

  const toggleRegionPopup = React.useCallback(() => {
    setRegionPopup(!regionPopup);
  }, [regionPopup, setRegionPopup]);

  const onTryAnotherSummoner = React.useCallback(() => {
    setSummonerNameError(null);
  }, [setSummonerNameError]);

  const onProSummonerClick = React.useCallback(
    (proSummonerName: string, proSummonerRegion: Region) => {
      setSummoner(proSummonerName, proSummonerRegion);
    },
    [setSummoner]
  );

  const onFindMe = React.useCallback(
    (e: React.FormEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setSummoner(summonerName, region);
    },
    [setSummoner, summonerName, region]
  );

  return (
    <>
      <FormLayout>
        <FormTitle>{t('set-summoner-title')}</FormTitle>
        <FormSubTitle>{t('set-summoner-description')}</FormSubTitle>
        <FormBody onSubmit={onFindMe}>
          <FormControlls>
            <SummonerInput
              region={region}
              onRegionClick={toggleRegionPopup}
              summonerName={summonerName}
              onChangeSummonerName={onChangeSummonerName}
              summonerNameError={summonerNameError && t(summonerNameError)}
              isLoading={isLoading}
            />
          </FormControlls>
          <AccountsButton
            analyticsButtonName="Find summoner"
            disabled={isLoading || summonerName.length === 0 || !region}
            type="submit"
          >
            {t('ui-find-me')}
          </AccountsButton>
        </FormBody>
      </FormLayout>

      {summonerNameError && (
        <SummonerNotFound
          summonerName={summonerName}
          region={region}
          onProSummonerClick={onProSummonerClick}
          onTryAnotherSummoner={onTryAnotherSummoner}
        />
      )}

      {regionPopup && <RegionModal selectedRegion={region} changeRegion={onChangeRegion} onClose={toggleRegionPopup} />}
    </>
  );
});
