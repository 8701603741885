import { Nullable } from '@mobalytics/shared';
import { TreasuryPaymentsGame } from '../../../__generated/treasury/types';
import { TRIAL_ONE_TIME_OFFER_LOL, TRIAL_ONE_TIME_OFFER_TFT } from '../../../constants';

export const getTrialOneTimeOfferByGame = (game: Nullable<TreasuryPaymentsGame>): string => {
  switch (game) {
    case TreasuryPaymentsGame.LOL:
      return TRIAL_ONE_TIME_OFFER_LOL;
    case TreasuryPaymentsGame.TFT:
      return TRIAL_ONE_TIME_OFFER_TFT;
    default:
      return '';
  }
};
