import { css } from '@emotion/react';
import { TextBody1RRMixin } from '@mobalytics/shared';
import * as React from 'react';
import { getMoneyAsString } from '../../../../utils/get-money-as-string.utils';

interface Props {
  className?: string;
  currency: string;
  value: number;
}

export const Price: React.FC<Props> = props => {
  const { className, currency, value } = props;
  const price = getMoneyAsString(currency, value);
  return (
    <span className={className} css={wrapperCss}>
      {price}
    </span>
  );
};

const wrapperCss = css`
  ${TextBody1RRMixin};
  white-space: nowrap;
`;
