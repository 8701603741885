import { css } from '@emotion/react';
import { VBoxSCMixin } from '@mobalytics/shared';
import * as React from 'react';
import { PromoGamersForGamers } from '../../promo-gamers-for-gamers/promo-gamers-for-gamers.component';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const AuthPageLayout: React.FC<Props> = props => {
  const { className, children } = props;
  return (
    <div className={className} css={wrapperCss}>
      {children}
      <PromoGamersForGamers />
    </div>
  );
};

AuthPageLayout.displayName = 'AuthPageLayout';

const wrapperCss = css`
  ${VBoxSCMixin};
  width: 100%;
  padding: 0 20px 40px;
`;
