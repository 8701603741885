import React from 'react';
import { css } from '@emotion/react';
import { CloseButton } from '../../buttons/close-button';

interface Props {
  className?: string;
  children: React.ReactNode;
  onClose?: () => void;
}
export const Modal = (props: Props) => {
  const { children, onClose, className } = props;
  return (
    <>
      <div css={overlayCss} />
      <div css={modalCss} className={className}>
        {children}
        {onClose && <CloseButton onClick={onClose} css={modalCloseButtonCss} />}
      </div>
    </>
  );
};

const overlayCss = css`
  opacity: 0.9;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #151136;
  z-index: 10;
`;

const modalCss = css`
  display: inline-block;
  min-width: 280px;
  width: calc(100% - 40px);
  border-radius: 5px;
  margin: auto;
  background-color: var(--general-base-300);
  box-shadow: 0 10px 60px rgba(16, 8, 28, 0.8);

  position: absolute;
  z-index: 11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const modalCloseButtonCss = css`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  color: #1d1546;
`;
