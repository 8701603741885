import * as React from 'react';
import { Button } from './secondary-button.styles';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
}

/**
 * @deprecated
 */
export const SecondaryButton: React.FC<Props> = props => {
  return <Button {...props}>{props.children}</Button>;
};
