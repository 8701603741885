import { css } from '@emotion/react';
import { Link } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SignInForm } from '../../components/forms/sign-in-form/sign-in-form.component';
import { PageMeta } from '../../components/page-meta';
import { AuthPageLayout } from '../../ui/layout/auth-page-layout/auth-page-layout.component';
import { FormFooter } from '../../ui/layout/form-layout/form-footer.component';
import { formLinkButtonCss } from '../../ui/layout/form-layout/form-link-button.component';
import { genRequestPasswordResetPath } from '../../utils/formatter/path.formatter';

export const SignIn: React.FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  return (
    <AuthPageLayout>
      <PageMeta title="ui-sign-in" description="ui-sign-in-description" />
      <SignInForm />
      <FormFooter css={formFooterCss}>
        <p>
          {t('Used a different login method?')}
          <Link to={genRequestPasswordResetPath(search)} css={formLinkButtonCss} isExternal={false}>
            {t('Recover access')}
          </Link>
        </p>
      </FormFooter>
    </AuthPageLayout>
  );
};

const formFooterCss = css`
  margin-bottom: 16px;
`;
