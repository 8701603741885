import { Logger, Region } from '@mobalytics/shared';
import { ExecutionResult } from 'apollo-link';
import i18next from 'i18next';
import { computed } from 'mobx';
import { RouterStore } from 'mobx-react-router';
import * as queryString from 'query-string';
import Url from 'url-parse';
import { v4 as uuid } from 'uuid';
import { AccountApi } from '../api/account-api';
import { LolApi } from '../api/lol-api';
import { GqlResponse } from '../types/api.types';
import { AccountsAppPath, AppRedirectQueryParams, AuthAction } from '../types/app.types';
import { GQLAPIErrorMessage } from '../types/error.types';
import { generateProviderEnterUrl } from '../utils/auth-providers';
import { formatError } from '../utils/format';
import { parseAppQueryParams } from '../utils/url';
import { validateEmail, validateName, validatePassword, validateToken } from '../utils/validation';
import { mapThemeToGame } from '../utils/mapping/map-theme-to-game.utils';
import { SingUpMarketingParams } from '../__generated/accounts/types';
import { UpdateAccountInfoMutation } from '../api/accounts/mutations/__generated/update-account-info-mutation.gql.generated';
import { Provider } from '../types/provider';
import { Theme } from '../types/theme';
import { SignUpI } from './../types/auth.types';
import { ConfigStore } from './config-store';
import { InputStore } from './input-store';
import { UserSettingsStore } from './user-settings-store';

export class AuthStore {
  constructor(
    private inputStore: InputStore,
    private routerStore: RouterStore,
    private configStore: ConfigStore,
    private i18nStore: i18next.i18n,
    private userSettingsStore: UserSettingsStore,
    private redirectUri: string | null,
    private theme: Theme,
    private marketing: SingUpMarketingParams | null,
    public mobaReferrerId: string | null
  ) {}

  @computed
  get authProviders(): Provider[] {
    return [Provider.Discord, Provider.Google, Provider.Twitch];
  }

  // region ---- sign in
  signIn = (urlEmail?: string, urlPassword?: string, continueFrom?: string) => {
    this.inputStore.resetErrors();

    const { email: inputEmail, password: inputPassword } = this.inputStore;

    const email = urlEmail || inputEmail;
    const password = urlPassword || inputPassword;

    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);

    if (emailError || passwordError) {
      this.inputStore.setEmailError(emailError, null);
      this.inputStore.setPasswordError(passwordError);
    } else {
      this.inputStore.setIsLoading(true);
      AccountApi.signIn(email, password, continueFrom)
        .then(this.onSignInSuccess)
        .catch(this.onResponseError);
    }
  };

  private onSignInSuccess = async () => {
    const { return_to_payment_pathname, ...restParams } = parseAppQueryParams();
    await this.fetchUserData();
    if (return_to_payment_pathname) {
      this.inputStore.setIsLoading(false);
      this.routerStore.history.push({
        pathname: return_to_payment_pathname,
        search: queryString.stringify(restParams, { encode: true }),
      });
    } else {
      this.inputStore.setIsLoading(false);
      this.redirectToApp({ auth_action: AuthAction.SIGN_IN });
    }
  };
  // endregion

  // region ---- sign up
  signUp = ({ urlName, urlEmail, urlPassword, continueFrom }: SignUpI) => {
    this.inputStore.resetErrors();

    const { name: inputName, email: inputEmail, password: inputPassword } = this.inputStore;

    const name = urlName || inputName;
    const email = urlEmail || inputEmail;
    const password = urlPassword || inputPassword;

    const nameError = validateName(name);
    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);

    if (nameError || emailError || passwordError) {
      this.inputStore.setNameError(nameError);
      this.inputStore.setEmailError(emailError, null);
      this.inputStore.setPasswordError(passwordError);
    } else {
      // todo: Serj, why emailsReceivingAgreement unused?
      this.inputStore.setIsLoading(true);
      AccountApi.signUp({
        name,
        email,
        password,
        continueFrom,
        marketing: this.marketing,
        mobaReferrerId: this.mobaReferrerId,
      })
        .then(this.onSignUpSuccess)
        .catch(this.onResponseError);
    }
  };

  private onSignUpSuccess = async () => {
    const { return_to_payment_pathname, ...restParams } = parseAppQueryParams();
    await this.fetchUserData();
    if (return_to_payment_pathname) {
      this.inputStore.setIsLoading(false);
      this.routerStore.history.push({
        pathname: return_to_payment_pathname,
        search: queryString.stringify(restParams, { encode: true }),
      });
    } else {
      // send user to the app
      this.redirectToApp({ auth_action: AuthAction.SIGN_UP });
    }
  };
  // endregion

  // region ---- fetch user settings and wallet
  private fetchUserData() {
    return this.userSettingsStore.fetchUser();
  }
  // endregion

  // region ---- sign out
  signOut = () => {
    this.inputStore.resetErrors();
    this.inputStore.setIsLoading(true);
    AccountApi.signOut()
      .then(this.onSignOutSuccess)
      .catch(this.onResponseError);
  };

  onSignOutSuccess = () => {
    this.inputStore.setIsLoading(false);
    this.routerStore.history.push({ pathname: AccountsAppPath.SIGN_IN, search: this.routerStore.location.search });
  };
  // endregion

  // region ---- sign up via provider
  connectProvider = (provider: Provider, continueFrom?: string) => {
    const redirectUri = this.generateRedirectUri();
    if (redirectUri) {
      this.inputStore.setIsLoading(true);
      window.location.href = generateProviderEnterUrl(
        provider,
        redirectUri,
        this.theme,
        this.i18nStore.language,
        continueFrom,
        this.marketing,
        this.mobaReferrerId || undefined
      );
    } else {
      Logger.error('Unable to connect provider, redirect url is not set');
    }
  };

  // endregion

  // region ---- connect moba account
  connectMoba = (email: string, continueFrom: string) => {
    this.inputStore.resetErrors();
    continueFrom
      ? this.signIn(email, undefined, continueFrom)
      : this.inputStore.setGeneralError(formatError(GQLAPIErrorMessage.INTERNAL_ERROR));
  };

  // endregion

  // region ---- request reset password
  requestPasswordReset = () => {
    this.inputStore.resetErrors();

    const { email } = this.inputStore;
    const emailError = validateEmail(email);

    if (emailError) {
      this.inputStore.setEmailError(emailError, null);
    } else {
      this.inputStore.setIsLoading(true);
      const redirectUrl = this.generateRedirectUri();
      redirectUrl &&
        AccountApi.requestPasswordReset({
          email,
          lang: this.i18nStore.language,
          game: mapThemeToGame(this.configStore.theme),
          redirectUrl,
        })
          .then(this.onRequestPasswordReset)
          .catch(this.onResponseError);
    }
  };

  private onRequestPasswordReset = () => {
    this.inputStore.setIsLoading(false);
    this.routerStore.push({
      pathname: AccountsAppPath.REQUEST_PASSWORD_RESET_SUCCESS,
      search: this.routerStore.location.search,
    });
  };

  // endregion

  // region ---- reset password
  resetPassword = () => {
    this.inputStore.resetErrors();

    const { token, password, passwordConfirmation } = this.inputStore;

    const tokenError = validateToken(token);
    const passwordError = validatePassword(password);

    if (tokenError || passwordError) {
      this.inputStore.setPasswordError(passwordError);
      this.inputStore.setTokenError(tokenError);
    } else if (password !== passwordConfirmation) {
      this.inputStore.setPasswordConfirmationError(formatError(GQLAPIErrorMessage.PASSWORD_CONFIRMATION_DOESNT_MATCH));
    } else {
      this.inputStore.setIsLoading(true);
      AccountApi.resetPassword(token, passwordConfirmation)
        .then(this.onResetPasswordSuccess)
        .catch(this.onResponseError);
    }
  };

  private onResetPasswordSuccess = () => {
    this.inputStore.setIsLoading(false);
    this.routerStore.push({
      pathname: AccountsAppPath.PASSWORD_RESET_SUCCESS,
      search: this.routerStore.location.search,
    });
  };

  // endregion

  // region ---- sign up details
  sendSignUpDetails = (name: string, continueFrom: string | undefined) => {
    this.inputStore.resetErrors();
    continueFrom
      ? this.signUp({ urlName: name, urlEmail: undefined, urlPassword: uuid(), continueFrom })
      : this.inputStore.setGeneralError(formatError(GQLAPIErrorMessage.INTERNAL_ERROR));
  };

  // endregion

  // region ---- errors handling
  private onResponseError = (error: GQLAPIErrorMessage | null) => {
    this.inputStore.setIsLoading(false);

    const message = error && formatError(error);
    switch (error) {
      case GQLAPIErrorMessage.AUTH_CREDENTIALS_INVALID:
        this.inputStore.setGeneralError(message);
        break;
      case GQLAPIErrorMessage.EMAIL_INVALID:
      case GQLAPIErrorMessage.EMAIL_IS_EMPTY:
      case GQLAPIErrorMessage.EMAIL_NOT_FOUND:
      case GQLAPIErrorMessage.EMAIL_INVALID_FORMAT:
      case GQLAPIErrorMessage.EMAIL_ALREADY_EXISTS:
      case GQLAPIErrorMessage.AUTH_EMAIL_ALREADY_EXISTS:
        this.inputStore.setEmailError(message, error);
        break;
      case GQLAPIErrorMessage.NAME_INVALID:
      case GQLAPIErrorMessage.NAME_IS_EMPTY:
        this.inputStore.setNameError(message);
        break;
      case GQLAPIErrorMessage.PASSWORD_INVALID:
      case GQLAPIErrorMessage.PASSWORD_IS_EMPTY:
      case GQLAPIErrorMessage.PASSWORD_TOO_LONG:
      case GQLAPIErrorMessage.PASSWORD_TOO_SHORT:
        this.inputStore.setPasswordError(message);
        break;
      case GQLAPIErrorMessage.TOKEN_INVALID:
        this.inputStore.setTokenError(message);
        break;
      case GQLAPIErrorMessage.SUMMONER_DOES_NOT_EXIST:
        this.inputStore.setSummonerNameError(message);
        break;
      default:
        this.inputStore.setGeneralError(message);
    }
  };

  // endregion

  // region ---- redirect to game app
  redirectToApp = (params?: AppRedirectQueryParams) => {
    const url = this.generateRedirectUri(params);
    if (url) {
      window.location.href = url;
    } else {
      Logger.error('Unable to redirect to app, redirect url is not set');
      this.inputStore.setGeneralError(formatError(GQLAPIErrorMessage.INTERNAL_ERROR));
    }
  };

  private generateRedirectUri = (params?: AppRedirectQueryParams): string | null => {
    const urlStr = this.redirectUri || this.configStore.redirectUri;
    if (urlStr) {
      const url = Url(urlStr);
      const queryStr = (url.query as unknown) as string;
      const query = queryString.parse(queryStr);
      const updatedQuery = { ...query, ...params };
      return `${url.origin}${url.pathname}?${queryString.stringify(updatedQuery)}`;
    }
    return null;
  };
  // endregion

  // region ---- LOL set summoner name

  setSummoner = (summonerName: string, region: Region) => {
    const { token } = this.inputStore;
    const tokenError = validateToken(token);

    if (tokenError) {
      LolApi.setSummoner(summonerName, region, this.i18nStore.language)
        .then(this.onSetSummoner)
        .catch(error => {
          this.inputStore.setSummonerNameError(error);
          this.onResponseError(error);
        });
    }
  };

  private onSetSummoner = (response: GqlResponse<ExecutionResult<UpdateAccountInfoMutation>>) => {
    const [data, error] = response;
    if (data) {
      this.redirectToApp({ auth_action: AuthAction.SIGN_UP });
    }
    if (error) {
      this.inputStore.setSummonerNameError(error);
    }
  };

  // endregion
}
