import { css } from '@emotion/react';
import { Tab, Tabs, TabsBasicVariant } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { paymentFormHeaderCss } from '../payment-details.styles';
import { Marketing } from '../../../../marketing/marketing';
import { TreasuryPaymentsProvider } from '../../../../__generated/treasury/types';

interface Props {
  className?: string;
  children: React.ReactNode;
  paymentMethod: TreasuryPaymentsProvider;
  onChangeProvider: (provider: TreasuryPaymentsProvider) => void;
}

export const PaymentMethodSelector: React.FC<Props> = props => {
  const { className, paymentMethod, onChangeProvider, children } = props;
  const { t } = useTranslation();
  return (
    <div className={className}>
      <p css={paymentFormHeaderCss}>{t('Enter payment details')}</p>
      <Tabs
        value={paymentMethod}
        onChange={provider => {
          onChangeProvider(provider);
        }}
        variant={TabsBasicVariant.Page}
        css={tabsCss}
      >
        <Tab
          value={TreasuryPaymentsProvider.STRIPE}
          label={t('Credit/debit card')}
          onClick={Marketing.paymentInfoStripeClicked}
        />
        <Tab
          value={TreasuryPaymentsProvider.BRAINTREE}
          label={t('PayPal')}
          onClick={Marketing.paymentInfoPayPalClicked}
        />
      </Tabs>

      {children}
    </div>
  );
};

const tabsCss = css`
  margin-bottom: 24px;
  & > button {
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    justify-content: center;
  }
`;
