import * as React from 'react';
import { NString } from '@mobalytics/shared';
import { PaymentConfirmationLayout } from '../../../payment-confirmation-layout/payment-confirmation-layout.component';
import { TreasuryPaymentsGame } from '../../../../../../__generated/treasury/types';
import { AllowedActionsT } from '../../../../payment.types';
import { useStripeFlowConfirmationInfo } from '../stripe-flow-confirmation-info.hook';
import { StripeFlowElementsProvider } from '../stripe-flow-elements-provider.component';
import { StripeExistingFlowConfirmation } from './stripe-existing-flow-confirmation/stripe-existing-flow-confirmation.component';
import { SavedCardInfo } from './saved-card-info/saved-card-info.component';

interface Props {
  className?: string;
  // data
  stripeCard: {
    last4: string;
  };
  submitButtonLabel: string;

  planId: string;
  game: TreasuryPaymentsGame;
  coupon: NString;
  allowedActions: AllowedActionsT;
}

export const StripeExistingFlow: React.FC<Props> = props => {
  const { className, stripeCard, submitButtonLabel, coupon, allowedActions, planId, game } = props;
  const { loading, fetchConfirmationInfo, confirmationInfo } = useStripeFlowConfirmationInfo({
    planId,
    game,
    coupon,
    allowedActions,
  });
  return (
    <div className={className}>
      <PaymentConfirmationLayout
        className={className}
        loading={loading || !!confirmationInfo}
        buttonLabel={submitButtonLabel}
        onSubmit={() => fetchConfirmationInfo(false)}
        errorMessage={null}
      >
        <SavedCardInfo last4={stripeCard.last4} />
        {confirmationInfo && (
          <StripeFlowElementsProvider confirmationInfo={confirmationInfo}>
            <StripeExistingFlowConfirmation confirmationInfo={confirmationInfo} />
          </StripeFlowElementsProvider>
        )}
      </PaymentConfirmationLayout>
    </div>
  );
};
