import * as React from 'react';
import { Logger, processUnionEntry } from '@mobalytics/shared';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useHistory, useLocation } from 'react-router';
import { genPaymentErrorPath } from '../../../../../../utils/formatter/path.formatter';
import { PaymentError } from '../../../../payment-error.types';
import { StripeConfirmationInfo } from '../stripe-flow.types';
import { useAllowedActionsQueryLazyQuery } from '../../../../_gql/__generated/allowed-actions.query.gql.generated';
import { treasuryApolloClient } from '../../../../../../treasury-apollo-client';
import { TreasuryPaymentsGame } from '../../../../../../__generated/treasury/types';
import { processAllowedActions } from '../../../../utils/process-allowed-actions.utils';
import { CustomerT } from '../../../../payment.types';

export const useStripeNewFlowPaymentConfirmation = (
  confirmationInfo: StripeConfirmationInfo,
  game: TreasuryPaymentsGame
) => {
  const { search } = useLocation();
  const { replace } = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();

  const [checkAllowedActions] = useAllowedActionsQueryLazyQuery({
    client: treasuryApolloClient,
    fetchPolicy: 'network-only',
    variables: {
      allowedActionsInput: {
        game,
      },
    },
  });

  const confirmPayment = React.useCallback(async () => {
    try {
      setLoading(true);
      if (!stripe) {
        Logger.error('[useStripeNewFlowPaymentConfirmation]:[confirmPayment]: stripe is missing');
        replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
        return;
      }
      if (!elements) {
        Logger.error('[useStripeNewFlowPaymentConfirmation]:[confirmPayment]: elements is missing');
        replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
        return;
      }
      debugger;
      if (confirmationInfo.selectedAction) {
        const availableActionsResponse = await checkAllowedActions();
        const customer = processUnionEntry<CustomerT>(
          availableActionsResponse.data?.treasury?.payments?.customer,
          'TreasuryPaymentsCustomer'
        );
        const allowedActions = processAllowedActions(customer);
        if (!allowedActions || !allowedActions[confirmationInfo.selectedAction]) {
          Logger.error(
            '[useStripeNewFlowPaymentConfirmation]:[confirmPayment]: availableActionsResponse',
            availableActionsResponse
          );
          replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
          return;
        }
      }
      const submitResponse = await elements.submit();
      if (submitResponse.error) {
        Logger.error('[useStripeNewFlowPaymentConfirmation]:[confirmPayment]: submitResponse', submitResponse.error);
        setError(submitResponse.error.message);
        return;
      }
      let response;
      if (confirmationInfo.type === 'payment') {
        response = await stripe.confirmPayment({
          clientSecret: confirmationInfo.secret,
          elements,
          confirmParams: {
            return_url: confirmationInfo.returnUrl,
          },
        });
      } else if (confirmationInfo.type === 'setup') {
        response = await stripe.confirmSetup({
          clientSecret: confirmationInfo.secret,
          elements,
          confirmParams: {
            return_url: confirmationInfo.returnUrl,
          },
        });
      }
      if (response?.error) {
        Logger.error('[useStripeNewFlowPaymentConfirmation]:[confirmPayment]: stripe response error', response.error);
        replace(genPaymentErrorPath(search, PaymentError.PAYMENT_DECLINED));
        return;
      }
    } catch (e) {
      Logger.error('[useStripeNewFlowPaymentConfirmation]:[confirmPayment]: error', e);
    } finally {
      setLoading(false);
    }
  }, [
    checkAllowedActions,
    confirmationInfo.returnUrl,
    confirmationInfo.secret,
    confirmationInfo.selectedAction,
    confirmationInfo.type,
    elements,
    replace,
    search,
    stripe,
  ]);

  return {
    loading,
    error,
    confirmPayment,
  };
};
