import { AppThemeConfig } from '../../types/app.types';
import { Game } from '../../__generated/accounts/types';
import { Provider } from '../../types/provider';

export const config: AppThemeConfig = {
  gameName: Game.LOL,
  testimonials: null,
  wallpaper: null,
  isPremiumSubscriptionAvailable: false,
  primaryProviders: [Provider.Google, Provider.Discord],
  fallbackRedirectUri: 'https://mobalytics.gg',
  paymentWallpaper: null,
  paymentCongratulationWallpaper: null,
};
