import { css } from '@emotion/react';
import { TextBody2RRMixin } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PlanT } from '../../../payment.types';
import { getNextPaymentDate } from '../../../utils/get-next-regular-payment-date.utils';
import { getMoneyAsString } from '../../../utils/get-money-as-string.utils';
import { getNextTrialPaymentDate } from '../../../utils/get-next-trial-payment-date.utils';

interface Props {
  className?: string;
  plan: PlanT;
}

export const NextPayment: React.FC<Props> = props => {
  const { className, plan } = props;
  const { t } = useTranslation();
  const finalPrice = getMoneyAsString(plan.currency, plan.finalPrice);
  const basePrice = getMoneyAsString(plan.currency, plan.basePrice);
  if (plan.isTrial) {
    const nextPaymentDate = getNextTrialPaymentDate();
    if (plan.discount?.coupon || plan.discount?.prorate) {
      return (
        <div className={className} css={wrapperCss}>
          <p>
            {t(
              'First payment {{finalPrice}} will occur on {{nextPaymentDate}}, followed by the regular price of {{basePrice}}.',
              {
                nextPaymentDate,
                finalPrice,
                basePrice,
              }
            )}
          </p>
        </div>
      );
    }
    return (
      <div className={className} css={wrapperCss}>
        <p>{t('First payment occurs {{nextPaymentDate}}', { nextPaymentDate })}</p>
      </div>
    );
  }
  const nextPaymentDate = getNextPaymentDate(plan.interval, plan.intervalCount);
  if (plan.discount?.coupon || plan.discount?.prorate) {
    return (
      <div className={className} css={wrapperCss}>
        <p>
          {t('Next payment occurs {{nextPaymentDate}} with regular price {{basePrice}}', {
            nextPaymentDate,
            basePrice,
          })}
        </p>
      </div>
    );
  }
  return (
    <div className={className} css={wrapperCss}>
      <p>{t('Next payment occurs {{nextPaymentDate}}', { nextPaymentDate })}</p>
    </div>
  );
};

const wrapperCss = css`
  ${TextBody2RRMixin};
  color: var(--general-grey-300);
  text-align: center;
`;
