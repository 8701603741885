import * as React from 'react';
import { isDef } from '@mobalytics/shared';
import { Radio } from '../../../../../ui/radio/radio.component';
import { ThankYouModalOption } from '../survey-modal.types';
import { formatThankYouModalOption } from '../survey-modal.utils';

interface Props {
  className?: string;
  value: ThankYouModalOption;
  onChange: (option: ThankYouModalOption) => void;
  isActive?: boolean;
}

export const ThankYouModalRadio: React.FC<Props> = props => {
  const { className } = props;
  const { value, onChange, isActive = false } = props;

  const onChangeHandler = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      if (isDef(value)) {
        onChange(event.target.value as ThankYouModalOption);
      }
    },
    [onChange]
  );

  return (
    <Radio
      className={className}
      id={value}
      label={formatThankYouModalOption(value)}
      isActive={isActive}
      onChange={onChangeHandler}
    />
  );
};
