import { css } from '@emotion/react';
import { isDefAndNotEmpty, Logger, NString, Nullable, TextBody1RRMixin, TextH2OMMixin } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router';
import { contentLoadingCss } from '../payment.styles';
import { AllowedActionsT, AvailableStepT, PlanT } from '../payment.types';
import { genPaymentBillingPath } from '../../../utils/formatter/path.formatter';
import { getQuerySearchWithCoupon } from '../utils/get-coupon-url';
import { TreasuryFlowInfoData, TreasuryPaymentsGame } from '../../../__generated/treasury/types';
import { ApplyCoupon } from './apply-coupon/apply-coupon.component';
import { usePlanSelection } from './plan-selection.hook';
import { PlansList } from './plans-list/plans-list.component';
import { TrialBlock } from './trial-block/trial-block.component';
import { ProrateNotification } from './prorate-notification/prorate-notification.component';

interface Props {
  className?: string;
  loading: boolean;

  game: TreasuryPaymentsGame;
  plans: PlanT[];
  trialPlan: PlanT | null;
  currentPlanId: NString;
  allowedActions: AllowedActionsT;
  flowInfo: Nullable<TreasuryFlowInfoData>;
  flowStep: Nullable<AvailableStepT>;

  refetchWithCoupon: (coupon: string, game: TreasuryPaymentsGame) => Promise<unknown>;
}

export const PlanSelection: React.FC<Props> = props => {
  const {
    className,
    game,
    loading,
    plans,
    trialPlan,
    currentPlanId,
    allowedActions,
    flowStep,
    flowInfo,
    refetchWithCoupon,
  } = props;
  const { search } = useLocation();
  const { handlePlanClick } = usePlanSelection();
  const { t } = useTranslation();
  const latestPlan = plans?.[plans.length - 1];

  if (!loading && plans.length === 1) {
    Logger.info('Redirecting to billing info because there is only one plan available');
    return <Redirect to={genPaymentBillingPath(plans[0].id, search)} push />;
  }

  const plansWithDiscount = plans?.filter(plan => plan.discount?.coupon);

  if (!loading && plansWithDiscount?.length === 1) {
    Logger.info('Redirecting to billing info because there is only one plan with discount');
    const plan = plansWithDiscount[0];
    const searchWithCoupon = getQuerySearchWithCoupon(plan.appliedCoupon || null);
    return <Redirect to={genPaymentBillingPath(plan.id, searchWithCoupon)} push />;
  }

  return (
    <div className={className} css={wrapperCss}>
      <div css={loading && contentLoadingCss}>
        <h2 css={titleCss}>
          {currentPlanId ? t('Do you want to change plan?') : t('unlock the ultimate mobalytics experience')}
        </h2>
        <p css={subTitleCss}>{t('choose your plan')}</p>

        <div css={plansWrapperCss}>
          <PlansList
            plans={plans}
            currentPlanId={currentPlanId}
            onPlanClick={handlePlanClick}
            css={plansListCss}
            allowedActions={allowedActions}
          />
          {latestPlan && latestPlan.discount.prorate && (
            <ProrateNotification prorateDiscount={latestPlan.discount.prorate} />
          )}
          <div css={trialPlansCss}>
            {trialPlan && !currentPlanId && (
              <TrialBlock
                plan={trialPlan}
                key={trialPlan.id}
                refetchWithCoupon={refetchWithCoupon}
                game={game}
                flowStep={flowStep}
                flowInfo={flowInfo}
              />
            )}
          </div>
        </div>
      </div>
      {!allowedActions.renew && (
        <ApplyCoupon css={applyCouponCss} loading={loading} isCouponApplied={isDefAndNotEmpty(plansWithDiscount)} />
      )}
    </div>
  );
};

const wrapperCss = css`
  margin-bottom: 20px;
`;

const titleCss = css`
  ${TextH2OMMixin};
  font-weight: 500;
  text-align: center;
  margin-top: 40px;
`;

const subTitleCss = css`
  ${TextBody1RRMixin};
  margin-top: 16px;
  text-align: center;
  color: var(--general-grey-100);
  &:first-letter {
    text-transform: uppercase;
  }
`;

const plansListCss = css`
  margin-top: 64px;
`;

const trialPlansCss = css`
  min-height: 100px;
  margin-top: 24px;
`;

const applyCouponCss = css`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
  min-height: 70px;
`;

const plansWrapperCss = css`
  width: fit-content;
  margin: 0 auto;
`;
