import { css } from '@emotion/react';
import { openInNewWindowAdditionalProps, TextBody2RRMixin } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PRIVACY_POLICY_LINK, TERMS_OF_SERVICE_LINK } from '../../constants';
import { formLinkButtonCss } from '../layout/form-layout/form-link-button.component';

interface Props {
  className?: string;
}

export const TermsAndServiceAgreement: React.FC<Props> = props => {
  const { className } = props;
  const { t } = useTranslation();
  return (
    <p css={wrapperCss} className={className}>
      {t('ui-i-accept-all')}
      <a href={TERMS_OF_SERVICE_LINK} css={formLinkButtonCss} {...openInNewWindowAdditionalProps}>
        {t('ui-terms-of-service')}
      </a>
      {t('ui-and')}
      <a href={PRIVACY_POLICY_LINK} css={formLinkButtonCss} {...openInNewWindowAdditionalProps}>
        {t('ui-privacy-policy')}
      </a>
    </p>
  );
};

const wrapperCss = css`
  ${TextBody2RRMixin};
  cursor: default;
`;
