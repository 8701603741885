import styled from '@emotion/styled';
import * as React from 'react';
import { Region } from '@mobalytics/shared';
import { Helmet } from '../../../../ui/icons/common/helmet';
import { Input } from '../../../../ui/input';

interface Props {
  region: Region;
  onRegionClick: () => void;
  summonerName: string;
  onChangeSummonerName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  summonerNameError: string | null;
  isLoading: boolean;
  className?: string;
}

/** @deprecated */
export const SummonerInput: React.FC<Props> = props => {
  const { region, onRegionClick, summonerName, onChangeSummonerName, summonerNameError, isLoading, className } = props;

  return (
    <Wrapper className={className}>
      <InputStyled
        value={summonerName}
        placeholder={'Your Game Name #Tag'}
        onChange={onChangeSummonerName}
        icon={<Helmet />}
        error={summonerNameError}
        name="summoner-email"
        isDisabled={isLoading}
      />
      <RegionSelectorWrapper>
        <SelectedRegion onClick={onRegionClick}>{region}</SelectedRegion>
      </RegionSelectorWrapper>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  position: relative;
  z-index: 2;
`;

export const InputStyled = styled(Input)`
  padding-right: 60px;
`;

export const RegionSelectorWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translate(0, -50%);
`;

export const SelectedRegion = styled.div`
  width: 44px;
  height: 20px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.base2};
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  line-height: 20px;
  cursor: pointer;

  transition: background ease 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.base3};
  }
`;

export const SelectedOptionsWrapper = styled.div`
  position: absolute;
  border: none;
  outline: none;

  width: 100%;
  background: ${({ theme }) => theme.colors.base3};
`;

export const SelectedOption = styled.div`
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  padding: 4px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.base4};
  }
`;
