import { Theme } from '@emotion/react';

// fonts
export const Roboto = 'Roboto, sans-serif';

const colors = {
  action: '#CCAD70',
  primary: '#FFF',
  secondary: '#AAABCA',
  negative: '#E54787',

  base1: '#1D1546',
  base2: '#4C4278',
  base3: '#565895',
  base4: '#3A3B7C',
  base5: '#897CA7',
  base6: '#25204a',
  base7: '#292156',
  accent1: '#18BD9B',
  accent2: '#E01273',
  accent3: '#38C6F4',
  accent4: '#FFC306',
  accent5: '#6F3C97',
  accent6: '#EB7503',

  gradients: {
    iconBox: 'linear-gradient(rgb(194, 158, 82) 0px, rgb(122, 90, 38) 88%, rgb(168, 132, 73))',
  },

  buttons: {
    primary: '#fc7c00',
    secondary: '#565895',
    link: '#ccad70',
    orange: '#EB7503',
    green: '#15a98b',
    gold: '#CCAD70',
    twiLight: '#565697',
  },

  socialButtons: {
    discord: '#7289da',
    google: '#db4437',
    twitch: '#6441a5',
    facebook: '#0773f6',
    steam: '#5c7e0f',
    faceit: '#ff5500',
  },
};

export const DefaultTheme: Theme = {
  colors,
};

export const LolTheme: Theme = {
  colors,
};

export const ValorantTheme: Theme = {
  colors,
};

export const LostArkTheme: Theme = {
  colors,
};
