import { action, computed, observable } from 'mobx';
import { ConfigStore } from './config-store';

export class TestimonialStore {
  @observable activeIndex: number = 1;

  constructor(private configStore: ConfigStore) {}

  @computed
  get testimonials() {
    return this.configStore.testimonials;
  }

  @computed
  get length(): number {
    return (this.configStore.testimonials && this.configStore.testimonials.length) || 0;
  }

  @action
  scroll = () => {
    if (this.configStore.testimonials?.length) {
      this.activeIndex < this.configStore.testimonials.length - 1 ? (this.activeIndex += 1) : (this.activeIndex = 0);
    }
  };

  @action
  setActiveIndex = (value: number) => {
    this.activeIndex = value;
  };
}
