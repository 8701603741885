import { css } from '@emotion/react';
import { HBoxSCMixin, VBoxMixin } from '@mobalytics/shared';
import * as React from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const FormLayout: React.FC<Props> = props => {
  const { className, children } = props;
  return (
    <div className={className} css={wrapperCss}>
      <div css={containerCss}>{children}</div>
    </div>
  );
};

const wrapperCss = css`
  ${HBoxSCMixin};
  width: 100%;
  max-width: 320px;
  flex: 1 1 320px;
`;

const containerCss = css`
  ${VBoxMixin};
  gap: 20px;
  width: 100%;
`;
