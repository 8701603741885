import { gql } from '@apollo/client';

export const UpdateAccountInfoQuery = gql`
  mutation UpdateAccountInfo($settings: [MetaDataSettingsKVInput!]) {
    updateAccountInfo(settings: $settings) {
      settings {
        key
        value
      }
    }
  }
`;
