import i18next from 'i18next';
import { tPlural } from '../../../utils/format';
import { DEFAULT_LANGUAGE } from '../../../constants';
import { TreasuryPaymentsPlanInterval } from '../../../__generated/treasury/types';

export const getPlanDuration = (
  interval: TreasuryPaymentsPlanInterval,
  intervalCount: number,
  t: i18next.TFunction
): string => {
  switch (interval) {
    case TreasuryPaymentsPlanInterval.DAILY:
      return tPlural('plural-days', intervalCount, t, DEFAULT_LANGUAGE);
    case TreasuryPaymentsPlanInterval.MONTHLY:
      return tPlural('plural-months', intervalCount, t, DEFAULT_LANGUAGE);
    case TreasuryPaymentsPlanInterval.YEARLY:
      return intervalCount === 1
        ? tPlural('plural-months', 12, t, DEFAULT_LANGUAGE)
        : tPlural('plural-years', intervalCount, t, DEFAULT_LANGUAGE);
  }
};
