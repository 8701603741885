import { CDN_IMAGES } from '../../constants';
import { AppThemeConfig } from '../../types/app.types';
import { Game } from '../../__generated/accounts/types';
import { Provider } from '../../types/provider';

export const config: AppThemeConfig = {
  gameName: Game.LOST_ARK,
  isPremiumSubscriptionAvailable: false,
  wallpaper: `${CDN_IMAGES}/backgrounds/desktop-onboarding-bg.png`,
  paymentWallpaper: null,
  paymentCongratulationWallpaper: null,
  testimonials: null,
  primaryProviders: [Provider.Twitch, Provider.Google],
  fallbackRedirectUri: 'https://app.mobalytics.gg/lost-ark',
};
