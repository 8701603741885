import { validateStrEnumValue } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AccountApi } from '../../../api/account-api';
import { useAppStore } from '../../../store/app-store.hook';
import { AccountsAppPath } from '../../../types/app.types';
import { RawAPIErrorCode } from '../../../types/error.types';
import { DiscordSupportLinkButton } from '../../../ui/buttons/discord-support-link-button';
import { FormBody, FormFooter, FormLayout, FormSubTitle, FormTitle } from '../../../ui/layout/form-layout';
import { PromoGamersForGamers } from '../../../ui/promo-gamers-for-gamers/promo-gamers-for-gamers.component';
import { formatProviderName } from '../../../utils/format';
import { parseAppQueryParams } from '../../../utils/url';
import { Provider } from '../../../types/provider';
import {
  CleanUpAndLetMeInButton,
  DisclaimerAfterCleanUp,
  DiscordSupportDisclaimer,
  ProviderErrorFormTitle,
} from './error-form.styles';

interface ErrorFormProps {
  className?: string;
}

const RenderErrorMessage = () => {
  const { t } = useTranslation();
  const queryParams = parseAppQueryParams();
  const provider = validateStrEnumValue<Provider>(Provider, queryParams.provider);
  const { routerStore } = useAppStore();

  const clearAllAndRedirect = React.useCallback(() => {
    // redirect to login anyway
    AccountApi.signOut()
      .then(() =>
        routerStore.history.replace({ pathname: AccountsAppPath.SIGN_IN, search: routerStore.location.search })
      )
      .catch(() =>
        routerStore.history.replace({ pathname: AccountsAppPath.SIGN_IN, search: routerStore.location.search })
      );
  }, [routerStore.history, routerStore.location.search]);

  // invalid coupon
  if (queryParams.error_code === RawAPIErrorCode.COUPON_INVALID) {
    return <FormTitle>{t('error-access-code-invalid')}</FormTitle>;
  }

  // try to access closed beta directly
  if (
    queryParams.error_code === RawAPIErrorCode.BETA_ACCESS_ONLY ||
    queryParams.error_code === RawAPIErrorCode.FORBIDDEN
  ) {
    return (
      <FormBody>
        <FormTitle>{t('error-private-beta-title')}</FormTitle>
        <FormSubTitle>{t('error-private-beta-description')}</FormSubTitle>
      </FormBody>
    );
  }

  // conflict account error
  if (queryParams.error_code === RawAPIErrorCode.AUTH_EXTERNAL_CONFLICT) {
    return (
      <FormBody>
        <FormTitle>{t('error-provider-account-already-exists')}</FormTitle>
        <FormSubTitle>{t('error-provider-account-already-exists-description')}</FormSubTitle>
      </FormBody>
    );
  }

  if (queryParams.error_code === RawAPIErrorCode.CLEAR_AND_SIGN_IN) {
    AccountApi.signOut()
      .then(() =>
        routerStore.history.replace({ pathname: AccountsAppPath.SIGN_IN, search: routerStore.location.search })
      )
      .catch(() =>
        routerStore.history.replace({ pathname: AccountsAppPath.SIGN_IN, search: routerStore.location.search })
      );
    return (
      <>
        <FormBody>
          <ProviderErrorFormTitle>{t('error-something-wrong-here')}</ProviderErrorFormTitle>
          <FormSubTitle>
            {t('disclaimer-provider-auth-error', { provider: t(formatProviderName(Provider.Twitch)) })}
          </FormSubTitle>
          <CleanUpAndLetMeInButton onClick={clearAllAndRedirect}>
            {t('ui-clean-up-and-let-me-in')}
          </CleanUpAndLetMeInButton>
          <DisclaimerAfterCleanUp>{t('disclaimer-after-clean-up')}</DisclaimerAfterCleanUp>
        </FormBody>
        <FormFooter>
          <DiscordSupportDisclaimer>
            {t('disclaimer-contact-support-in')}
            &nbsp;
            <DiscordSupportLinkButton>{t('provider-discord')}</DiscordSupportLinkButton>
          </DiscordSupportDisclaimer>
          <PromoGamersForGamers />
        </FormFooter>
      </>
    );
  }

  // unknown provider error
  if (provider) {
    return (
      <>
        <FormBody>
          <ProviderErrorFormTitle>{t('error-something-wrong-here')}</ProviderErrorFormTitle>
          <FormSubTitle>
            {t('disclaimer-provider-auth-error', { provider: t(formatProviderName(provider)) })}
          </FormSubTitle>
          <CleanUpAndLetMeInButton onClick={clearAllAndRedirect}>
            {t('ui-clean-up-and-let-me-in')}
          </CleanUpAndLetMeInButton>
          <DisclaimerAfterCleanUp>{t('disclaimer-after-clean-up')}</DisclaimerAfterCleanUp>
        </FormBody>
        <FormFooter>
          <DiscordSupportDisclaimer>
            {t('disclaimer-contact-support-in')}
            &nbsp;
            <DiscordSupportLinkButton>{t('provider-discord')}</DiscordSupportLinkButton>
          </DiscordSupportDisclaimer>
          <PromoGamersForGamers />
        </FormFooter>
      </>
    );
  }

  // unknown error
  return <FormTitle>{t('error-internal')}</FormTitle>;
};

export const ErrorForm: React.FC<ErrorFormProps> = (props: ErrorFormProps) => {
  const { className } = props;
  return (
    <FormLayout className={className}>
      <RenderErrorMessage />
    </FormLayout>
  );
};
