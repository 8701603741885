import { css } from '@emotion/react';
import { Link, linkButtonStyles, TextSubtitle4RMMixin } from '@mobalytics/shared';
import * as React from 'react';

interface Props {
  className?: string;
  children: React.ReactElement;
  to: string;
}

export const FormTitleLink: React.FC<Props> = props => {
  const { className, children, to } = props;
  return (
    <Link to={to} css={wrapperCss} className={className}>
      {children}
    </Link>
  );
};

const wrapperCss = css`
  ${linkButtonStyles};
  ${TextSubtitle4RMMixin};
  position: absolute;
  top: -50%;
  left: 0;
  transform: translate(0px, -2px);

  &:before {
    content: '';
    position: absolute;
    width: 9px;
    height: 22px;
    border-left: 2px solid var(--general-grey-300);
    border-bottom: 1px solid var(--general-grey-300);
    bottom: 0;
    transform: translate(8px, 100%);
  }
`;
