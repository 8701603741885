import * as React from 'react';
import { AppRouter } from '../../app.router';
import { ConnectMobaAccount } from '../../pages/connect-moba-account/connect-moba-account.component';
import { DesktopSignInComplete } from '../../pages/desktop-sign-in-complete/desktop-sign-in-complete.component';
import { DesktopSignInRedirect } from '../../pages/desktop-sign-in-redirect/desktop-sign-in-redirect.component';
import { DesktopSignIn } from '../../pages/desktop-sign-in/desktop-sign-in.component';
import { DesktopSignOut } from '../../pages/desktop-sign-out/desktop-sign-out.component';
import { Error } from '../../pages/error/error.component';
import { PasswordResetSuccess } from '../../pages/password-reset-success/password-reset-success.component';
import { PasswordReset } from '../../pages/password-reset/password-reset.component';
import { RequestPasswordResetSuccess } from '../../pages/request-password-reset-success/request-password-reset-success.component';
import { RequestPasswordReset } from '../../pages/request-password-reset/request-password-reset.component';
import { SignIn } from '../../pages/sign-in/sign-in.component';
import { SignUpDetails } from '../../pages/sign-up-details/set-user-email.component';
import { SignUp } from '../../pages/sign-up/sign-up.component';
import { config } from './config';
import { LolSetSummoner } from './pages/lol-set-summoner-name/lol-set-summoner-name.component';

export const ThemeSync: React.FC = function() {
  return (
    <AppRouter
      config={config}
      Error={Error}
      SignIn={SignIn}
      SignUp={SignUp}
      LolSetSummoner={LolSetSummoner}
      SignUpDetails={SignUpDetails}
      PasswordReset={PasswordReset}
      DesktopSignIn={DesktopSignIn}
      DesktopSignOut={DesktopSignOut}
      ConnectMobaAccount={ConnectMobaAccount}
      PasswordResetSuccess={PasswordResetSuccess}
      RequestPasswordReset={RequestPasswordReset}
      DesktopSignInRedirect={DesktopSignInRedirect}
      DesktopSignInComplete={DesktopSignInComplete}
      RequestPasswordResetSuccess={RequestPasswordResetSuccess}
    />
  );
};
