import { css } from '@emotion/react';
import { ellipsisMixin, HBoxSCMixin, NString, TextBody2RRMixin, TextSubtitle3RMMixin } from '@mobalytics/shared';
import * as React from 'react';

interface TestimonialsListProps {
  className?: string;
  authorName: string;
  authorIcon: NString;
  text: string;
}

export const TestimonialItem: React.FC<TestimonialsListProps> = (props: TestimonialsListProps) => {
  const { className, authorIcon, authorName, text } = props;

  return (
    <div className={className}>
      <div css={lineCss}>
        {authorIcon && (
          <div css={authorIconWrapperCss}>
            <img css={authorIconCss} src={authorIcon} alt="" />
          </div>
        )}
        <p css={nameCss}>{authorName}</p>
      </div>
      {text && <p css={textCss}>{text}</p>}
    </div>
  );
};

const lineCss = css`
  ${HBoxSCMixin};
  gap: 12px;
  margin-bottom: 16px;
`;

const authorIconWrapperCss = css`
  flex: 0 0 auto;
  height: 40px;
  width: 40px;
  background: var(--general-base-300);
  border: 2px solid var(--general-gold-200);
  box-shadow: 0 0 2px var(--general-base-500) inset;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;

const authorIconCss = css`
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
`;

const nameCss = css`
  ${TextSubtitle3RMMixin};
  ${ellipsisMixin};
  color: var(--general-white-500);
`;

const textCss = css`
  ${TextBody2RRMixin};
`;
