import * as Types from '../../../../__generated/treasury/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PlansWithCouponQueryVariables = Types.Exact<{
  plansInput: Types.TreasuryPaymentsCouponPlansFilter;
}>;

export type PlansWithCouponQuery = {
  __typename: 'Query';
  treasury?: {
    __typename: 'TreasuryQuery';
    id: string;
    payments?: {
      __typename: 'TreasuryPaymentsQuery';
      plansWithCoupon?: {
        __typename: 'TreasuryPaymentsApplyCouponPayload';
        errors?: Array<
          | { __typename: 'TreasuryPaymentsBadRequestError'; message?: string | null }
          | { __typename: 'TreasuryPaymentsPaymentError'; message: string }
          | { __typename: 'TreasuryPaymentsUnauthenticatedError'; message?: string | null }
          | { __typename: 'TreasuryPaymentsUnknownError'; message?: string | null }
        > | null;
        plans?: Array<{
          __typename: 'TreasuryPaymentsPlan';
          id: string;
          interval: Types.TreasuryPaymentsPlanInterval;
          intervalCount: number;
          profit: string;
          isBestChoice: boolean;
          product: Types.TreasuryPaymentsProduct;
          trial?:
            | { __typename: 'TreasuryPaymentsTrial'; id: string; isAvailable: boolean }
            | { __typename: 'TreasuryPaymentsUnknownError' }
            | null;
          basePrice: { __typename: 'TreasuryPaymentsMoney'; amount: number; currency: string };
          finalPrice: { __typename: 'TreasuryPaymentsMoney'; amount: number; currency: string };
          appliedCoupon?: {
            __typename: 'TreasuryPaymentsCoupon';
            name: string;
            discount:
              | {
                  __typename: 'TreasuryPaymentsCouponDiscountAmount';
                  amount: { __typename: 'TreasuryPaymentsMoney'; amount: number; currency: string };
                }
              | { __typename: 'TreasuryPaymentsCouponDiscountPercentage'; percentage: number };
          } | null;
          appliedProration?: { __typename: 'TreasuryPaymentsMoney'; amount: number; currency: string } | null;
          viewOptions?: {
            __typename: 'PlanViewOptions';
            frame?: Types.PlanFrameOption | null;
            extraFeatures?: Array<Types.PlanExtraFeature> | null;
          } | null;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export const PlansWithCouponQueryDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PlansWithCouponQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'plansInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryPaymentsCouponPlansFilter' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'treasury' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plansWithCoupon' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filter' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'plansInput' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'errors' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'plans' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PlanFragment' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PlanFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryPaymentsPlan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBestChoice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trial' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryPaymentsTrial' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAvailable' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basePrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finalPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appliedCoupon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'TreasuryPaymentsCouponDiscountAmount' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'TreasuryPaymentsCouponDiscountPercentage' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'percentage' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appliedProration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'frame' } },
                { kind: 'Field', name: { kind: 'Name', value: 'extraFeatures' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;

/**
 * __usePlansWithCouponQuery__
 *
 * To run a query within a React component, call `usePlansWithCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansWithCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansWithCouponQuery({
 *   variables: {
 *      plansInput: // value for 'plansInput'
 *   },
 * });
 */
export function usePlansWithCouponQuery(
  baseOptions: Apollo.QueryHookOptions<PlansWithCouponQuery, PlansWithCouponQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlansWithCouponQuery, PlansWithCouponQueryVariables>(PlansWithCouponQueryDocument, options);
}
export function usePlansWithCouponQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlansWithCouponQuery, PlansWithCouponQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlansWithCouponQuery, PlansWithCouponQueryVariables>(
    PlansWithCouponQueryDocument,
    options
  );
}
export type PlansWithCouponQueryHookResult = ReturnType<typeof usePlansWithCouponQuery>;
export type PlansWithCouponQueryLazyQueryHookResult = ReturnType<typeof usePlansWithCouponQueryLazyQuery>;
export type PlansWithCouponQueryQueryResult = Apollo.QueryResult<PlansWithCouponQuery, PlansWithCouponQueryVariables>;
