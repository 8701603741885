import { Region } from '@mobalytics/shared';

export function getRegionOptions(): Region[] {
  return [
    Region.NA,
    Region.OCE,
    Region.TR,
    Region.PH,
    Region.EUW,
    Region.LAN,
    Region.RU,
    Region.TW,
    Region.EUNE,
    Region.LAS,
    Region.JP,
    Region.VN,
    Region.KR,
    Region.BR,
    Region.SG,
    Region.TH,
  ];
}
