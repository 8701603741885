import { Logger } from '@mobalytics/shared';
import * as history from 'history';
import * as mobx from 'mobx';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import * as ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Router } from 'react-router';
import { App } from './app.component';
import { initI18n } from './i18n/i18n';
import { Mixpanel } from './marketing/mixpanel';
import { AppStore } from './store/app-store';
import { AppStoreProvider } from './store/app-store.hook';
import { styles } from './ui/global/global-styles';
import { parseAppQueryParams } from './utils/url';

mobx.configure({ enforceActions: 'always' });

// app query string params
const queryParams = parseAppQueryParams();

// localization
const i18nStore = initI18n(queryParams.lang);

// router
const routerStore = new RouterStore();
const browserHistory = history.createBrowserHistory();
const syncHistory = syncHistoryWithStore(browserHistory, routerStore);

// marketing
Mixpanel.init();
Logger.init(true, true, 'text');

// app store
const appStore = new AppStore(i18nStore, routerStore);
i18nStore.on('loaded', (loaded: object) => Object.keys(loaded).length > 0 && appStore.setIsInitialLangLoaded(true));
const createAppStore = () => appStore;

if (window.location.origin !== 'https://account.mobalytics.gg') {
  window.appStore = appStore;
}

const Application = (
  <>
    <I18nextProvider i18n={i18nStore}>
      <AppStoreProvider createAppStore={createAppStore}>
        <Router history={syncHistory}>
          {styles}
          <App />
        </Router>
      </AppStoreProvider>
    </I18nextProvider>
  </>
);

ReactDOM.render(Application, document.getElementById('root'));
