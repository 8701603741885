import * as React from 'react';
import { RequestPasswordResetForm } from '../../components/forms/request-password-reset-form/request-password-reset-form.component';
import { PageMeta } from '../../components/page-meta';
import { AuthPageLayout } from '../../ui/layout/auth-page-layout/auth-page-layout.component';

export const RequestPasswordReset: React.FC = () => {
  return (
    <AuthPageLayout>
      <PageMeta title="ui-reset-password" description="ui-reset-password-description" />
      <RequestPasswordResetForm />
    </AuthPageLayout>
  );
};
