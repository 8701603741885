import { isDefAndNotEmpty, Nullable } from '@mobalytics/shared';

export const notNullArray = <T>(array: Nullable<Nullable<T>[]>): T[] | null => {
  if (!isDefAndNotEmpty(array)) return null;
  const result = [];
  for (const item of array) {
    if (item) {
      result.push(item);
    }
  }
  return isDefAndNotEmpty(result) ? result : null;
};
