import * as React from 'react';

export const InfoIcon: React.FC<React.BaseHTMLAttributes<SVGElement>> = props => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" {...props}>
    <path
      d="M273.927 191.984a7.966 7.966 0 00-2.911-2.911A7.825 7.825 0 00267 188c-1.451 0-2.79.358-4.015 1.073a7.964 7.964 0 00-2.912 2.911A7.825 7.825 0 00259 196c0 1.451.358 2.79 1.073 4.016a7.966 7.966 0 002.911 2.911A7.826 7.826 0 00267 204c1.451 0 2.79-.358 4.016-1.073a7.964 7.964 0 002.911-2.911A7.827 7.827 0 00275 196c0-1.451-.358-2.79-1.073-4.016zM268.334 201a.325.325 0 01-.094.24.323.323 0 01-.24.093h-2a.325.325 0 01-.24-.093.324.324 0 01-.093-.24v-2c0-.097.031-.177.094-.24a.325.325 0 01.24-.093h2c.097 0 .177.031.239.093a.325.325 0 01.094.24v2zm2.536-6.156a2.754 2.754 0 01-.292.63c-.107.163-.262.33-.463.5-.201.17-.361.292-.48.365-.117.073-.288.172-.51.297-.229.131-.418.303-.567.515-.15.212-.224.384-.224.516a.325.325 0 01-.094.24.324.324 0 01-.24.093h-2a.325.325 0 01-.24-.094.324.324 0 01-.093-.24v-.374c0-.466.182-.902.547-1.308a3.568 3.568 0 011.203-.9c.333-.154.57-.31.708-.47.14-.16.209-.371.209-.635 0-.23-.13-.434-.39-.615-.261-.18-.558-.27-.891-.27-.362 0-.66.083-.896.25-.23.166-.528.486-.896.958a.307.307 0 01-.26.125.294.294 0 01-.199-.062l-1.375-1.042c-.16-.125-.187-.27-.083-.438.89-1.479 2.177-2.218 3.865-2.218.604 0 1.194.142 1.77.427a3.945 3.945 0 011.444 1.208c.385.52.578 1.087.578 1.698 0 .306-.044.587-.13.844z"
      transform="translate(-623 -1442) translate(364 1254)"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
