import { action, observable } from 'mobx';
import { SerializableStore } from './serializable-store';

export interface SingleDataLoaderState<ErrorType> {
  error: ErrorType | null;
  isLoading: boolean | null;
  isLoaded: boolean | null;
  isNotFound: boolean | null;
}

export class SingleDataLoaderStore<ErrorType> extends SerializableStore {
  @observable error: ErrorType | null = null;
  @observable isLoading: boolean | null = null;
  @observable isLoaded: boolean | null = null;
  @observable isNotFound: boolean | null = null;

  onAPIError = (error: ErrorType) => {
    this.onAPIErrorInternal(error);
  };

  @action
  protected onAPIErrorInternal(error: ErrorType) {
    this.setIsLoading(false);
    this.setAPIError(error);
  }

  onAPISuccess = () => {
    this.onAPISuccessInternal();
  };

  @action
  protected onAPISuccessInternal() {
    this.setIsNotFound(false);
    this.setIsLoading(false);
    this.error = null;
  }

  @action
  setAPIError = (error: ErrorType) => {
    this.error = error;
  };

  @action
  setIsLoading = (value: boolean) => {
    if (!this.isLoaded && this.isLoading && !value) {
      this.isLoaded = true;
    }
    this.isLoading = value;
  };

  setIsNotFound = (value: boolean) => {
    this.setIsNotFoundInternal(value);
  };

  @action
  protected setIsNotFoundInternal(value: boolean) {
    this.isNotFound = value;
  }
}
