import * as React from 'react';
import { NString } from '@mobalytics/shared';
import { ControllerFieldState, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { useHistory } from 'react-router';
import { genPaymentPlanSelectionPath } from '../../../../utils/formatter/path.formatter';
import { getQuerySearchWithCoupon } from '../../utils/get-coupon-url';

export type CouponFormFields = {
  coupon: string;
};

type Params = {
  coupon: NString;
  loading: boolean;
  isCouponApplied: boolean;
};

type Result = {
  loading: boolean;
  isFormVisible: boolean;
  setIsFormVisible: React.Dispatch<React.SetStateAction<boolean>>;
  couponField: ControllerRenderProps<CouponFormFields, 'coupon'>;
  couponFieldState: ControllerFieldState;
  applyCoupon: () => void;
  removeCoupon: () => void;
};

export const useCoupon = (params: Params): Result => {
  const { coupon, isCouponApplied, loading } = params;
  const { push } = useHistory();
  const [isFormVisible, setIsFormVisible] = React.useState<boolean>(false);

  const { t } = useTranslation();

  const { control, handleSubmit, clearErrors, setValue, setError } = useForm<CouponFormFields>({
    mode: 'onSubmit',
    defaultValues: {
      coupon: coupon || '',
    },
  });

  const { field, fieldState } = useController({
    name: 'coupon',
    control,
    rules: {
      required: { message: t('Enter coupon'), value: true },
    },
  });

  React.useEffect(() => {
    if (coupon && isCouponApplied) {
      setIsFormVisible(false);
    }
  }, [coupon, isCouponApplied]);

  React.useEffect(() => {
    if (coupon && !isCouponApplied && !loading) {
      setError('coupon', { message: t('Invalid coupon') });
    }
  }, [coupon, isCouponApplied, loading, setError, t]);

  const applyCoupon = handleSubmit(async (data: CouponFormFields) => {
    clearErrors('coupon');
    push(genPaymentPlanSelectionPath(getQuerySearchWithCoupon(data.coupon)));
  });

  const removeCoupon = async () => {
    clearErrors('coupon');
    setValue('coupon', '');
    push(genPaymentPlanSelectionPath(getQuerySearchWithCoupon(null)));
  };

  return {
    loading,
    isFormVisible,
    setIsFormVisible,
    couponField: field,
    couponFieldState: fieldState,
    applyCoupon,
    removeCoupon,
  };
};
