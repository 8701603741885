import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useAppStore } from '../../../store/app-store.hook';
import { AccountsButton } from '../../../ui/buttons/accounts-button/accounts-button.component';
import { FormBody } from '../../../ui/layout/form-layout/form-body.component';
import { FormLayout } from '../../../ui/layout/form-layout/form-layout.component';
import { FormSubTitle } from '../../../ui/layout/form-layout/form-subtitle.component';
import { FormTitle } from '../../../ui/layout/form-layout/form-title.component';
import { genSignInPath } from '../../../utils/formatter/path.formatter';

interface PasswordResetSuccessFormProps {
  className?: string;
}

export const PasswordResetSuccessForm: React.FC<PasswordResetSuccessFormProps> = observer(
  (props: PasswordResetSuccessFormProps) => {
    const { className } = props;
    const { t } = useTranslation();
    const { inputStore } = useAppStore();
    const { reset } = inputStore;
    const { search } = useLocation();

    React.useEffect(() => reset, [reset]);

    return (
      <FormLayout className={className}>
        <FormTitle>{t('ui-password-successfully-changed')}</FormTitle>
        <FormSubTitle>{t('ui-password-successfully-changed-description')}</FormSubTitle>
        <FormBody>
          <Link to={genSignInPath(search)}>
            <AccountsButton analyticsButtonName="Sign In">{t('ui-sign-in')}</AccountsButton>
          </Link>
        </FormBody>
      </FormLayout>
    );
  }
);
