import { css } from '@emotion/react';
import { TextButton1CAPSRBMixin } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
}

export const ExistingButton: React.FC<Props> = props => {
  const { className } = props;
  const { t } = useTranslation();

  return (
    <p className={className} css={wrapperCss}>
      {t('current plan')}
    </p>
  );
};

const wrapperCss = css`
  ${TextButton1CAPSRBMixin};
  text-align: center;
  padding: 8px 20px;
`;
