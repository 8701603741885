import '@emotion/react';
import { NString, Nullable } from '@mobalytics/shared';
import { AppStore } from '../store/app-store';
import { Game } from '../__generated/accounts/types';
import { TestimonialItem } from './testimonials.types';
import { Provider } from './provider';
import { Theme } from './theme';

export enum AccountsAppPath {
  MAIN = '/',
  ERROR = '/error',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  LOL_SUMMONER_NAME = '/lol/summoner-name',
  PASSWORD_RESET = '/reset-password',
  SIGN_UP_DETAILS = '/sign-up-details',
  DESKTOP_SIGN_IN = '/desktop/sign-in',
  DESKTOP_SIGN_OUT = '/desktop/sign-out',
  CONNECT_MOBA_ACCOUNT = '/connect-moba-account',
  PASSWORD_RESET_SUCCESS = '/reset-password-success',
  REQUEST_PASSWORD_RESET = '/request-reset-password',
  DESKTOP_SIGN_IN_REDIRECT = '/desktop/sign-in-redirect',
  DESKTOP_SIGN_IN_COMPLETE = '/desktop/sign-in-complete',
  REQUEST_PASSWORD_RESET_SUCCESS = '/request-reset-password-success',
  PAYMENT = `/payment/:section?/:planId?`,
}

export type AppQueryParams = {
  // public params, used by game apps
  redirect_uri: NString;
  lang: NString;
  theme: NString;
  coupon?: NString;
  mobaReferrerId?: string;

  // internal consent params, used internally within consent
  continue_from: NString;
  access_code: NString;
  token: NString;
  email: { email: string[] | string | null | undefined };
  name: NString;
  provider: NString;
  error_code: NString;
  return_to_payment_pathname: NString;

  // misc
  version: NString;
  platform: NString;
};

export enum AuthAction {
  SIGN_IN = 'sign_in',
  SIGN_UP = 'sign_up',
  CONNECT = 'connect',
}

export type AuthProviderQueryParams = {
  redirect_uri: string;
  error_redirect_uri: string;
  continue_from?: string;
  theme: Theme;
  lang: string;
  referrer_code?: string;
};

export type AppRedirectQueryParams = {
  error_code?: NString;
  auth_action?: AuthAction;
  lang?: string;
};

export type AppThemeConfig = {
  gameName: Game;
  wallpaper: string | null;
  errorWallpaper?: string | null;
  paymentWallpaper: string | null;
  paymentCongratulationWallpaper: string | null;
  testimonials: TestimonialItem[] | null;
  primaryProviders: Provider[];
  fallbackRedirectUri: string; // used in case client app didn't pass it via query
  isPremiumSubscriptionAvailable: boolean;
};

export interface AppPreloadedState {
  CDN_URL: NString;
  API_URL: NString;
  STRIPE_TOKEN: NString;
  MIXPANEL_TOKEN: NString;
  MIXPANEL_URL: NString;
  DESKTOP_REDIRECT_URL: NString;
  FALLBACK_REDIRECT_URL: NString;
  BRAIN_TREE_TOKEN: NString;
  PAYPAL_ENV: 'sandbox' | 'production';
  PAYPAL_CLIENT_ID: NString;
  GQL_HTTP_URL: NString;
  TREASURY_HTTP_URL: NString;
}

export type ConnectAccountInfo = { email: string; providers: Provider[] | null };

declare global {
  type GqlTreasuryPaymentsTime = string;
  interface Window {
    appStore: AppStore;
    __PRELOADED_STATE__: Nullable<AppPreloadedState>;
  }
}

type ColorsTheme = {
  action: string;
  primary: string;
  secondary: string;
  negative: string;

  base1: string;
  base2: string;
  base3: string;
  base4: string;
  base5: string;
  base6: string;
  base7: string;
  accent1: string;
  accent2: string;
  accent3: string;
  accent4: string;
  accent5: string;
  accent6: string;

  gradients: {
    iconBox: string;
  };

  buttons: {
    primary: string;
    secondary: string;
    link: string;
    orange: string;
    green: string;
    gold: string;
    twiLight: string;
  };

  socialButtons: {
    discord: string;
    google: string;
    twitch: string;
    facebook: string;
    steam: string;
    faceit: string;
  };
};

declare module '@emotion/react' {
  export interface Theme {
    colors: ColorsTheme;
  }
}
