import { css } from '@emotion/react';
import { ButtonColor, isDef, Nullable, TextBody2RRMixin } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Marketing } from '../../../../marketing/marketing';
import { AccountsButton } from '../../../../ui/buttons/accounts-button/accounts-button.component';
import { media } from '../../../../ui/global/media-query';
import { Modal } from '../../../../ui/modals/modal/modal.component';
import { ModalBody } from '../../../../ui/modals/modal/modal-body.component';
import { ThankYouModalOption } from './survey-modal.types';
import { surveyModalOptions } from './survey-modal.utils';
import { ThankYouModalRadio } from './thank-you-modal-radio/thank-you-modal-radio.component';

interface Props {
  onClose: () => void;
  onSubmit: () => void;
}

export const SurveyModal: React.FC<Props> = props => {
  const { onClose, onSubmit } = props;
  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = React.useState<Nullable<ThankYouModalOption>>(null);
  const [message, setMessage] = React.useState<string>('');
  React.useEffect(() => Marketing.thankYouModalDisplayed, []);
  React.useEffect(() => Marketing.thankYouModalClosed, []);

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setMessage(event.target.value);
  };

  const onSubmitHandler = (): void => {
    Marketing.thankYouModalClicked(selectedOption, message);
    onSubmit();
  };

  const submitIsDisabled = !isDef(selectedOption) && !message.length;

  return (
    <Modal onClose={onClose} css={modalCss}>
      <ModalBody>
        <p css={titleCss}>{t('ui-thank-you-modal-title')}</p>
        <p css={descriptionCss}>{t('ui-thank-you-modal-desc')}</p>

        <ul css={optionsListCss}>
          {surveyModalOptions.map(option => (
            <ThankYouModalRadio
              key={option}
              value={option}
              isActive={selectedOption === option}
              onChange={setSelectedOption}
            />
          ))}
        </ul>

        <textarea
          placeholder={t('ui-thank-you-modal-textarea')}
          value={message}
          maxLength={1000}
          onChange={onChangeHandler}
          css={textAreaCss}
        />

        <AccountsButton
          analyticsButtonName="Payment thank you survey"
          onClick={onSubmitHandler}
          disabled={submitIsDisabled}
          color={ButtonColor.Secondary}
        >
          {t('ui-thank-you-modal-submit')}
        </AccountsButton>
      </ModalBody>
    </Modal>
  );
};

const titleCss = css`
  display: block;
  margin: 0 0 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.15px;
  color: var(--general-white-100);
`;

const descriptionCss = css`
  display: block;
  margin: 0 0 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #b3b4d0;
`;

const optionsListCss = css`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 12px;
  margin-bottom: 16px;
  padding-left: 0;
  ${media.small} {
    grid-auto-flow: row;
    grid-template-columns: repeat(1, 1fr);
  }
`;

const textAreaCss = css`
  ${TextBody2RRMixin};
  color: var(--general-grey-100);
  background-color: var(--general-base-600);
  border: 1px solid var(--general-base-100);
  border-radius: 6px;
  height: 120px;
  padding: 12px;
  resize: none;
  width: 100%;
  max-width: 100%;
  margin-bottom: 24px;
  &::placeholder {
    color: var(--general-grey-100);
  }
`;

const modalCss = css`
  max-width: 600px;
`;
