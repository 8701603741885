import { useLocalStore } from 'mobx-react-lite';
import * as React from 'react';
import { AppStore } from './app-store';

const storeContext = React.createContext<AppStore | null>(null);

type AppStoreProviderProps = {
  children?: React.ReactNode;
  createAppStore: () => AppStore;
};

export const AppStoreProvider = (props: AppStoreProviderProps) => {
  const { children, createAppStore } = props;
  const store = useLocalStore(createAppStore);
  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useAppStore = (): AppStore => {
  const store = React.useContext(storeContext);
  if (!store) {
    throw new Error('Store provider is not set.');
  }
  return store;
};
