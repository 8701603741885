import i18next from 'i18next';
import { PlanT } from '../payment.types';
import { TreasuryPaymentsGame, TreasuryPaymentsProduct } from '../../../__generated/treasury/types';
import { getPlanDuration } from './get-plan-duration.utils';

export const getMixpanelPlanName = (plan: PlanT, game: TreasuryPaymentsGame, t: i18next.TFunction) => {
  const mixpanelGame = getTreasuryPaymentsGameAsString(game);
  const duration = getPlanDuration(plan.interval, plan.intervalCount, t);
  switch (plan.planType) {
    case TreasuryPaymentsProduct.PLUS: {
      return `${mixpanelGame} Plus ${plan.isTrial ? 'Trial' : duration} (Stripe)`;
    }
    case TreasuryPaymentsProduct.AD_FREE: {
      return `Ad-Free ${mixpanelGame}: ${duration} (Stripe)`;
    }
  }
};

const getTreasuryPaymentsGameAsString = (game: TreasuryPaymentsGame) => {
  switch (game) {
    case TreasuryPaymentsGame.DESTINY_2:
      return 'Destiny 2';
    case TreasuryPaymentsGame.DIABLO_4:
      return 'Diablo 4';
    case TreasuryPaymentsGame.LOL:
      return 'LoL';
    case TreasuryPaymentsGame.LOR:
      return 'LoR';
    case TreasuryPaymentsGame.LOST_ARK:
      return 'Lost Ark';
    case TreasuryPaymentsGame.TFT:
      return 'TFT';
    case TreasuryPaymentsGame.VALORANT:
      return 'Valorant';
    case TreasuryPaymentsGame.DEADLOCK:
      return 'Deadlock';
  }
};
