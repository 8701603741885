import { css } from '@emotion/react';
import { TextBody2RRMixin } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PlanT } from '../../../payment.types';
import { getPlanDuration } from '../../../utils/get-plan-duration.utils';
import { PriceWithDiscount } from './price-with-discount/price-with-discount.component';
import { Price } from './price/price.component';

interface Props {
  className?: string;
  plan: PlanT;
}

export const TotalPrice: React.FC<Props> = props => {
  const { className, plan } = props;
  const { t } = useTranslation();

  const totalPriceLabel = !plan.isTrial ? (
    <span css={durationCss}>
      {t('Total for')} {getPlanDuration(plan.interval, plan.intervalCount, t)}
    </span>
  ) : (
    <span css={durationCss}>{t('After the free trial, your subscription (monthly) will begin.')}</span>
  );

  const discount = plan.discount?.coupon || plan.discount?.prorate;

  const totalPrice = !!discount ? (
    <PriceWithDiscount
      oldPrice={plan.basePrice}
      newPrice={plan.finalPrice}
      currency={plan.currency}
      discount={discount}
    />
  ) : (
    <Price value={plan.finalPrice} currency={plan.currency} />
  );
  return (
    <div css={wrapperCss} className={className}>
      {totalPriceLabel}
      {totalPrice}
    </div>
  );
};

const wrapperCss = css`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 12px;
`;

const durationCss = css`
  ${TextBody2RRMixin};
  color: var(--general-grey-100);
`;
