import * as Types from '../../../../__generated/treasury/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaymentPageQueryVariables = Types.Exact<{
  plansInput: Types.TreasuryPaymentsCouponPlansFilter;
  allowedActionsInput: Types.TreasuryPaymentsCustomerActionsFilter;
  availableStepInput: Types.TreasuryFlowAvailableStepFilter;
  flowInfoInput: Types.TreasuryFlowInfoFilter;
}>;

export type PaymentPageQuery = {
  __typename: 'Query';
  treasury?: {
    __typename: 'TreasuryQuery';
    id: string;
    flow?: {
      __typename: 'TreasuryFlowQuery';
      availableStep?:
        | { __typename: 'TreasuryFlowAvailableStep'; id: string }
        | { __typename: 'TreasuryFlowGenericError' }
        | null;
      flowInfo?: {
        __typename: 'TreasuryFlowInfoGeneral';
        info?: Array<{ __typename: 'TreasuryFlowInfoData'; planID: string; couponID: string }> | null;
      } | null;
    } | null;
    payments?: {
      __typename: 'TreasuryPaymentsQuery';
      customer?:
        | {
            __typename: 'TreasuryPaymentsCustomer';
            id: string;
            allowedActions?: Array<
              | { __typename: 'TreasuryPaymentCustomerActionCancelSubscription' }
              | {
                  __typename: 'TreasuryPaymentCustomerActionChangeSubscription';
                  isAllowed: boolean;
                  product: Types.TreasuryPaymentsProduct;
                }
              | {
                  __typename: 'TreasuryPaymentCustomerActionCreateSubscription';
                  isAllowed: boolean;
                  product: Types.TreasuryPaymentsProduct;
                }
              | {
                  __typename: 'TreasuryPaymentCustomerActionRenewSubscription';
                  isAllowed: boolean;
                  product: Types.TreasuryPaymentsProduct;
                }
              | { __typename: 'TreasuryPaymentCustomerActionUseTrial' }
            > | null;
            billingInfo?:
              | {
                  __typename: 'TreasuryPaymentsBillingInfo';
                  paypal?: { __typename: 'TreasuryPaymentsPayPal'; email: string } | null;
                  stripe?: {
                    __typename: 'TreasuryPaymentsStripe';
                    card: { __typename: 'TreasuryPaymentsCard'; last4: string; name: string };
                    billing: {
                      __typename: 'TreasuryPaymentsBillingAddress';
                      country: string;
                      state: string;
                      city: string;
                      line1: string;
                      zip: string;
                    };
                  } | null;
                }
              | { __typename: 'TreasuryPaymentsUnknownError' }
              | null;
            subscriptions: Array<
              | {
                  __typename: 'TreasuryPaymentsSubscription';
                  id: string;
                  state: Types.TreasuryPaymentsSubscriptionState;
                  nextPaymentDate?: Types.Scalars['TreasuryPaymentsTime'] | null;
                  plan: {
                    __typename: 'TreasuryPaymentsPlan';
                    id: string;
                    interval: Types.TreasuryPaymentsPlanInterval;
                    intervalCount: number;
                    profit: string;
                    isBestChoice: boolean;
                    product: Types.TreasuryPaymentsProduct;
                    trial?:
                      | { __typename: 'TreasuryPaymentsTrial'; id: string; isAvailable: boolean }
                      | { __typename: 'TreasuryPaymentsUnknownError' }
                      | null;
                    basePrice: { __typename: 'TreasuryPaymentsMoney'; amount: number; currency: string };
                    finalPrice: { __typename: 'TreasuryPaymentsMoney'; amount: number; currency: string };
                    appliedCoupon?: {
                      __typename: 'TreasuryPaymentsCoupon';
                      name: string;
                      discount:
                        | {
                            __typename: 'TreasuryPaymentsCouponDiscountAmount';
                            amount: { __typename: 'TreasuryPaymentsMoney'; amount: number; currency: string };
                          }
                        | { __typename: 'TreasuryPaymentsCouponDiscountPercentage'; percentage: number };
                    } | null;
                    appliedProration?: { __typename: 'TreasuryPaymentsMoney'; amount: number; currency: string } | null;
                    viewOptions?: {
                      __typename: 'PlanViewOptions';
                      frame?: Types.PlanFrameOption | null;
                      extraFeatures?: Array<Types.PlanExtraFeature> | null;
                    } | null;
                  };
                }
              | { __typename: 'TreasuryPaymentsUnknownError' }
            >;
            trial?:
              | { __typename: 'TreasuryPaymentsCustomerTrial'; id: string }
              | { __typename: 'TreasuryPaymentsUnknownError' }
              | null;
          }
        | { __typename: 'TreasuryPaymentsUnauthenticatedError' }
        | { __typename: 'TreasuryPaymentsUnknownError' }
        | null;
      plansWithCoupon?: {
        __typename: 'TreasuryPaymentsApplyCouponPayload';
        errors?: Array<
          | { __typename: 'TreasuryPaymentsBadRequestError'; message?: string | null }
          | { __typename: 'TreasuryPaymentsPaymentError'; message: string }
          | { __typename: 'TreasuryPaymentsUnauthenticatedError'; message?: string | null }
          | { __typename: 'TreasuryPaymentsUnknownError'; message?: string | null }
        > | null;
        plans?: Array<{
          __typename: 'TreasuryPaymentsPlan';
          id: string;
          interval: Types.TreasuryPaymentsPlanInterval;
          intervalCount: number;
          profit: string;
          isBestChoice: boolean;
          product: Types.TreasuryPaymentsProduct;
          trial?:
            | { __typename: 'TreasuryPaymentsTrial'; id: string; isAvailable: boolean }
            | { __typename: 'TreasuryPaymentsUnknownError' }
            | null;
          basePrice: { __typename: 'TreasuryPaymentsMoney'; amount: number; currency: string };
          finalPrice: { __typename: 'TreasuryPaymentsMoney'; amount: number; currency: string };
          appliedCoupon?: {
            __typename: 'TreasuryPaymentsCoupon';
            name: string;
            discount:
              | {
                  __typename: 'TreasuryPaymentsCouponDiscountAmount';
                  amount: { __typename: 'TreasuryPaymentsMoney'; amount: number; currency: string };
                }
              | { __typename: 'TreasuryPaymentsCouponDiscountPercentage'; percentage: number };
          } | null;
          appliedProration?: { __typename: 'TreasuryPaymentsMoney'; amount: number; currency: string } | null;
          viewOptions?: {
            __typename: 'PlanViewOptions';
            frame?: Types.PlanFrameOption | null;
            extraFeatures?: Array<Types.PlanExtraFeature> | null;
          } | null;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export const PaymentPageQueryDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PaymentPageQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'plansInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryPaymentsCouponPlansFilter' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'allowedActionsInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryPaymentsCustomerActionsFilter' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'availableStepInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryFlowAvailableStepFilter' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'flowInfoInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryFlowInfoFilter' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'treasury' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flow' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availableStep' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filter' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'availableStepInput' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'TreasuryFlowAvailableStep' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flowInfo' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filter' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'flowInfoInput' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'info' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'planID' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'couponID' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'TreasuryPaymentsCustomer' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'allowedActions' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'filter' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'allowedActionsInput' },
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {
                                              kind: 'Name',
                                              value: 'TreasuryPaymentCustomerActionCreateSubscription',
                                            },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'isAllowed' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {
                                              kind: 'Name',
                                              value: 'TreasuryPaymentCustomerActionChangeSubscription',
                                            },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'isAllowed' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {
                                              kind: 'Name',
                                              value: 'TreasuryPaymentCustomerActionRenewSubscription',
                                            },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'isAllowed' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'billingInfo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'TreasuryPaymentsBillingInfo' },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'paypal' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: { kind: 'Name', value: 'PaypalBillingInfoFragment' },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'stripe' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: { kind: 'Name', value: 'StripeBillingInfoFragment' },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subscriptions' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'PaymentSubscriptionFragment' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'trial' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'TreasuryPaymentsCustomerTrial' },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plansWithCoupon' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filter' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'plansInput' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'errors' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'plans' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PlanFragment' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaypalBillingInfoFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryPaymentsPayPal' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StripeBillingInfoFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryPaymentsStripe' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'card' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'last4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'billing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'line1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentSubscriptionFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryPaymentsSubscription' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryPaymentsSubscription' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextPaymentDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PlanFragment' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PlanFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryPaymentsPlan' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'interval' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intervalCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBestChoice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trial' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryPaymentsTrial' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAvailable' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'basePrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finalPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appliedCoupon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'TreasuryPaymentsCouponDiscountAmount' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'TreasuryPaymentsCouponDiscountPercentage' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'percentage' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appliedProration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'frame' } },
                { kind: 'Field', name: { kind: 'Name', value: 'extraFeatures' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;

/**
 * __usePaymentPageQuery__
 *
 * To run a query within a React component, call `usePaymentPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentPageQuery({
 *   variables: {
 *      plansInput: // value for 'plansInput'
 *      allowedActionsInput: // value for 'allowedActionsInput'
 *      availableStepInput: // value for 'availableStepInput'
 *      flowInfoInput: // value for 'flowInfoInput'
 *   },
 * });
 */
export function usePaymentPageQuery(baseOptions: Apollo.QueryHookOptions<PaymentPageQuery, PaymentPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentPageQuery, PaymentPageQueryVariables>(PaymentPageQueryDocument, options);
}
export function usePaymentPageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentPageQuery, PaymentPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentPageQuery, PaymentPageQueryVariables>(PaymentPageQueryDocument, options);
}
export type PaymentPageQueryHookResult = ReturnType<typeof usePaymentPageQuery>;
export type PaymentPageQueryLazyQueryHookResult = ReturnType<typeof usePaymentPageQueryLazyQuery>;
export type PaymentPageQueryQueryResult = Apollo.QueryResult<PaymentPageQuery, PaymentPageQueryVariables>;
