import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useAppStore } from '../../../store/app-store.hook';
import { ConnectAccountInfo } from '../../../types/app.types';
import { FormFooter } from '../../../ui/layout/form-layout/form-footer.component';
import { FormLayout } from '../../../ui/layout/form-layout/form-layout.component';
import { formLinkButtonCss } from '../../../ui/layout/form-layout/form-link-button.component';
import { FormTitle } from '../../../ui/layout/form-layout/form-title.component';
import { genRequestPasswordResetPath } from '../../../utils/formatter/path.formatter';
import { getConnectInfoFromUrl } from '../../../utils/url';
import { MultipleEmailsForm } from './multiple-emails-form/multiple-emails-form.component';
import { SingleEmailForm } from './single-email-form/single-email-form.component';

interface ConnectMobaAccountFormProps {
  className?: string;
}

export const ConnectMobaAccountForm: React.FC<ConnectMobaAccountFormProps> = observer(
  (props: ConnectMobaAccountFormProps) => {
    const { className } = props;
    const { t } = useTranslation();
    const { inputStore } = useAppStore();
    const { reset } = inputStore;
    const { search } = useLocation();

    const [connectInfo] = React.useState<ConnectAccountInfo[] | null>(getConnectInfoFromUrl());
    React.useEffect(() => reset, [reset]);
    return (
      <FormLayout className={className}>
        {(!connectInfo || !connectInfo.length) && <FormTitle>{t('error-internal')}</FormTitle>}
        {connectInfo && connectInfo.length === 1 && <SingleEmailForm connectInfo={connectInfo[0]} />}
        {connectInfo && connectInfo.length > 1 && <MultipleEmailsForm connectInfo={connectInfo} />}
        <FormFooter>
          <Link to={genRequestPasswordResetPath(search)} css={formLinkButtonCss}>
            {t<string>('Forgot your password?')}
          </Link>
        </FormFooter>
      </FormLayout>
    );
  }
);
