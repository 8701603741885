// exported from: https://getbootstrap.com/docs/4.3/layout/overview/#responsive-breakpoints

export const MEDIA_BREAKPOINTS = {
  xsmall: '575.99px',
  small: '767.99px',
  medium: '991.99px',
  large: '1199.99px',
  xlarge: '1499.99px',
};

export const media = {
  // ---- common media (desktop first) --------------------
  xsmall: `@media (max-width: 575.99px)`,

  small: `@media (max-width: 767.99px)`,

  medium: `@media (max-width: 991.99px)`,

  large: `@media (max-width: 1199.99px)`,

  xlarge: `@media (max-width: 1499.99px)`,

  extraSmallOnly: `@media (max-width: 575.98px)`,

  smallOnly: `@media (min-width: 576px) and (max-width: 767.98px)`,

  mediumOnly: `@media (min-width: 768px) and (max-width: 991.98px)`,

  largeOnly: `@media (min-width: 992px) and (max-width: 1199.98px)`,

  extraLargeOnly: `@media (min-width: 1200px)`,

  lowHeight: `@media (max-height: 699.98px)`,
};
