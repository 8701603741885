import { Logger, NString } from '@mobalytics/shared';
import { useHistory, useLocation } from 'react-router';
import React from 'react';
import { TreasuryPaymentsGame, TreasuryPaymentsProvider } from '../../../../../__generated/treasury/types';
import { AllowedActionsT } from '../../../payment.types';
import { usePaymentFlow } from '../payment-flow.hook';
import {
  genAbsolutePaymentProcessingPath,
  genAbsolutePaymentThankYouPath,
  genPaymentErrorPath,
  genPaymentThankYouPath,
} from '../../../../../utils/formatter/path.formatter';
import { PaymentError } from '../../../payment-error.types';
import { StripeConfirmationInfo } from './stripe-flow.types';

type ParamsT = {
  planId: string;
  game: TreasuryPaymentsGame;
  coupon: NString;
  allowedActions: AllowedActionsT;
};

export const useStripeFlowConfirmationInfo = (params: ParamsT) => {
  const { planId, game, coupon, allowedActions } = params;
  const {
    createNewSubscription,
    changeCurrentSubscription,
    renewCurrentSubscription,
    prepareBillingInfo,
  } = usePaymentFlow();
  const { search } = useLocation();
  const { replace } = useHistory();
  const [loading, setIsLoading] = React.useState(false);

  const [confirmationInfo, setConfirmationInfo] = React.useState<StripeConfirmationInfo>();

  const fetchConfirmationInfo = React.useCallback(
    async (shouldPrepareBillingInfoFirst: boolean) => {
      setIsLoading(true);
      try {
        let response = null;
        let action: 'create' | 'change' | 'renew' | null = null;
        if (shouldPrepareBillingInfoFirst) {
          response = await prepareBillingInfo();
        } else if (allowedActions.change) {
          response = await changeCurrentSubscription(planId, game, coupon);
          action = 'change';
        } else if (allowedActions.create) {
          response = await createNewSubscription(planId, game, coupon, TreasuryPaymentsProvider.STRIPE);
          action = 'create';
        } else if (allowedActions.renew) {
          response = await renewCurrentSubscription(planId, game, coupon);
          action = 'renew';
        } else {
          Logger.error('[useConfirmationInfo]:[fetchConfirmationInfo]: no allowed actions');
          replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
          return;
        }
        if (!response) {
          Logger.error('[useConfirmationInfo]:[fetchConfirmationInfo]: response is missing');
          replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
          return;
        }
        if (response.error) {
          Logger.error('[useConfirmationInfo]:[fetchConfirmationInfo]: response error', response.error);
          replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
          return;
        }

        // handle case with filling payment method first
        if (response.__typename === 'TreasuryPaymentsPrepareUpdateBillingInfoPayload') {
          if (response.setupIntentSecret) {
            setConfirmationInfo({
              secret: response.setupIntentSecret,
              type: 'setup',
              returnUrl: genAbsolutePaymentProcessingPath(planId, search),
              selectedAction: action,
            });
          } else {
            Logger.error('[useConfirmationInfo]:[fetchConfirmationInfo]: trial setupSecret is missing');
            replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
          }
        } else {
          // handle case with immediate payment
          if (response.paymentIntentSecret) {
            setConfirmationInfo({
              secret: response?.paymentIntentSecret,
              type: 'payment',
              returnUrl: genAbsolutePaymentThankYouPath(planId, search),
              selectedAction: action,
            });
          } else {
            // at current point we imply that if mutation finished successfully and there is no secrets and no error
            // it means that we mutated subscription without user confirmation
            replace(genPaymentThankYouPath(planId, search));
          }
        }
      } catch (e) {
        Logger.error('[useConfirmationInfo]:[fetchConfirmationInfo]: unknown error', e);
        replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
      } finally {
        setIsLoading(false);
      }
    },
    [
      allowedActions.change,
      allowedActions.create,
      allowedActions.renew,
      changeCurrentSubscription,
      coupon,
      createNewSubscription,
      game,
      planId,
      prepareBillingInfo,
      renewCurrentSubscription,
      replace,
      search,
    ]
  );

  return {
    loading,
    confirmationInfo,
    fetchConfirmationInfo,
  };
};
