import { css } from '@emotion/react';
import { NString } from '@mobalytics/shared';
import * as React from 'react';
import { PlanCard } from '../../../../ui/plan-card/plan-card.component';
import { AllowedActionsT, PlanT } from '../../payment.types';

interface Props {
  className?: string;
  plans: PlanT[];
  currentPlanId: NString;
  onPlanClick: (plan: PlanT) => void;
  allowedActions: AllowedActionsT;
}
export const PlansList: React.FC<Props> = props => {
  const { className, plans, currentPlanId, onPlanClick, allowedActions } = props;
  return (
    <ul css={wrapperCss} className={className}>
      {plans?.map(plan => {
        return (
          <li key={plan.id}>
            <PlanCard
              plan={plan}
              isCurrentPlan={currentPlanId === plan.id}
              onClick={onPlanClick}
              allowedActions={allowedActions}
            />
          </li>
        );
      })}
    </ul>
  );
};

const wrapperCss = css`
  display: flex;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
  padding-left: 0;
  align-items: flex-end;
  li {
    list-style: none;
  }
`;
