import React from 'react';
import { Logger, NString } from '@mobalytics/shared';
import { useHistory, useLocation } from 'react-router';
import { usePaymentFlow } from '../../payment-flow.hook';
import { TreasuryPaymentsGame, TreasuryPaymentsProvider } from '../../../../../../__generated/treasury/types';
import { AllowedActionsT } from '../../../../payment.types';
import { genPaymentErrorPath, genPaymentThankYouPath } from '../../../../../../utils/formatter/path.formatter';
import { PaymentError } from '../../../../payment-error.types';

type Params = {
  planId: NString;
  game: TreasuryPaymentsGame;
  coupon: NString;
  allowedActions: AllowedActionsT;
};

export const usePaypalFlowExisting = (params: Params) => {
  const { planId, game, coupon, allowedActions } = params;
  const [loading, setIsLoading] = React.useState(false);
  const { search } = useLocation();
  const { replace } = useHistory();
  const { createNewSubscription, changeCurrentSubscription, renewCurrentSubscription } = usePaymentFlow();

  const processPayment = async () => {
    if (!planId) {
      Logger.error('[usePaypalFlowExisting]:[processPayment]: planId is missing');
      return;
    }
    try {
      setIsLoading(true);
      let response = null;
      if (allowedActions.change) {
        response = await changeCurrentSubscription(planId, game, coupon);
      } else if (allowedActions.create) {
        response = await createNewSubscription(planId, game, coupon, TreasuryPaymentsProvider.BRAINTREE);
      } else if (allowedActions.renew) {
        response = await renewCurrentSubscription(planId, game, coupon);
      } else {
        Logger.error('[usePaypalFlowExisting]:[processPayment]: no allowed actions');
        replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
        return;
      }
      if (!response) {
        Logger.error('[usePaypalFlowExisting]:[processPayment]: response is missing');
        replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
        return;
      }
      if (response.error) {
        Logger.error('[usePaypalFlowExisting]:[processPayment]: response error', response.error);
        replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
        return;
      }
      replace(genPaymentThankYouPath(planId, search));
    } catch (e) {
      Logger.error('[usePaypalFlowExisting]:[processPayment]: unknown error', e);
      replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
    } finally {
      setIsLoading(true);
    }
  };

  return {
    loading,
    processPayment,
  };
};
