import { NString, Nullable } from '@mobalytics/shared';
import i18next from 'i18next';
import { PlanFragment } from '../_gql/fragments/__generated/plan.fragment.gql.generated';
import { TreasuryPaymentsCouponDiscount, TreasuryPaymentsPlanInterval } from '../../../__generated/treasury/types';
import { PlanT } from '../payment.types';
import { notNullArray } from '../../../utils/not-null-array.utils';
import { getMoneyAsString } from './get-money-as-string.utils';
import { processBestChoice } from './process-best-choice.util';

export const processPlan = (plan: PlanFragment, t: i18next.TFunction): PlanT | null => {
  if (plan.interval && plan.intervalCount) {
    const basePrice = plan.basePrice?.amount || 0;
    const finalPrice = plan.finalPrice?.amount || 0;
    const currency = plan.basePrice?.currency || 'USD';
    const couponDiscount = processCouponDiscount(plan.appliedCoupon?.discount);
    const prorateDiscount = plan.appliedProration
      ? processProrateDiscount(plan.appliedProration?.currency, plan.appliedProration?.amount)
      : null;
    const basePricePerMonth = getPricePerMonth(plan.interval, plan.intervalCount, basePrice);
    const finalPricePerMonth = couponDiscount
      ? getPricePerMonth(plan.interval, plan.intervalCount, finalPrice)
      : basePricePerMonth;
    return {
      id: plan.id,
      interval: plan.interval,
      intervalCount: plan.intervalCount,
      finalPrice,
      basePrice,
      currency,
      basePricePerMonth,
      finalPricePerMonth,
      profit: plan.profit,
      bestChoiceLabel: processBestChoice(plan.isBestChoice, plan.viewOptions?.extraFeatures, t),
      isTrial: plan.trial?.__typename === 'TreasuryPaymentsTrial' && plan.trial.isAvailable,
      discount: { coupon: couponDiscount, prorate: prorateDiscount },
      planType: plan.product,
      appliedCoupon: plan.appliedCoupon?.name,
      planFeatures: notNullArray(plan.viewOptions?.extraFeatures),
    };
  }
  return null;
};

const processCouponDiscount = (discount: Nullable<TreasuryPaymentsCouponDiscount>): NString => {
  if (discount) {
    if (discount.__typename === 'TreasuryPaymentsCouponDiscountPercentage') {
      return `${discount.percentage.toFixed(0)}%`;
    } else if (discount.__typename === 'TreasuryPaymentsCouponDiscountAmount') {
      return getMoneyAsString(discount.amount.currency, discount.amount.amount);
    }
  }
  return null;
};

const processProrateDiscount = (currency: string, price: number): NString => {
  return getMoneyAsString(currency, price);
};

const getPricePerMonth = (interval: TreasuryPaymentsPlanInterval, intervalCount: number, price: number): number => {
  if (interval === TreasuryPaymentsPlanInterval.YEARLY) {
    return Math.floor(price / (12 * (intervalCount || 1)));
  }
  return Math.floor(price / (intervalCount || 1));
};
