import * as Types from '../../../../__generated/treasury/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeSubscriptionMutationVariables = Types.Exact<{
  planId: Types.Scalars['ID'];
  game: Types.TreasuryPaymentsGame;
  couponName?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type ChangeSubscriptionMutation = {
  __typename: 'Mutation';
  treasury?: {
    __typename: 'TreasuryMutation';
    id: string;
    payments?: {
      __typename: 'TreasuryPaymentsMutation';
      changeSubscription: {
        __typename: 'TreasuryPaymentsChangeSubscriptionPayload';
        paymentIntentSecret?: string | null;
        setupIntentSecret?: string | null;
        error?:
          | {
              __typename: 'TreasuryPaymentsBadRequestError';
              parameter?: string | null;
              message?: string | null;
              details?: string | null;
            }
          | {
              __typename: 'TreasuryPaymentsPaymentError';
              code: Types.TreasuryPaymentsPaymentErrorCode;
              message: string;
            }
          | { __typename: 'TreasuryPaymentsUnauthenticatedError'; message?: string | null }
          | { __typename: 'TreasuryPaymentsUnknownError'; message?: string | null }
          | null;
        subscription?: {
          __typename: 'TreasuryPaymentsSubscription';
          id: string;
          state: Types.TreasuryPaymentsSubscriptionState;
        } | null;
      };
    } | null;
  } | null;
};

export const ChangeSubscriptionMutationDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeSubscriptionMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'game' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryPaymentsGame' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'couponName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'treasury' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'changeSubscription' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'input' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'planId' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'game' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'game' } },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'couponName' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'couponName' } },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'error' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PaymentErrorFragment' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subscription' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'paymentIntentSecret' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'setupIntentSecret' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentErrorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryPaymentsError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryPaymentsBadRequestError' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'parameter' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'details' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryPaymentsPaymentError' } },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'code' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export type ChangeSubscriptionMutationMutationFn = Apollo.MutationFunction<
  ChangeSubscriptionMutation,
  ChangeSubscriptionMutationVariables
>;

/**
 * __useChangeSubscriptionMutation__
 *
 * To run a mutation, you first call `useChangeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSubscriptionMutation, { data, loading, error }] = useChangeSubscriptionMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      game: // value for 'game'
 *      couponName: // value for 'couponName'
 *   },
 * });
 */
export function useChangeSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeSubscriptionMutation, ChangeSubscriptionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeSubscriptionMutation, ChangeSubscriptionMutationVariables>(
    ChangeSubscriptionMutationDocument,
    options
  );
}
export type ChangeSubscriptionMutationHookResult = ReturnType<typeof useChangeSubscriptionMutation>;
export type ChangeSubscriptionMutationMutationResult = Apollo.MutationResult<ChangeSubscriptionMutation>;
export type ChangeSubscriptionMutationMutationOptions = Apollo.BaseMutationOptions<
  ChangeSubscriptionMutation,
  ChangeSubscriptionMutationVariables
>;
