import * as React from 'react';

export const Helmet: React.FC<React.BaseHTMLAttributes<SVGElement>> = props => {
  return (
    <svg width="16px" height="21px" viewBox="0 0 16 21" {...props}>
      <g fill="#AAABCA" fillRule="nonzero" stroke="none" strokeWidth={1}>
        <path
          d="M9 20.928v-6.39L9.606 13H11l2-3H9l-2 3-2-3H1l2 3h1.394L5 14.538v6.39A16.46 16.46 0 014 20l-2-1v-1l-2-3V9a7.003 7.003 0 014.937-6.691A6.999 6.999 0 017 2a7.003 7.003 0 017 7v6l-2 3v1l-2 1c-.357.357-.69.667-1 .928z"
          transform="translate(-657 -377) translate(632 262) translate(10 108) translate(16 7)"
        />
        <path
          d="M7 11.45L4.792 8.14l-.035-.2L7-1.03l2.243 8.97-.035.2L7 11.45zM9.75 9.347V7l.073-.177 4.584-4.584-1.19 5.952L9.75 9.347zM4.25 9.347V7l-.073-.177L-.407 2.24l1.19 5.952L4.25 9.347z"
          stroke="#FFF"
          strokeWidth={0.5}
          transform="translate(-657 -377) translate(632 262) translate(10 108) translate(16 7)"
        />
      </g>
    </svg>
  );
};
