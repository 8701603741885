import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ErrorMessage,
  ErrorSignStyled,
  ErrorTooltip,
  IconContainer,
  IconWrapper,
  InputElement,
  Wrapper,
} from './input.styles';

export interface InputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  placeholder?: string;
  className?: string;
  isDisabled?: boolean;
  icon?: React.ReactNode;
  error?: string | null;
}

/**
 * @deprecated use moba-shared/input instead
 */
export const Input: React.FC<InputProps> = function(props: InputProps) {
  const { t } = useTranslation();
  const { placeholder, className, isDisabled, icon, error, ...rest } = props;
  return (
    <Wrapper className={className} error={error} isDisabled={isDisabled}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <InputElement
        placeholder={placeholder ? t(placeholder) : undefined}
        aria-label={placeholder ? t(placeholder) : ''}
        {...rest}
      />
      {error && (
        <IconContainer>
          <ErrorSignStyled />
        </IconContainer>
      )}
      {error && (
        <ErrorTooltip>
          <ErrorMessage>{t(error)}</ErrorMessage>
        </ErrorTooltip>
      )}
    </Wrapper>
  );
};
