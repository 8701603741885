import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Switch } from 'react-router';
import { Route } from './components/route/route';
import { Sidebar } from './components/sidebar/sidebar.component';
import { Payment } from './pages/payment/payment.component';
import { useAppStore } from './store/app-store.hook';
import { AccountsAppPath, AppThemeConfig } from './types/app.types';
import { AppLayout } from './ui/layout/app-layout/app-layout.component';
import { PageLayout } from './ui/layout/page-layout/page-layout.component';

interface AppRouterProps {
  config: AppThemeConfig;
  Error: React.FC;
  SignIn: React.FC;
  SignUp: React.FC;
  LolSetSummoner: React.FC | null;
  DesktopSignIn: React.FC;
  SignUpDetails: React.FC;
  PasswordReset: React.FC;
  DesktopSignOut: React.FC;
  ConnectMobaAccount: React.FC;
  PasswordResetSuccess: React.FC;
  RequestPasswordReset: React.FC;
  DesktopSignInRedirect: React.FC;
  DesktopSignInComplete: React.FC;
  RequestPasswordResetSuccess: React.FC;
}

export const AppRouter: React.FC<AppRouterProps> = observer((props: AppRouterProps) => {
  const { config } = props;
  const { SignIn, SignUp, LolSetSummoner } = props;
  const { SignUpDetails, ConnectMobaAccount, Error } = props;
  const { PasswordReset, PasswordResetSuccess } = props;
  const { RequestPasswordReset, RequestPasswordResetSuccess } = props;
  const { DesktopSignIn, DesktopSignInRedirect, DesktopSignInComplete, DesktopSignOut } = props;
  const { isInitialLangLoaded, configStore } = useAppStore();
  const { setConfig } = configStore;

  React.useEffect(() => setConfig(config), [config, setConfig]);

  return isInitialLangLoaded ? (
    <AppLayout>
      <Switch>
        <Route exact={false} path={AccountsAppPath.PAYMENT} component={Payment} />
        <PageLayout wallpaper={config.wallpaper} sideContent={<Sidebar />}>
          <Route exact={true} path={AccountsAppPath.SIGN_IN} component={SignIn} />
          <Route exact={true} path={AccountsAppPath.SIGN_UP} component={SignUp} />
          <Route exact={true} path={AccountsAppPath.SIGN_UP_DETAILS} component={SignUpDetails} />
          <Route exact={true} path={AccountsAppPath.REQUEST_PASSWORD_RESET} component={RequestPasswordReset} />
          <Route
            exact={true}
            path={AccountsAppPath.REQUEST_PASSWORD_RESET_SUCCESS}
            component={RequestPasswordResetSuccess}
          />
          <Route exact={true} path={AccountsAppPath.PASSWORD_RESET} component={PasswordReset} />
          <Route exact={true} path={AccountsAppPath.PASSWORD_RESET_SUCCESS} component={PasswordResetSuccess} />
          <Route exact={true} path={AccountsAppPath.DESKTOP_SIGN_IN} component={DesktopSignIn} />
          <Route exact={true} path={AccountsAppPath.DESKTOP_SIGN_IN_REDIRECT} component={DesktopSignInRedirect} />
          <Route exact={true} path={AccountsAppPath.DESKTOP_SIGN_IN_COMPLETE} component={DesktopSignInComplete} />
          <Route exact={true} path={AccountsAppPath.DESKTOP_SIGN_OUT} component={DesktopSignOut} />
          <Route exact={true} path={AccountsAppPath.LOL_SUMMONER_NAME} component={LolSetSummoner || undefined} />
          <Route exact={true} path={AccountsAppPath.CONNECT_MOBA_ACCOUNT} component={ConnectMobaAccount} />

          <Route exact={true} path={AccountsAppPath.ERROR} component={Error} />
        </PageLayout>
      </Switch>
    </AppLayout>
  ) : null;
});
