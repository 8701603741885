import { ButtonSize, Loader, Nullable } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AccountsButton } from '../../../ui/buttons/accounts-button/accounts-button.component';
import { TreasuryPaymentsProduct } from '../../../__generated/treasury/types';
import { MascotLayout } from '../../../ui/layout/mascot-layout/mascot-layout.component';
import { SurveyModal } from './survey-modal/survey-modal.component';
import { useThankYou } from './thank-you.hook';

interface Props {
  className?: string;
  planType: Nullable<TreasuryPaymentsProduct>;
}

export const ThankYou: React.FC<Props> = props => {
  const { className, planType } = props;
  const { loading, isSurveyOpen, wasSurveyClosed, checkStatus, onButtonClick, backToApp } = useThankYou();
  const { t } = useTranslation();

  React.useEffect(() => {
    checkStatus();
  }, [checkStatus]);

  if (loading) {
    return (
      <MascotLayout
        mascotEmotion={'calm'}
        title={t('Confirm payment...')}
        description={t(
          "Please wait for a moment while we're confirming your payment. This should take just a few moments."
        )}
        className={className}
      >
        <Loader />
      </MascotLayout>
    );
  }
  const description =
    planType === TreasuryPaymentsProduct.PLUS ? (
      <>
        <p>{t('You are now a Plus member!')}</p>
        <p>{t('Time to unlock your potential with more OP features and tools.')}</p>
      </>
    ) : (
      t(
        "You've officially destroyed all ads! Your support empowers us to continue creating tools to help gamers like you."
      )
    );
  return (
    <MascotLayout mascotEmotion={'happy'} title={t('congratulations!')} description={description} className={className}>
      {!wasSurveyClosed && (
        <AccountsButton
          analyticsButtonName="Start climbing"
          onClick={() => onButtonClick(planType)}
          size={ButtonSize.H40}
        >
          {planType === TreasuryPaymentsProduct.PLUS && t('start climbing')}
          {planType === TreasuryPaymentsProduct.AD_FREE && t('back to mobalytics')}
        </AccountsButton>
      )}

      {isSurveyOpen && <SurveyModal onClose={backToApp} onSubmit={backToApp} />}
    </MascotLayout>
  );
};
