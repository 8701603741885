import { Nullable } from '@mobalytics/shared';
import { TreasuryPaymentsGame } from '../../__generated/treasury/types';
import { Theme } from '../../types/theme';

export function mapThemeToTreasuryGame(theme: Nullable<Theme>): TreasuryPaymentsGame | null {
  switch (theme) {
    case Theme.Bacon:
    case Theme.Lor:
      return TreasuryPaymentsGame.LOR;
    case Theme.Lol:
      return TreasuryPaymentsGame.LOL;
    case Theme.Tft:
      return TreasuryPaymentsGame.TFT;
    case Theme.Valorant:
      return TreasuryPaymentsGame.VALORANT;
    case Theme.LostArk:
      return TreasuryPaymentsGame.LOST_ARK;
    case Theme.Destiny2:
      return TreasuryPaymentsGame.DESTINY_2;
    case Theme.Diablo4:
      return TreasuryPaymentsGame.DIABLO_4;
    case Theme.Deadlock:
      return TreasuryPaymentsGame.DEADLOCK;
  }
  return null;
}
