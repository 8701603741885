import { gql } from '@apollo/client';

export const AccountFragment = gql`
  fragment AccountFragment on Account {
    uid
    email
    login
    loginHash
    level
  }
`;
