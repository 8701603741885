import styled from '@emotion/styled';
import { Region, RichText } from '@mobalytics/shared';
import { shade } from 'polished';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CDN_IMAGES } from '../../../../constants';
import { ButtonLabel } from '../../../../ui/buttons/base-button';
import { SecondaryButton } from '../../../../ui/buttons/secondary-button';
import { InfoIcon } from '../../../../ui/icons/common/info';
import { ProSummonerCard } from './pro-summoner-card';

interface Props {
  summonerName: string;
  region: Region;
  onProSummonerClick: (summonerName: string, region: Region) => void;
  onTryAnotherSummoner: () => void;
}

export const SummonerNotFound: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { summonerName, region, onProSummonerClick, onTryAnotherSummoner } = props;

  return (
    <Wrapper>
      <Title markdownText={t('summoner-does-not-exist-title', { summonerName, region })} />
      <SubTitle>
        {t('summoner-not-found-description')} {t('ui-china-or-garena-regions')}
        <TooltipWrapper>
          <InfoIcon />
          <Tooltip>{t('ui-china-or-garena-regions-tooltip')}</Tooltip>
        </TooltipWrapper>
      </SubTitle>

      <Row>
        <ProSummonerCard
          summonerName={'Doublelift#na1'}
          region={Region.NA}
          onProSummonerClick={onProSummonerClick}
          imageSrc={`${CDN_IMAGES}/pro-players/doublelift.png`}
          aka={'aka ‘Doublelift‘'}
        />
        <ProSummonerCard
          summonerName={'Hide on bush#KR1'}
          region={Region.KR}
          onProSummonerClick={onProSummonerClick}
          imageSrc={`${CDN_IMAGES}/pro-players/faker.png`}
          aka={'aka ‘Faker’'}
        />
        <ProSummonerCard
          summonerName={'Caps#45555'}
          region={Region.EUW}
          onProSummonerClick={onProSummonerClick}
          imageSrc={`${CDN_IMAGES}/pro-players/caps.png`}
          aka={'aka ‘Caps’'}
        />
      </Row>

      <SubTitle>{t('summoner-not-found-action-label')}</SubTitle>
      <SecondaryButtonStyled onClick={onTryAnotherSummoner}>
        <ButtonLabel>{t('ui-try-another-summoner')}</ButtonLabel>
      </SecondaryButtonStyled>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  background: ${({ theme }) => theme.colors.base1};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(RichText)`
  max-width: 680px;
  font-size: 36px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};

  margin-bottom: 28px;

  span {
    color: ${({ theme }) => theme.colors.accent6};
  }
`;

export const SubTitle = styled.div`
  max-width: 680px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.15px;
  text-align: center;
  margin-bottom: 48px;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const TooltipWrapper = styled.span`
  position: relative;
  cursor: help;

  svg {
    display: inline-block;
    margin-bottom: -2px;
    margin-left: 5px;
  }
`;

export const Row = styled.div`
  width: 900px;
  display: flex;
  margin-bottom: 48px;
  justify-content: space-between;
`;

export const SecondaryButtonStyled = styled(SecondaryButton)`
  width: 280px;
  height: 48px;
  border-radius: 3px;
  font-size: 14px;
`;

export const Tooltip = styled.div`
  font-size: 14px;
  font-weight: 400;
  width: 220px;
  padding: 12px;
  box-shadow: 0 6px 20px 4px rgba(26, 18, 62, 0.8);
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.secondary};
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -8px);

  border: solid 1px ${({ theme }) => shade(0.3, theme.colors.base2)};
  background-color: ${({ theme }) => shade(0.1, theme.colors.base1)};

  visibility: hidden;
  opacity: 0;

  transition: opacity ease 0.2s;

  ${TooltipWrapper}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;
