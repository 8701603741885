import i18next from 'i18next';
import { Nullable } from '@mobalytics/shared';
import { PlanExtraFeature } from '../../../__generated/treasury/types';

export const processBestChoice = (
  isBestChoice: boolean,
  extraFeatures: Nullable<(PlanExtraFeature | null)[]>,
  t: i18next.TFunction
): string | null => {
  if (extraFeatures?.includes(PlanExtraFeature.EGG_SKIN)) {
    return t('Limited Time: Free Egg', { nsSeparator: '#' });
  }
  if (extraFeatures?.includes(PlanExtraFeature.SKIN_CODE)) {
    return t('Limited Time: Free Skin', { nsSeparator: '#' });
  }
  if (isBestChoice) {
    return t('best choice');
  }
  return null;
};
