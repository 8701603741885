import styled from '@emotion/styled';
import { shade } from 'polished';
import { BaseButton } from '../base-button';

/**
 * @deprecated
 */
export const Button = styled(BaseButton)`
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.buttons.secondary};
  padding: 8px 12px;
  border-radius: 3px;
  min-width: 120px;
  &:hover {
    background-color: ${({ theme }) => shade(0.25, theme.colors.buttons.secondary)};
  }
  &:active {
    background-color: ${({ theme }) => shade(0.5, theme.colors.buttons.secondary)};
  }
  transition: background-color 0.2s ease-in;
`;
