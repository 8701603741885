import styled from '@emotion/styled';
import { shade } from 'polished';
import { BaseButton } from '../base-button';

/**
 * @deprecated
 */
export const Button = styled(BaseButton)<{ disabled?: boolean }>`
  color: ${({ theme }) => theme.colors.primary};
  padding: 8px 12px;
  border-radius: 3px;
  min-width: 120px;
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.base3 : theme.colors.buttons.primary)};
  opacity: ${({ disabled }) => disabled && 0.4};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  &:hover {
    background-color: ${({ theme }) => shade(0.25, theme.colors.buttons.primary)};
  }
  &:active {
    background-color: ${({ theme }) => shade(0.5, theme.colors.buttons.primary)};
  }
`;
