import * as queryString from 'query-string';
import { generatePath } from 'react-router';
import { FALLBACK_REDIRECT_URL } from '../../config';
import { AccountsAppPath } from '../../types/app.types';
import { PaymentSection } from '../../types/payment.types';
import { parseAppQueryParams } from '../url';
import { PaymentError } from '../../pages/payment/payment-error.types';

export const genPaymentPlanSelectionPath = (search: string) => {
  return `${generatePath(AccountsAppPath.PAYMENT, { section: PaymentSection.PLAN_SELECTION })}${search}`;
};

export const genPaymentBillingPath = (planId: string, search: string) => {
  return `${generatePath(AccountsAppPath.PAYMENT, { planId, section: PaymentSection.PAYMENT_INFO })}${search}`;
};

export const genPaymentThankYouPath = (planId: string, search: string) => {
  return `${generatePath(AccountsAppPath.PAYMENT, { planId, section: PaymentSection.THANK_YOU })}${search}`;
};
export const genAbsolutePaymentThankYouPath = (planId: string, search: string) => {
  return `${window.location.origin}${generatePath(AccountsAppPath.PAYMENT, {
    planId,
    section: PaymentSection.THANK_YOU,
  })}${search}`;
};

export const genAbsolutePaymentProcessingPath = (planId: string, search: string) => {
  return `${window.location.origin}${generatePath(AccountsAppPath.PAYMENT, {
    planId,
    section: PaymentSection.PAYMENT_PROCESSING,
  })}${search}`;
};

export const genPaymentErrorPath = (search: string, errorCode: PaymentError) => {
  const queryParams = queryString.parse(search, { decode: true });
  return `${generatePath(AccountsAppPath.PAYMENT, {
    section: PaymentSection.ERROR,
  })}?${queryString.stringify(
    {
      ...queryParams,
      error_code: errorCode,
    },
    {
      encode: true,
    }
  )}`;
};

export const genSignInPath = (search: string) => {
  return `${generatePath(AccountsAppPath.SIGN_IN)}${search}`;
};

export const genSignUpPath = (search: string) => {
  return `${generatePath(AccountsAppPath.SIGN_UP)}${search}`;
};

export const genRequestPasswordResetPath = (search: string) => {
  return `${generatePath(AccountsAppPath.REQUEST_PASSWORD_RESET)}${search}`;
};

export const genSupportUrl = () => {
  return `https://support.mobalytics.gg`;
};

export const genRedirectUrl = (): string => {
  const { redirect_uri, theme } = parseAppQueryParams();
  if (redirect_uri) {
    return redirect_uri;
  }
  return `${FALLBACK_REDIRECT_URL}/${theme}`;
};
