import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import * as React from 'react';
import { initReactI18next } from 'react-i18next';
import { NString } from '@mobalytics/shared';
import { DEFAULT_LANGUAGE, LANGUAGES_LOAD_PATH, SUPPORTED_LANGUAGES } from '../constants';
const reactStringReplace = require('react-string-replace');

// TODO Serj list need to be actualized
export enum SupportedLanguage {
  AR_AR = 'ar_ar',
  CS_CZ = 'cs_cz',
  DE_DE = 'de_de',
  EN_US = 'en_us',
  ES_ES = 'es_es',
  FR_FR = 'fr_fr',
  IT_IT = 'it_it',
  HU_HU = 'hu_hu',
  EL_GR = 'el_gr',
  JA_JP = 'ja_jp',
  KO_KR = 'ko_kr',
  PL_PL = 'pl_pl',
  PT_BR = 'pt_br',
  RO_RO = 'ro_ro',
  RU_RU = 'ru_ru',
  TR_TR = 'tr_tr',
  ZH_CHS = 'zh_chs',

  // 'ar',
  // 'cs',
  // 'da',
  // 'de',
  // 'el',
  // 'en',
  // 'es-ES',
  // 'fi',
  // 'fr',
  // 'hr',
  // 'it',
  // 'ja',
  // 'ko',
  // 'lt',
  // 'lv',
  // 'nl',
  // 'no',
  // 'pl',
  // 'pt-PT',
  // 'ru',
  // 'sk',
  // 'sl',
  // 'sr-CS',
  // 'sv-SE',
  // 'uk',
  // 'zh-CN',
  // 'zh-TW',
}

export function initI18n(lang?: NString) {
  const lng = lang && SUPPORTED_LANGUAGES.indexOf(lang) ? lang : DEFAULT_LANGUAGE;
  i18n
    .use(initReactI18next)
    .use(Backend)
    .init({
      lng,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: LANGUAGES_LOAD_PATH,
      },
    });
  return i18n;
}
const trRegExp = /{{\s*([a-zA-Z0-9$_-]+)\s*}}/gi;
type ComponentTranslationParams = { [key: string]: string | number | React.ReactNode };

export function applyComponentTranslationParams(tr: string, params: ComponentTranslationParams): React.ReactFragment {
  return reactStringReplace(tr, trRegExp, (match: string, index: number) => {
    const value = params[match];
    if (value) {
      return React.isValidElement(value) ? React.cloneElement(value, { key: `${match}-${index}` }) : value;
    }
    return match;
  });
}
