import i18next from 'i18next';
import { TreasuryPaymentsPlanInterval } from '../../../__generated/treasury/types';
import { getPlanDuration } from './get-plan-duration.utils';

export const getIntervalName = (
  interval: TreasuryPaymentsPlanInterval,
  intervalCount: number,
  t: i18next.TFunction
) => {
  if (
    interval === TreasuryPaymentsPlanInterval.YEARLY ||
    (interval === TreasuryPaymentsPlanInterval.MONTHLY && intervalCount === 12)
  ) {
    return t(`annually`);
  }
  if (interval === TreasuryPaymentsPlanInterval.DAILY) {
    return t(`daily`);
  }
  if (interval === TreasuryPaymentsPlanInterval.MONTHLY && intervalCount === 1) {
    return t(`monthly`);
  }
  if (interval === TreasuryPaymentsPlanInterval.MONTHLY && intervalCount === 3) {
    return t(`quarterly`);
  }
  return t(`per ${getPlanDuration(interval, intervalCount, t)}`);
};
