import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Region } from '@mobalytics/shared';
import { getRegionOptions } from '../../../utils/region.utils';
import { RegionIcon } from '../../icons/regions';
import { Modal } from '../modal/modal.component';
import { ModalBody } from '../modal/modal-body.component';
import { formatRegionName } from './rerion-modal.util';

interface Props {
  selectedRegion: Region;
  changeRegion: (region: Region) => void;
  onClose: () => void;
}

export const RegionModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { selectedRegion, changeRegion, onClose } = props;

  const onRegionClick = React.useCallback(
    (region: Region) => {
      changeRegion && changeRegion(region);
      onClose && onClose();
    },
    [changeRegion, onClose]
  );

  return (
    <Modal onClose={onClose} css={modalCss}>
      <ModalBody css={modalBodyCss}>
        <div css={titleCss}>{t('ui-regions')}</div>
        <div css={regionsContainerCss}>
          {getRegionOptions().map(region => (
            <div
              css={regionWrapperCss}
              key={region}
              className={selectedRegion === region ? 'active' : ''}
              onClick={onRegionClick.bind(null, region)}
            >
              <RegionIcon region={region} css={[regionIconCss, selectedRegion === region && regionIconActiveCss]} />
              <span css={[regionNameCss, selectedRegion === region && regionNameActiveCss]}>
                {formatRegionName(t, region)}
              </span>
            </div>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

const modalBodyCss = css`
  padding: 20px 24px;
`;

const titleCss = css`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  width: 100%;
  text-align: center;
  margin-bottom: 26px;
  color: #fff;
`;

const regionsContainerCss = css`
  display: grid;
  grid-template-rows: 24px;
  grid-template-columns: 132px 132px 132px 132px;
  grid-row-gap: 16px;
  grid-column-gap: 20px;
  max-width: 800px;
  justify-content: center;
`;

const regionIconCss = css`
  height: 23px;
  width: 23px;
  border-radius: 50%;
  margin-right: 12px;
`;

const regionIconActiveCss = css`
  border: solid 1px rgba(21, 169, 139, 0.6);
  box-shadow: none;
`;

const regionNameCss = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: #aaabca;
`;

const regionNameActiveCss = css`
  color: #fff;
`;

const regionWrapperCss = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &:hover svg {
    box-shadow: 0 0 8px 0 rgba(204, 173, 112, 0.4);
  }

  &:hover span {
    color: #ccad70;
  }
`;

const modalCss = css`
  max-width: 628px;
`;
