import { gql } from '@apollo/client';

export const SignUpQuery = gql`
  mutation SignUp(
    $name: String!
    $email: String!
    $password: String!
    $continueFrom: String
    $marketing: SingUpMarketingParams
    $mobaReferrerId: String
  ) {
    signUp(
      name: $name
      email: $email
      password: $password
      continueFrom: $continueFrom
      marketing: $marketing
      referrerId: $mobaReferrerId
    )
  }
`;
