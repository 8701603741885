import { firstItem, NString, Nullable, processUnionEntry } from '@mobalytics/shared';
import { useTranslation } from 'react-i18next';
import { treasuryApolloClient } from '../../../treasury-apollo-client';
import { usePaymentPageQuery } from '../_gql/__generated/payment-page.query.gql.generated';
import { getTrialOneTimeOfferByGame } from '../utils/get-trial-one-time-offer-by-game';
import { AvailableStepT, BillingInfoT, CustomerT, TrialPlanT } from '../payment.types';
import { PlanFragment } from '../_gql/fragments/__generated/plan.fragment.gql.generated';
import { getCurrentSubscription } from '../utils/get-current-subscription.utils';
import { processPlansInfo } from '../utils/process-plans-info.utils';
import { processAllowedActions } from '../utils/process-allowed-actions.utils';
import { TreasuryFlowInfoData, TreasuryPaymentsGame } from '../../../__generated/treasury/types';

type ParamsT = {
  game: Nullable<TreasuryPaymentsGame>;
  coupon: NString;
  planId: NString;
};

export const usePaymentPageData = (params: ParamsT) => {
  const { t } = useTranslation();
  const { game, coupon, planId } = params;
  const flowId = getTrialOneTimeOfferByGame(game);

  const { data: currentData, previousData, loading, refetch } = usePaymentPageQuery({
    client: treasuryApolloClient,
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    variables: {
      plansInput: {
        game: game!,
        couponName: coupon || '',
      },
      allowedActionsInput: {
        game: game!,
      },
      availableStepInput: { flowID: flowId },
      flowInfoInput: { flowID: flowId, game: game! },
    },
    skip: !game,
  });

  const data = currentData || previousData;

  // process customer
  const customer = processUnionEntry<CustomerT>(data?.treasury?.payments?.customer, 'TreasuryPaymentsCustomer');

  // process plans
  const { plans, allPlans, plansWithDiscount } = processPlansInfo(data?.treasury?.payments?.plansWithCoupon?.plans, t);
  const customerTrialPlan = processUnionEntry<TrialPlanT>(customer?.trial, 'TreasuryPaymentsCustomerTrial');
  const trialPlan = !customerTrialPlan?.id ? allPlans?.find(plan => plan?.isTrial) || null : null;
  const subscription = getCurrentSubscription(customer?.subscriptions);
  const currentPlanId = processUnionEntry<PlanFragment>(subscription?.plan, 'TreasuryPaymentsPlan')?.id;
  const selectedPlan = allPlans?.find(it => it.id === planId);

  //process available actions
  const allowedActions = processAllowedActions(customer);

  //process billing info
  const billingInfo = processUnionEntry<BillingInfoT>(customer?.billingInfo, 'TreasuryPaymentsBillingInfo');
  const paypal = billingInfo?.paypal;
  const stripe = billingInfo?.stripe;

  const flowInfo: Nullable<TreasuryFlowInfoData> = firstItem(data?.treasury?.flow?.flowInfo?.info);
  const flowStep: Nullable<AvailableStepT> = processUnionEntry<AvailableStepT>(
    data?.treasury?.flow?.availableStep,
    'TreasuryFlowAvailableStep'
  );

  //handlers
  const refetchWithCoupon = (coupon: string, game: TreasuryPaymentsGame) => {
    return refetch({
      plansInput: {
        couponName: coupon,
        game,
      },
    });
  };

  return {
    loading,
    data,
    refetchWithCoupon,

    allPlans,
    plans,
    plansWithDiscount,
    trialPlan,
    currentPlanId,
    selectedPlan,

    allowedActions,

    billingInfo: {
      paypal,
      stripe,
    },

    flowInfo,
    flowStep,
  };
};
