import { isObservableProp } from 'mobx';
import { isDef } from '@mobalytics/shared';
import { SerializableStore } from '../store/serializable-store';

export function resetObservable(store: any) {
  if (store) {
    for (const key in store) {
      const storeField = store[key];
      if (storeField instanceof SerializableStore && storeField.isResetable) {
        storeField.reset();
      } else if (isObservableProp(store, key)) {
        store[key] = null;
      }
    }
  }
}

/** Deserialize from object to store */
export function deserializeObservable(store: any, obj: any) {
  if (store && obj) {
    for (const key in store) {
      const storeField = store[key];
      if (storeField instanceof SerializableStore) {
        storeField.isDeserializable && storeField.deserialize(obj[key]);
      } else if (isObservableProp(store, key)) {
        store[key] = obj[key];
      }
    }
  }
}

/** Serialize from store to object */
export function serializeObservable(store: any) {
  if (store) {
    const result = {} as any;
    for (const key in store) {
      const storeField = store[key];
      if (storeField instanceof SerializableStore) {
        storeField.isSerializable && (result[key] = storeField.serialize());
      } else if (isObservableProp(store, key)) {
        const value = store[key];
        if (isDef(value)) {
          result[key] = value;
        }
      }
    }
    return Object.keys(result).length ? result : null;
  }
  return null;
}
