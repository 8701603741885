import { TFunction } from 'i18next';
import { PaymentError } from '../payment-error.types';

export const formatPaymentErrorTitle = (t: TFunction, error: PaymentError) => {
  switch (error) {
    case PaymentError.PAYMENT_DECLINED:
      return t('Payment declined');
    case PaymentError.INTERNAL_ERROR:
      return t('Something went wrong');
  }
};
