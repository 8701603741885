import { css } from '@emotion/react';

/* todo: check what we have in mobashared */
export const visuallyHidden = css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

export const spreadFixed = css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const TransformCenter = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const placeholderInputStyle = (background: string, fontSize: string) => css`
  /* WebKit, Blink, Edge */
  &::-webkit-input-placeholder {
    color: ${background};
    font-size: ${fontSize};
  }

  /* Mozilla Firefox 4 to 18 */
  &:-moz-placeholder {
    color: ${background};
    opacity: 1;
    font-size: ${fontSize};
  }

  /* Mozilla Firefox 19+ */
  &::-moz-placeholder {
    color: ${background};
    opacity: 1;
    font-size: ${fontSize};
  }

  /* Internet Explorer 10-11 */
  &:-ms-input-placeholder {
    color: ${background};
    font-size: ${fontSize};
  }
  &::placeholder {
    color: ${background};
    font-size: ${fontSize};
  }
`;
