import { css } from '@emotion/react';
import { figmaIcon, Link, Loader, Logger } from '@mobalytics/shared';
import * as React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Redirect, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Mixpanel } from '../../marketing/mixpanel';
import { PaymentSection } from '../../types/payment.types';
import { genPaymentPlanSelectionPath, genRedirectUrl } from '../../utils/formatter/path.formatter';
import { STRIPE_TOKEN } from '../../config';
import { MascotLayout } from '../../ui/layout/mascot-layout/mascot-layout.component';
import { TreasuryPaymentsProvider } from '../../__generated/treasury/types';
import { useAccount } from './hooks/account.hook';
import { usePaymentFilters } from './hooks/payment-filters.hook';
import { usePaymentPageData } from './hooks/payment-page-data.hook';
import { useSignInRedirect } from './hooks/sing-in-redirect.hook';
import { PlanSelection } from './plan-selection/plan-selection.component';
import { PaymentDetails } from './payment-details/payment-details.component';
import { ThankYou } from './thank-you/thank-you.component';
import { Error } from './error/error.component';
import { StripeFlowProcessing } from './payment-details/payment-flow/stripe-flow/stripe-flow-processing/stripe-flow-processing.component';
import { PaymentMethodProvider } from './payment-details/payment-method-provider/payment-method-provider.component';

export const stripePromise = loadStripe(STRIPE_TOKEN);

export const Payment: React.FC = () => {
  const { game, section, couponQueryParam, planId } = usePaymentFilters();
  const { account, isDataLoaded } = useAccount();
  const {
    loading,
    data,
    plans,
    currentPlanId,
    trialPlan,
    plansWithDiscount,
    selectedPlan,
    allowedActions,
    billingInfo,
    flowStep,
    flowInfo,
    refetchWithCoupon,
  } = usePaymentPageData({
    game,
    coupon: couponQueryParam,
    planId,
  });
  const { search } = useLocation();
  const { paypal, stripe } = billingInfo;
  const { t } = useTranslation();

  // todo: move  to top level
  React.useEffect(() => {
    if (account?.uid) {
      Mixpanel.onUserStatusChanged(account);
    }
  }, [account]);

  useSignInRedirect(isDataLoaded, !!account?.uid);

  if (loading && !data) {
    return (
      <div css={wrapperCss}>
        <div css={containerCss}>
          <Loader />
        </div>
      </div>
    );
  }

  if (!game) {
    Logger.error('[Payment]: game in url is missing');
    return (
      <div css={wrapperCss}>
        <div css={containerCss}>
          <MascotLayout
            title={t('Game is unknown...')}
            description={t('Make sure that you come from valid game.')}
            mascotEmotion="sad"
          />
        </div>
      </div>
    );
  }

  if (!plans) {
    Logger.error('[Payment]: available plans are missing');
    return (
      <div css={wrapperCss}>
        <div css={containerCss}>
          <MascotLayout
            title={t('Something went wrong...')}
            description={t('There are no available plans. Please try again later or contact our support.')}
            mascotEmotion="sad"
          />
        </div>
      </div>
    );
  }

  if ((section === PaymentSection.PAYMENT_PROCESSING || section === PaymentSection.PAYMENT_INFO) && !selectedPlan) {
    Logger.info('Redirecting back to plan selection because there is no available plan specified in url');
    return <Redirect to={genPaymentPlanSelectionPath(search)} />;
  }

  return (
    <div css={wrapperCss}>
      {(section === PaymentSection.PLAN_SELECTION || section === PaymentSection.PAYMENT_INFO) && (
        <div css={headerCss}>
          <Link to={genRedirectUrl()} isExternal>
            <img src={figmaIcon('mobalytics-logo', 'logo-mobalytics-collapsed')} alt="" />
          </Link>
        </div>
      )}

      <div css={containerCss}>
        {section === PaymentSection.PLAN_SELECTION && (
          <PlanSelection
            game={game}
            loading={loading}
            currentPlanId={currentPlanId}
            plans={plans}
            trialPlan={trialPlan}
            allowedActions={allowedActions}
            refetchWithCoupon={refetchWithCoupon}
            flowStep={flowStep}
            flowInfo={flowInfo}
          />
        )}
        {section === PaymentSection.PAYMENT_INFO && (
          <PaymentMethodProvider
            defaultPaymentMethod={paypal ? TreasuryPaymentsProvider.BRAINTREE : TreasuryPaymentsProvider.STRIPE}
          >
            <PaymentDetails
              game={game}
              allowedActions={allowedActions}
              plans={plans}
              plansWithDiscount={plansWithDiscount}
              currentPlanId={currentPlanId}
              selectedPlan={selectedPlan!}
              paypal={paypal}
              stripePaymentMethods={stripe}
            />
          </PaymentMethodProvider>
        )}
        {section === PaymentSection.PAYMENT_PROCESSING && (
          <StripeFlowProcessing
            game={game}
            allowedActions={allowedActions}
            planId={selectedPlan!.id}
            coupon={selectedPlan?.appliedCoupon}
          />
        )}
        {section === PaymentSection.THANK_YOU && <ThankYou planType={selectedPlan?.planType} />}
        {section === PaymentSection.ERROR && <Error />}
      </div>
    </div>
  );
};

const wrapperCss = css`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const containerCss = css`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`;

const headerCss = css`
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #251e47;
`;
