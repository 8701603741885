import { action, observable } from 'mobx';
import * as React from 'react';
import { Region } from '@mobalytics/shared';
import { GQLAPIErrorMessage } from '../types/error.types';
import { formatError } from '../utils/format';
import { parseAppQueryParams } from '../utils/url';

export class InputStore {
  @observable isLoading: boolean = false;
  @observable generalError: string | null = null;

  @observable termsAndServiceAgreement: boolean = true;
  @observable emailsReceivingAgreement: boolean = true;

  @observable name: string = '';
  @observable nameError: string | null = null;

  @observable email: string = '';
  @observable emailError: string | null = null;
  @observable emailErrorCode: GQLAPIErrorMessage | null = null;

  @observable password: string = '';
  @observable passwordError: string | null = null;

  @observable passwordConfirmation: string = '';
  @observable passwordConfirmationError: string | null = null;

  @observable summonerName: string = '';
  @observable summonerNameError: string | null = null;

  @observable region: Region = Region.NA;

  @observable token: string = '';
  @observable tokenError: string | null = null;

  @observable accessCode: string = '';
  @observable accessCodeError: string | null = null;

  @action
  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };

  @action
  setGeneralError = (value: string | null) => {
    this.generalError = value;
  };

  @action
  onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.nameError = null;
    this.name = e.currentTarget.value;
  };

  @action
  setNameError = (value: string | null) => {
    this.nameError = value;
  };

  @action
  onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.emailError = null;
    this.email = e.currentTarget.value;
  };

  @action
  setEmail = (value: string) => {
    this.email = value;
  };

  @action
  setEmailError = (value: string | null, code: GQLAPIErrorMessage | null) => {
    this.emailError = value;
    this.emailErrorCode = code;
  };

  @action
  onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.passwordError = null;
    this.password = e.currentTarget.value;
  };

  @action
  onChangePasswordConfirmation = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.passwordError = null;
    this.passwordConfirmation = e.currentTarget.value;
  };

  @action
  setPasswordError = (value: string | null) => {
    this.passwordError = value;
  };

  @action
  setPasswordConfirmationError = (value: string | null) => {
    this.passwordConfirmationError = value;
  };

  @action
  onChangeSummonerName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.summonerNameError = null;
    this.summonerName = e.currentTarget.value;
  };

  @action
  setSummonerNameError = (value: string | null) => {
    this.summonerNameError = value;
  };

  @action
  onSetSummonerName = (summonerName: string) => {
    this.summonerNameError = null;
    this.summonerName = summonerName;
  };

  @action
  onChangeRegion = (region: Region) => {
    this.region = region;
  };

  @action
  setToken = (value: string) => {
    this.token = value;
  };

  setTokenFromUrl = () => {
    const queryParams = parseAppQueryParams();
    queryParams.token
      ? this.setToken(queryParams.token)
      : this.setTokenError(formatError(GQLAPIErrorMessage.TOKEN_INVALID));
  };

  @action
  onChangeToken = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.tokenError = null;
    this.token = e.currentTarget.value;
  };

  @action
  setTokenError = (value: string | null) => {
    this.tokenError = value;
  };

  @action
  onChangeAccessCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.accessCodeError = null;
    this.accessCode = e.currentTarget.value;
  };

  @action
  setAccessCodeError = (value: string | null) => {
    this.accessCodeError = value;
  };

  @action
  toggleEmailsReceivingAgreement = () => {
    this.emailsReceivingAgreement = !this.emailsReceivingAgreement;
  };

  @action
  toggleTermsAndServiceAgreement = () => {
    this.termsAndServiceAgreement = !this.termsAndServiceAgreement;
  };

  @action
  resetErrors = () => {
    this.nameError = null;
    this.emailError = null;
    this.emailErrorCode = null;
    this.tokenError = null;
    this.generalError = null;
    this.passwordError = null;
    this.accessCodeError = null;
    this.passwordConfirmationError = null;
    this.summonerNameError = null;
  };

  @action
  reset = () => {
    this.resetErrors();
    this.isLoading = false;
    this.name = '';
    this.token = '';
    this.email = '';
    this.password = '';
    this.accessCode = '';
    this.passwordConfirmation = '';
    this.termsAndServiceAgreement = true;
  };
}
