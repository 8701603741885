import { css, Global } from '@emotion/react';
import { FigmaColorsMixin, GlobalStyles } from '@mobalytics/shared';
import emotionNormalize from 'emotion-normalize';
import { ColorsMixin } from '../../utils/colors.mixin';
import { Roboto } from './theme';

export const styles = (
  <Global
    styles={css`
      html,
      body {
        font-family: ${Roboto};
        font-size: 10px;
        color: #fff;
        overscroll-behavior: none;
      }
      ${GlobalStyles};
      ${emotionNormalize};
      ${FigmaColorsMixin};
      ${ColorsMixin}
    `}
  />
);
