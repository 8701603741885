import React from 'react';
import { PaymentMethodContext } from './payment-method-provider.component';

export const usePaymentMethod = () => {
  const { paymentMethod, setPaymentMethod } = React.useContext(PaymentMethodContext);

  return {
    paymentMethod,
    setPaymentMethod,
  };
};
