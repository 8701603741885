import { TreasuryPaymentsGame } from '../../../../../__generated/treasury/types';
import { CDN_IMAGES } from '../../../../../constants';

export const getPromoImageByGame = (game: TreasuryPaymentsGame) => {
  switch (game) {
    case TreasuryPaymentsGame.LOL:
      return `${CDN_IMAGES}/one-time-plus-offer/lol-skin-chest.png`;
    case TreasuryPaymentsGame.TFT:
      return `${CDN_IMAGES}/one-time-plus-offer/tft-egg-chest.png`;
    default:
      return null;
  }
};
