import { Logger, NString } from '@mobalytics/shared';
import { BraintreeTokenizePayload } from '@paypal/react-paypal-js/dist/types/types/braintree/commonsTypes';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { usePaymentFlow } from '../../payment-flow.hook';
import { PaypalFlowStepT } from '../paypal-flow.types';
import { TreasuryPaymentsGame, TreasuryPaymentsProvider } from '../../../../../../__generated/treasury/types';
import { AllowedActionsT } from '../../../../payment.types';
import { genPaymentErrorPath, genPaymentThankYouPath } from '../../../../../../utils/formatter/path.formatter';
import { PaymentError } from '../../../../payment-error.types';

type Params = {
  planId: NString;
  game: TreasuryPaymentsGame;
  coupon: NString;
  allowedActions: AllowedActionsT;
};

export const usePaypalFlowNew = (params: Params) => {
  const { game, coupon, allowedActions, planId } = params;
  const [step, changeStep] = React.useState<PaypalFlowStepT>('paypal-auth');
  const [tokenResponse, setTokenResponse] = React.useState<BraintreeTokenizePayload | null>(null);
  const [loading, setIsLoading] = React.useState(false);
  const { search } = useLocation();
  const { replace } = useHistory();
  const {
    createNewSubscription,
    changeCurrentSubscription,
    renewCurrentSubscription,
    updatePaymentInfo,
  } = usePaymentFlow();

  const onPaypalApprove = (payload: BraintreeTokenizePayload) => {
    setTokenResponse(payload);
    changeStep('billing-info');
  };

  const processPayment = async () => {
    if (!planId) {
      Logger.error('[usePaypalFlowNew]:[processPayment]: planId is missing');
      replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
      return;
    }
    if (!tokenResponse?.nonce) {
      Logger.error('[usePaypalFlowNew]:[processPayment]: nonce is missing');
      replace(genPaymentErrorPath(search, PaymentError.PAYMENT_DECLINED));
      return;
    }

    try {
      setIsLoading(true);
      const updatePaymentInfoResult = await updatePaymentInfo(tokenResponse.nonce, TreasuryPaymentsProvider.BRAINTREE);
      if (updatePaymentInfoResult?.error) {
        Logger.error('[usePaypalFlowNew]:[processPayment]: updatePaymentInfo error', updatePaymentInfoResult.error);
        replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
        return;
      }
      let response = null;
      if (allowedActions.change) {
        response = await changeCurrentSubscription(planId, game, coupon);
      } else if (allowedActions.create) {
        response = await createNewSubscription(planId, game, coupon, TreasuryPaymentsProvider.BRAINTREE);
      } else if (allowedActions.renew) {
        response = await renewCurrentSubscription(planId, game, coupon);
      } else {
        Logger.error('[usePaypalFlowNew]:[processPayment]: no allowed actions');
        replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
        return;
      }
      if (!response) {
        Logger.error('[usePaypalFlowNew]:[processPayment]: response is missing');
        replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
        return;
      }
      if (response.error) {
        Logger.error('[usePaypalFlowNew]:[processPayment]: response error', response.error);
        replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
        return;
      }
      replace(genPaymentThankYouPath(planId, search));
    } catch (e) {
      Logger.error('[usePaypalFlowNew]:[processPayment]: unknown error', e);
      replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
    } finally {
      setIsLoading(true);
    }
  };

  return {
    loading,
    step,
    tokenResponse,
    onPaypalApprove,
    processPayment,
  };
};
