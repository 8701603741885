import * as React from 'react';
import { css } from '@emotion/react';
import { colorImgToGray, figmaIcon, TextSubtitle3RMMixin } from '@mobalytics/shared';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
}

export const SecurePayments: React.FC<Props> = props => {
  const { className } = props;
  const { t } = useTranslation();
  return (
    <div css={wrapperCss} className={className}>
      <img src={figmaIcon('system-icons', '24-locked')} alt="" />
      <p>{t('Secure payment')}</p>
    </div>
  );
};

const wrapperCss = css`
  ${TextSubtitle3RMMixin};
  color: var(--general-grey-300);
  filter: ${colorImgToGray};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
`;
