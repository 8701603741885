import * as React from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeConfirmationInfo } from '../../stripe-flow.types';
import { useStripeFlowExistingConfirmation } from '../stripe-existing-flow-payment-confirmation.hook';

interface Props {
  className?: string;
  confirmationInfo: StripeConfirmationInfo;
}

export const StripeExistingFlowConfirmation: React.FC<Props> = props => {
  const { confirmationInfo } = props;
  const { confirmPayment } = useStripeFlowExistingConfirmation(confirmationInfo);
  const stripe = useStripe();
  const elements = useElements();

  React.useEffect(() => {
    if (stripe && elements) {
      confirmPayment();
    }
  }, [confirmPayment, elements, stripe]);

  return null;
};
