import * as React from 'react';
import { TreasuryPaymentsProvider } from '../../../../__generated/treasury/types';

interface Props {
  children: React.ReactNode;
  defaultPaymentMethod: TreasuryPaymentsProvider;
}

export const PaymentMethodContext = React.createContext<{
  paymentMethod: TreasuryPaymentsProvider;
  setPaymentMethod: (paymentMethod: TreasuryPaymentsProvider) => void;
}>({
  paymentMethod: TreasuryPaymentsProvider.STRIPE,
  setPaymentMethod: () => {},
});

export const PaymentMethodProvider: React.FC<Props> = props => {
  const { children, defaultPaymentMethod } = props;

  const [paymentMethod, setPaymentMethod] = React.useState(defaultPaymentMethod || 'stripe');
  const value = React.useMemo(() => ({ paymentMethod, setPaymentMethod }), [paymentMethod]);

  return <PaymentMethodContext.Provider value={value}>{children}</PaymentMethodContext.Provider>;
};
