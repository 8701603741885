import { Mixpanel as MixpanelProxy, NString, Platform, ReservedProp, SuperProps } from '@mobalytics/shared';
import * as MixpanelBrowser from 'mixpanel-browser';
import { MIXPANEL_TOKEN } from '../config';
import { AccountFragment } from '../api/accounts/fragments/__generated/account-fragment.gql.generated';

export enum MPEventName {
  LOGIN = 'Login',
  PAGE_VISITED = 'Page visited',
  PLAN_SELECTED = 'Plan selected',
  TRIAL_SELECTED = 'Trial selected',
  PLAN_CHANGED = 'Plan changed',
  COUPON_APPLIED = 'Coupon applied',
  PAYMENT_INFO_NEXT_STEP_CLICKED = 'Payment info next step clicked',
  PAYMENT_INFO_PAYPAL_CLICKED = 'Payment info paypal clicked',
  PAYMENT_INFO_STRIPE_CLICKED = 'Payment info stripe clicked',
  BILLING_INFO_NEXT_STEP_CLICKED = 'Billing info next step clicked',
  BANNER_DISPLAYED = 'Banner displayed',
  BANNER_CLICKED = 'Banner clicked',
  BANNER_CLOSED = 'Banner closed',
}

export enum MPPage {
  MAIN = 'Main page',
  ERROR = 'Error page',
  SIGN_IN = 'Sign in page',
  SIGN_UP = 'Sign up page',
  LOL_SUMMONER_NAME = 'Set Summoner page',
  PASSWORD_RESET = 'Password reset page',
  DESKTOP_SIGN_IN = 'Desktop sign in page',
  DESKTOP_SIGN_OUT = 'Desktop sign out page',
  DESKTOP_SIGN_IN_REDIRECT = 'Desktop sign in redirect page',
  DESKTOP_SIGN_IN_COMPLETE_PAGE = 'Desktop sign in complete page',
  SIGN_UP_DETAILS = 'Sign up email request page',
  CONNECT_MOBA_ACCOUNT = 'Connect moba account page',
  PASSWORD_RESET_SUCCESS = 'Password reset success page',
  REQUEST_PASSWORD_RESET = 'Request password reset page',
  REQUEST_PASSWORD_RESET_SUCCESS = 'Request password reset success page',
  PAYMENT = 'Payment',
}

export enum MPPageSection {
  PLAN_SELECTION = 'Plan selection',
  PAYMENT_INFO = 'Payment info',
  THANK_YOU = 'Thank you',
}

export class Mixpanel {
  static init() {
    MixpanelProxy.init(MIXPANEL_TOKEN);
    MixpanelProxy.superPropsSet({
      [SuperProps.Platform]: Platform.WEB,
    });
  }

  static onUserStatusChanged(account: Pick<AccountFragment, 'uid' | 'login' | 'email'>) {
    MixpanelProxy.identify(account.uid);
    MixpanelProxy.superPropsSet({
      [ReservedProp.EMAIL]: account.email,
      [ReservedProp.NAME]: account.login,
    });
  }

  static trackPageVisited(page: MPPage, section: NString, game: string) {
    const pageSection = section ? { Section: section } : {};
    MixpanelProxy.trackEvent(MPEventName.PAGE_VISITED, {
      'Page name': page,
      ...pageSection,
      Game: game,
    });
  }

  static track(event: MPEventName, properties?: MixpanelBrowser.Dict) {
    MixpanelProxy.trackEvent(event, properties);
  }
}
