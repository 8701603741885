import * as queryString from 'query-string';
import { AccountsAppPath, AuthProviderQueryParams } from '../types/app.types';
import { SingUpMarketingParams } from '../__generated/accounts/types';
import { Provider } from '../types/provider';
import { Theme } from '../types/theme';

export function generateProviderEnterUrl(
  provider: Provider,
  redirectUrl: string,
  theme: Theme,
  lang: string,
  continueFrom?: string,
  marketing?: SingUpMarketingParams | null,
  mobaReferrerId?: string
): string {
  const errorParams = { provider, theme, lang };
  const errorPage = `${window.location.origin}${AccountsAppPath.ERROR}?${queryString.stringify(errorParams, {
    encode: true,
  })}`;
  const urlParams: AuthProviderQueryParams = {
    ...marketing,
    theme,
    lang,
    redirect_uri: redirectUrl,
    error_redirect_uri: errorPage,
    referrer_code: mobaReferrerId,
  };
  if (continueFrom) {
    urlParams.continue_from = continueFrom;
  }
  return `/api/auth/v1/external/${provider}/enter?${queryString.stringify(urlParams, { encode: true })}`;
}
