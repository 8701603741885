import * as React from 'react';
import {
  ButtonColor,
  media,
  TextBody3RRMixin,
  TextH3OMMixin,
  TextMetric2OMMixin,
  TextSubtitle1RMMixin,
  TextSubtitle2RBMixin,
} from '@mobalytics/shared';
import { Trans, useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Modal } from '../../../../ui/modals/modal/modal.component';
import { ModalBody } from '../../../../ui/modals/modal/modal-body.component';
import { AccountsButton } from '../../../../ui/buttons/accounts-button/accounts-button.component';
import { TreasuryPaymentsGame } from '../../../../__generated/treasury/types';
import { PlanT } from '../../payment.types';
import { getMoneyAsString } from '../../utils/get-money-as-string.utils';
import { getPromoImageByGame } from './utils/get-promo-image-by-game.util';
import { getPromoRewardMessageByGame } from './utils/get-promo-message-by-game.util';
import { getButtonLabelByGame } from './utils/get-button-label-by-game.util';

interface Props {
  game: TreasuryPaymentsGame;
  plan: PlanT;
  onApplyOffer: () => void;
  onRejectOffer: () => void;
}

export const OneTimeOfferModal: React.FC<Props> = props => {
  const { game, onApplyOffer, onRejectOffer, plan } = props;
  const { t } = useTranslation();
  const image = getPromoImageByGame(game);
  return (
    <Modal css={modalCss}>
      <ModalBody css={modalBodyCss}>
        <div css={badgeContainerCss}>
          <div css={badgeCss}> -{plan.discount.coupon} OFF</div>
        </div>
        <div css={rowCss}>
          <div css={imageWrapperCss}>{image && <img src={image} css={imageCss} alt="" />}</div>
          <div css={contentCss}>
            <h2 css={headerCss}>
              <Trans
                defaults="Subscribe now for <what>{{what}}</0>"
                values={{ what: t('a ONE-TIME offer!') }}
                components={{ what: <div css={headerHighlightCss} /> }}
              />
            </h2>
            <div css={descriptionCss}>
              <Trans
                defaults="<reward>{{reward}}</reward> if you buy an annual Plus subscription now (includes a {{discount}} discount)."
                values={{ reward: getPromoRewardMessageByGame(game, t), discount: plan.discount.coupon }}
                components={{ reward: <span css={descriptionHighlightCss} /> }}
              />
            </div>
            <div css={pricesCss}>
              <span css={oldPriceCss}>{getMoneyAsString(plan.currency, plan.basePrice)}</span>&nbsp;
              <span>{getMoneyAsString(plan.currency, plan.finalPrice)}</span>&nbsp;&nbsp;
              <span css={discountCss}>-{plan.discount.coupon} PLUS</span>
            </div>
          </div>
        </div>
        <div css={buttonsWrapperCss}>
          <AccountsButton
            analyticsButtonName="Get plus instead of trial"
            color={ButtonColor.Plus}
            onClick={onApplyOffer}
          >
            {getButtonLabelByGame(game, t)}
          </AccountsButton>
          <AccountsButton analyticsButtonName="Get trial" color={ButtonColor.Secondary} onClick={onRejectOffer}>
            {t('continue to trial')}
          </AccountsButton>
        </div>
        <p css={notificationCss}>
          {t('*First year is non-refundable. Offer is available only in NA, EUW, EUNE, and OCE.')}
        </p>
      </ModalBody>
    </Modal>
  );
};

const modalCss = css`
  max-width: 800px;
`;

const modalBodyCss = css`
  padding-bottom: 16px;
`;

const badgeContainerCss = css`
  position: absolute;
  width: 150px;
  height: 150px;
  right: 0;
  top: 0;
  overflow: hidden;
`;

const badgeCss = css`
  ${TextSubtitle1RMMixin};
  width: 200%;
  height: 52px;
  background: blue;
  transform: rotate(45deg) translateY(64px) translateX(-20px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(90deg, #fb571e 0%, #f9942b 100%);
`;

const rowCss = css`
  display: flex;
  margin-bottom: 40px;
  ${media.small} {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
`;

const imageWrapperCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
`;

const imageCss = css`
  width: 296px;
  height: 246px;
  transform: scale(1.3);
`;

const contentCss = css`
  max-width: 296px;
  margin-top: 8px;
  margin-left: 8px;
`;

const headerCss = css`
  ${TextH3OMMixin};
  margin-bottom: 20px;
`;

const headerHighlightCss = css`
  color: var(--general-orange-200);
  text-decoration: underline;
  text-underline-offset: 8px;
`;

const descriptionCss = css`
  ${TextSubtitle2RBMixin};
  margin-bottom: 32px;
`;

const descriptionHighlightCss = css`
  color: var(--general-orange-200);
`;

const pricesCss = css`
  ${TextMetric2OMMixin};
`;

const oldPriceCss = css`
  color: var(--general-grey-100);
  text-decoration: line-through;
  text-line-through-color: var(--general-grey-100);
`;

const discountCss = css`
  color: var(--general-orange-200);
`;

const buttonsWrapperCss = css`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 16px;
  ${media.small} {
    flex-direction: column;
  }
`;

const notificationCss = css`
  ${TextBody3RRMixin};
  color: var(--general-grey-100);
  text-align: center;
`;
