import { CDN_IMAGES } from '../../constants';
import { AppThemeConfig } from '../../types/app.types';
import { Game } from '../../__generated/accounts/types';
import { Provider } from '../../types/provider';

export const config: AppThemeConfig = {
  gameName: Game.TFT,
  isPremiumSubscriptionAvailable: true,
  wallpaper: `${CDN_IMAGES}/backgrounds/desktop-onboarding-bg.png`,
  paymentWallpaper: `${CDN_IMAGES}/backgrounds/tft-payment-bg.jpg`,
  paymentCongratulationWallpaper: `${CDN_IMAGES}/backgrounds/tft-payment-congratulation-bg.jpg`,
  testimonials: [
    {
      icon: 'https://cdn.mobalytics.gg/stable/profileicon/4568.png',
      name: 'the cook7000, NA',
      text: 'testimonials-tft-the-cook7000',
    },
    {
      icon: 'https://cdn.mobalytics.gg/stable/profileicon/4276.png',
      name: 'Strikzy, EUW',
      text: 'testimonials-tft-strikzy',
    },
    {
      icon: 'https://cdn.mobalytics.gg/stable/profileicon/4569.png',
      name: 'Muzly, EUW',
      text: 'testimonials-tft-muzly',
    },
    {
      icon: 'https://cdn.mobalytics.gg/stable/profileicon/909.png',
      name: 'Jusjeet21, NA',
      text: 'testimonials-tft-jusjeet21',
    },
    {
      icon: 'https://cdn.mobalytics.gg/stable/profileicon/15.png',
      name: 'HYDEBLOOD, NA',
      text: 'testimonials-tft-hudeblood',
    },
  ],
  primaryProviders: [Provider.Twitch, Provider.Google],
  fallbackRedirectUri: 'https://app.mobalytics.gg/',
};
