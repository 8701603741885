import { css, keyframes } from '@emotion/react';
import { HBoxCCMixin, HBoxECMixin } from '@mobalytics/shared';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStore } from '../../store/app-store.hook';
import { TestimonialDot } from './testimonial-dot/testimonial-dot.component';
import { TestimonialItem } from './testimonial-item/testimonial-item.component';

interface Props {
  className?: string;
}

export const Testimonials: React.FC<Props> = observer(props => {
  const { className } = props;
  const { t } = useTranslation();
  const { testimonialsStore } = useAppStore();
  const { testimonials, activeIndex: currentIndex, scroll, setActiveIndex } = testimonialsStore;

  React.useEffect(() => {
    setInterval(scroll, 8000);
  }, [scroll]);

  const dotClickHandler = React.useCallback(
    (index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  return (
    <div className={className}>
      <div css={listWrapperCss}>
        {testimonials?.map((it, index) => (
          <TestimonialItem
            key={index}
            authorIcon={it.icon}
            authorName={it.name}
            text={t(it.text)}
            css={[testimonialItemCss, index === currentIndex && testimonialItemActiveCss]}
          />
        ))}
      </div>
      <div css={dotsWrapperCss}>
        {testimonials?.map((_it, index) => (
          <TestimonialDot key={index} index={index} isActive={index === currentIndex} onClick={dotClickHandler} />
        ))}
      </div>
    </div>
  );
});

const listWrapperCss = css`
  ${HBoxECMixin};
  gap: 20px;
`;

const slideToLeft = keyframes`
0%{
    transform: translateX(0);
    opacity: 1;

}
100%{
    transform: translateX(-150%);
    opacity: 0;
}`;

const slideFromRight = keyframes`
0%{
    transform: translateX(150%);
    opacity: 0;

}
100%{
    transform: translateX(0);
    opacity: 1;
}`;

const testimonialItemCss = css`
  width: 100%;
  position: absolute;
  animation: ${slideToLeft} 1s ease-out both;
`;

const testimonialItemActiveCss = css`
  position: static;
  animation: ${slideFromRight} 1s ease-out both;
`;

const dotsWrapperCss = css`
  ${HBoxCCMixin};
  margin-top: 16px;
  gap: 8px;
`;
