import * as Types from '../../../../__generated/treasury/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PrepareUpdateBillingInfoMutationVariables = Types.Exact<{ [key: string]: never }>;

export type PrepareUpdateBillingInfoMutation = {
  __typename: 'Mutation';
  treasury?: {
    __typename: 'TreasuryMutation';
    id: string;
    payments?: {
      __typename: 'TreasuryPaymentsMutation';
      prepareUpdateBillingInfo?: {
        __typename: 'TreasuryPaymentsPrepareUpdateBillingInfoPayload';
        setupIntentSecret?: string | null;
        error?:
          | { __typename: 'TreasuryPaymentsUnauthenticatedError'; message?: string | null }
          | { __typename: 'TreasuryPaymentsUnknownError'; message?: string | null }
          | null;
      } | null;
    } | null;
  } | null;
};

export const PrepareUpdateBillingInfoMutationDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PrepareUpdateBillingInfoMutation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'treasury' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'prepareUpdateBillingInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'error' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'TreasuryPaymentsError' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'TreasuryPaymentsUnauthenticatedError' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'TreasuryPaymentsUnknownError' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'setupIntentSecret' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export type PrepareUpdateBillingInfoMutationMutationFn = Apollo.MutationFunction<
  PrepareUpdateBillingInfoMutation,
  PrepareUpdateBillingInfoMutationVariables
>;

/**
 * __usePrepareUpdateBillingInfoMutation__
 *
 * To run a mutation, you first call `usePrepareUpdateBillingInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepareUpdateBillingInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepareUpdateBillingInfoMutation, { data, loading, error }] = usePrepareUpdateBillingInfoMutation({
 *   variables: {
 *   },
 * });
 */
export function usePrepareUpdateBillingInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<PrepareUpdateBillingInfoMutation, PrepareUpdateBillingInfoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PrepareUpdateBillingInfoMutation, PrepareUpdateBillingInfoMutationVariables>(
    PrepareUpdateBillingInfoMutationDocument,
    options
  );
}
export type PrepareUpdateBillingInfoMutationHookResult = ReturnType<typeof usePrepareUpdateBillingInfoMutation>;
export type PrepareUpdateBillingInfoMutationMutationResult = Apollo.MutationResult<PrepareUpdateBillingInfoMutation>;
export type PrepareUpdateBillingInfoMutationMutationOptions = Apollo.BaseMutationOptions<
  PrepareUpdateBillingInfoMutation,
  PrepareUpdateBillingInfoMutationVariables
>;
