import * as React from 'react';
import { SetSummonerForm } from '../../../../components/forms/set-summoner-form/set-summoner-form.component';
import { AuthPageLayout } from '../../../../ui/layout/auth-page-layout/auth-page-layout.component';

export const LolSetSummoner: React.FC = () => {
  return (
    <AuthPageLayout>
      <SetSummonerForm />
    </AuthPageLayout>
  );
};
