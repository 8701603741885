import { GQLAPIErrorMessage } from '../types/error.types';
import { Provider } from '../types/provider';

const errorToTranslationMap: Record<GQLAPIErrorMessage, string> = {
  // general errors
  [GQLAPIErrorMessage.INTERNAL_ERROR]: 'error-internal',
  // auth errors
  [GQLAPIErrorMessage.AUTH_CREDENTIALS_INVALID]: 'error-wrong-email-or-password',
  [GQLAPIErrorMessage.AUTH_EMAIL_ALREADY_EXISTS]: 'error-email-already-exists',
  [GQLAPIErrorMessage.PASSWORD_INVALID]: 'error-password-invalid',
  [GQLAPIErrorMessage.PASSWORD_IS_EMPTY]: 'error-password-empty',
  [GQLAPIErrorMessage.PASSWORD_TOO_SHORT]: 'error-password-too-short',
  [GQLAPIErrorMessage.PASSWORD_TOO_LONG]: 'error-password-too-long',
  [GQLAPIErrorMessage.PASSWORD_CONFIRMATION_DOESNT_MATCH]: 'error-password-confirmation-doesnt-match',
  [GQLAPIErrorMessage.EMAIL_INVALID]: 'error-email-invalid',
  [GQLAPIErrorMessage.EMAIL_IS_EMPTY]: 'error-email-empty',
  [GQLAPIErrorMessage.EMAIL_INVALID_FORMAT]: 'error-email-invalid-format',
  [GQLAPIErrorMessage.EMAIL_NOT_FOUND]: 'error-email-not-found',
  [GQLAPIErrorMessage.EMAIL_ALREADY_EXISTS]: 'error-email-already-exists',
  [GQLAPIErrorMessage.NAME_INVALID]: 'error-name-invalid',
  [GQLAPIErrorMessage.NAME_IS_EMPTY]: 'error-name-empty',
  [GQLAPIErrorMessage.ACCESS_CODE_EMPTY]: 'error-access-code-empty',
  [GQLAPIErrorMessage.ACCESS_CODE_INVALID]: 'error-access-code-invalid',
  [GQLAPIErrorMessage.TOKEN_INVALID]: 'error-token-invalid',
  [GQLAPIErrorMessage.UNAUTHENTICATED]: 'error-unauthenticated',
  [GQLAPIErrorMessage.AUTH_CREDENTIALS_INVALID]: 'error-wrong-email-or-password',
  // payment errors
  [GQLAPIErrorMessage.COUPON_INVALID]: 'error-wrong-coupon-code',
  [GQLAPIErrorMessage.PAYMENT_COUPON_INVALID]: 'error-wrong-coupon-code',
  [GQLAPIErrorMessage.PAYMENT_SUBSCRIPTION_CREATE_ERROR]: 'error-subscription-create-error',
  [GQLAPIErrorMessage.PAYMENT_SUBSCRIPTION_UPDATE_ERROR]: 'error-subscription-update-error',
  [GQLAPIErrorMessage.SUMMONER_DOES_NOT_EXIST]: 'error-summoner-does-not-exist',
  [GQLAPIErrorMessage.UNDEFINED_TOKEN_OPERATION]: 'error-undefined-token-operation',
  [GQLAPIErrorMessage.NOT_ENOUGH_TOKENS]: 'error-not-enough-tokens',
  [GQLAPIErrorMessage.PAYMENT_DECLINED]: 'error-payment-declined',
  [GQLAPIErrorMessage.CARD_DECLINED]: 'error-card-declined',
  [GQLAPIErrorMessage.EXPIRED_CARD]: 'error-expired-card',
  [GQLAPIErrorMessage.INCORRECT_CVC]: 'error-incorrect-cvv',
  [GQLAPIErrorMessage.PROCESSING_ERROR]: 'error-processing-error',
  [GQLAPIErrorMessage.INCORRECT_NUMBER]: 'error-incorrect-number',
  [GQLAPIErrorMessage.CARD_LIMIT_EXCEEDED]: 'error-card-limit-exceeded',
  [GQLAPIErrorMessage.DECLINED_CARD]: 'error-card-declined',
  [GQLAPIErrorMessage.INCORRECT_CARD_NUMBER]: 'error-card-declined',
};

export function formatError(error: GQLAPIErrorMessage): string {
  return errorToTranslationMap[error];
}

export function formatProviderName(provider: Provider): string {
  // in case there ever will be provider which name should be localized or different from enum value
  switch (provider) {
    case Provider.Discord:
      return 'provider-discord';
    case Provider.Google:
      return 'provider-google';
    case Provider.Twitch:
      return 'provider-twitch';
    case Provider.Facebook:
      return 'provider-facebook';
    case Provider.Steam:
      return 'provider-steam';
    case Provider.Faceit:
      return 'provider-faceit';
    case Provider.Riot:
      return 'provider-riot';
  }
}

/**@deprecated*/
export function tPlural(
  baseTerm: string,
  count: number,
  t: (term: string, params: { [key: string]: string | number }) => string,
  language: string
): string {
  const postfix = new Intl.PluralRules(toBCP_78(language)).select(count);
  return t(`${baseTerm}-${postfix}`, { count });
}

/** For more details see: https://tools.ietf.org/html/bcp47 */
export function toBCP_78(lang: string): string {
  const [language, territory] = lang.split('_');
  if (language === 'zh' && territory === 'chs') {
    return `zh-hans-CH`;
  }
  return `${language}-${(territory || language).toUpperCase()}`;
}
