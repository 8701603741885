export enum RawAPIErrorCode {
  AUTH_EMAIL_ALREADY_EXISTS = 'AUTH_EMAIL_ALREADY_EXISTS',
  NOT_FOUND = 'NOT_FOUND',
  COUPON_INVALID = 'COUPON_INVALID',
  CLEAR_AND_SIGN_IN = 'CLEAR_AND_SIGN_IN',
  BETA_ACCESS_ONLY = 'BETA_ACCESS_ONLY',
  AUTH_CREDENTIALS_INVALID = 'AUTH_CREDENTIALS_INVALID',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  INPUT_INVALID = 'INPUT_INVALID',
  FORBIDDEN = 'FORBIDDEN',
  UNKNOWN = 'UNKNOWN',
  AUTH_EXTERNAL_CONFLICT = 'AUTH_EXTERNAL_CONFLICT',
}

export enum GQLAPIErrorMessage {
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  AUTH_CREDENTIALS_INVALID = 'AUTH_CREDENTIALS_INVALID',
  AUTH_EMAIL_ALREADY_EXISTS = 'AUTH_EMAIL_ALREADY_EXISTS',
  PASSWORD_INVALID = 'PASSWORD_INVALID',
  PASSWORD_IS_EMPTY = 'PASSWORD_IS_EMPTY',
  PASSWORD_TOO_LONG = 'PASSWORD_TOO_LONG',
  PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT',
  PASSWORD_CONFIRMATION_DOESNT_MATCH = 'PASSWORD_CONFIRMATION_DOESNT_MATCH',
  EMAIL_INVALID = 'EMAIL_INVALID',
  EMAIL_IS_EMPTY = 'EMAIL_IS_EMPTY',
  EMAIL_INVALID_FORMAT = 'EMAIL_INVALID_FORMAT',
  EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
  EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
  NAME_INVALID = 'NAME_INVALID',
  NAME_IS_EMPTY = 'NAME_IS_EMPTY',
  ACCESS_CODE_EMPTY = 'ACCESS_CODE_EMPTY',
  ACCESS_CODE_INVALID = 'ACCESS_CODE_INVALID',
  TOKEN_INVALID = 'TOKEN_INVALID',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  PAYMENT_COUPON_INVALID = 'PAYMENT_COUPON_INVALID',
  PAYMENT_SUBSCRIPTION_CREATE_ERROR = 'PAYMENT_SUBSCRIPTION_CREATE_ERROR',
  PAYMENT_SUBSCRIPTION_UPDATE_ERROR = 'PAYMENT_SUBSCRIPTION_UPDATE_ERROR',
  SUMMONER_DOES_NOT_EXIST = 'SUMMONER_DOES_NOT_EXIST',
  COUPON_INVALID = 'COUPON_INVALID',
  UNDEFINED_TOKEN_OPERATION = 'UNDEFINED_TOKEN_OPERATION',
  NOT_ENOUGH_TOKENS = 'NOT_ENOUGH_TOKENS',
  PAYMENT_DECLINED = 'PAYMENT_DECLINED',
  CARD_DECLINED = 'CARD_DECLINED',
  EXPIRED_CARD = 'EXPIRED_CARD',
  INCORRECT_CVC = 'INCORRECT_CVC',
  PROCESSING_ERROR = 'PROCESSING_ERROR',
  INCORRECT_NUMBER = 'INCORRECT_NUMBER',
  CARD_LIMIT_EXCEEDED = 'CARD_LIMIT_EXCEEDED',
  DECLINED_CARD = 'DECLINED_CARD',
  INCORRECT_CARD_NUMBER = 'INCORRECT_CARD_NUMBER',
}
