import { round } from '@mobalytics/shared';

export const getMoneyBaseUnit = (valueInSubunits: number, includeFractions: boolean): number => {
  const rounded = round(valueInSubunits / 100, 2);
  if (includeFractions) {
    return rounded;
  }

  return Math.floor(rounded);
};
