import { Nullable } from '@mobalytics/shared';
import { AllowedActionsT, ChangeActionT, CreateActionT, CustomerT, RenewActionT } from '../payment.types';

export const processAllowedActions = (customer: Nullable<CustomerT>): AllowedActionsT => {
  const createAction = customer?.allowedActions?.find(
    it => it.__typename === 'TreasuryPaymentCustomerActionCreateSubscription' && it.isAllowed
  ) as Nullable<CreateActionT>;
  const changeAction = customer?.allowedActions?.find(
    it => it.__typename === 'TreasuryPaymentCustomerActionChangeSubscription' && it.isAllowed
  ) as Nullable<ChangeActionT>;
  const renewAction = customer?.allowedActions?.find(
    it => it.__typename === 'TreasuryPaymentCustomerActionRenewSubscription' && it.isAllowed
  ) as Nullable<RenewActionT>;
  return {
    create: !!createAction,
    change: !!changeAction,
    renew: !!renewAction,
  };
};
