import styled from '@emotion/styled';
import * as React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { AccountsButton } from '../../../../ui/buttons/accounts-button/accounts-button.component';

interface CopyContainerProps {
  redirectUrl: string;
  className?: string;
}

export const CopyContainer: React.FC<CopyContainerProps> = props => {
  const { t } = useTranslation();
  const { redirectUrl, className } = props;

  const [isCopied, setCopied] = React.useState<boolean>(false);
  const onCopyLink = React.useCallback(() => {
    setCopied(true);
    setTimeout(() => setCopied(false), 800);
  }, [setCopied]);

  return (
    <Wrapper className={className}>
      <CopyToClipboard text={redirectUrl} onCopy={onCopyLink}>
        <AccountsButton analyticsButtonName="Copy sign-in key">{t('ui-copy-sign-in-key')}</AccountsButton>
      </CopyToClipboard>
      {isCopied && <CopiedMessage>{t('ui-sign-in-key-copied')}</CopiedMessage>}
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  position: relative;
  > * {
    width: 100%;
    text-align: center;
  }
`;

export const CopiedMessage = styled.div`
  font-size: 16px;
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translate(-50%, 100%);
`;
