import { TFunction } from 'i18next';
import { PaymentError } from '../payment-error.types';

export const formatPaymentErrorDescription = (t: TFunction, error: PaymentError) => {
  switch (error) {
    case PaymentError.PAYMENT_DECLINED:
      return t('Payment was declined by the payment provider. Please try again or use another payment method.');
    case PaymentError.INTERNAL_ERROR:
      return t('You faced unexpected error, please contact our support.');
  }
};
