import { css } from '@emotion/react';
import { TextBody3RRMixin } from '@mobalytics/shared';
import * as React from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const ErrorMessage: React.FC<Props> = props => {
  const { className, children } = props;
  return (
    <p css={errorMessageCss} className={className}>
      {children}
    </p>
  );
};

const errorMessageCss = css`
  ${TextBody3RRMixin};
  color: var(--general-red-100);
`;
