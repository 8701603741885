import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import * as React from 'react';
import { BRAIN_TREE_TOKEN, PAYPAL_CLIENT_ID } from '../../../../../../../config';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const PaypalProvider: React.FC<Props> = props => {
  const { children } = props;
  return (
    <PayPalScriptProvider
      options={{
        'client-id': PAYPAL_CLIENT_ID,
        'data-user-id-token': BRAIN_TREE_TOKEN,
        'disable-funding': 'credit,card',
        components: 'buttons',
        intent: 'capture',
        commit: false,
        vault: true,
      }}
    >
      {children}
    </PayPalScriptProvider>
  );
};
