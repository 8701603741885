import { css } from '@emotion/react';
import * as React from 'react';
import { DelimiterVariant } from './delimiter.types';

interface Props {
  className?: string;
  variant: DelimiterVariant;
}

export const Delimiter: React.FC<Props> = props => {
  const { className, variant } = props;
  return (
    <div className={className} css={[wrapperCss, variant === 'dark' && darkCss, variant === 'bright' && brightCss]} />
  );
};

const wrapperCss = css`
  flex: 0 0 auto;
  width: 100%;
  max-width: 144px;
  height: 1px;
  margin-top: 24px;
  margin-bottom: 40px;
`;
export const brightCss = css`
  background: linear-gradient(270deg, rgba(44, 39, 79, 0) 14.58%, #ffffff 50.01%, rgba(44, 39, 79, 0) 81.77%);
`;

export const darkCss = css`
  background: linear-gradient(
    270deg,
    var(--general-base-200) 14.58%,
    var(--general-dark-blue-200) 50.01%,
    #2c274f 81.77%
  );
`;
