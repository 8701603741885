import * as React from 'react';
import * as queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { parseAppQueryParams } from '../../../utils/url';
import { oldPaymentsApolloClient } from '../../../old-payments-apollo-client';
import { genPaymentPlanSelectionPath, genSignInPath } from '../../../utils/formatter/path.formatter';
import { usePaymentFilters } from './payment-filters.hook';

export const useSignInRedirect = (loaded: boolean, isAccountAuth: boolean) => {
  const { pathname, search } = useLocation();
  const { section } = usePaymentFilters();
  const { replace } = useHistory();

  React.useEffect(() => {
    if (loaded) {
      /* handle redirect to signin page*/
      if (!isAccountAuth) {
        const appQueryParams = parseAppQueryParams();
        const searchWithPaymentPathname = queryString.stringify({
          ...appQueryParams,
          return_to_payment_pathname: pathname,
        });
        oldPaymentsApolloClient.cache.reset();
        replace(genSignInPath(`?${searchWithPaymentPathname}`));
      } else if (!section) {
        replace(genPaymentPlanSelectionPath(search));
      }
    }
  }, [isAccountAuth, loaded, pathname, replace, search, section]);
};
