import * as React from 'react';
import { RequestPasswordResetSuccessForm } from '../../components/forms/request-password-reset-success-form/request-password-reset-success-form.component';
import { PageMeta } from '../../components/page-meta';
import { AuthPageLayout } from '../../ui/layout/auth-page-layout/auth-page-layout.component';

export const RequestPasswordResetSuccess: React.FC = () => {
  return (
    <AuthPageLayout>
      <PageMeta title="ui-email-was-sent" description="ui-email-was-sent-disclaimer" />
      <RequestPasswordResetSuccessForm />
    </AuthPageLayout>
  );
};
