import styled from '@emotion/styled';

export const BaseButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  outline: none;
  white-space: nowrap;
  font-style: normal;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
  transition: background-color 0.2s ease-in, opacity 0.2s ease-in;
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
`;

/** @deprecated  */
export const ButtonIconContainer = styled.div`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translate(0, -50%);
  height: 16px;
  width: 16px;
  & svg {
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ButtonLabel = styled.p`
  text-transform: uppercase;
  display: inline-block;
`;
