import { ApolloLink, ApolloClient, InMemoryCache, from, HttpLink } from '@apollo/client';
import { GQL_HTTP_URL } from './config';

const httpLink = new HttpLink({
  uri: GQL_HTTP_URL,
  credentials: 'include',
});

const link = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-moba-proxy-gql-ops-name': operation.operationName,
    },
  }));

  return forward(operation);
});

export const oldPaymentsApolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Account: {
        keyFields: ['uid'],
      },
    },
  }),
  link: from([link, httpLink]),
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});
