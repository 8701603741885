import { css } from '@emotion/react';
import { TextBody2RRMixin } from '@mobalytics/shared';
import * as React from 'react';

interface Props {
  className?: string;
  children: React.ReactElement;
}

export const FormSubTitle: React.FC<Props> = props => {
  const { className, children } = props;
  return (
    <p className={className} css={wrapperCss}>
      {children}
    </p>
  );
};

const wrapperCss = css`
  ${TextBody2RRMixin};
  color: var(--general-grey-100);
  text-align: center;
`;
