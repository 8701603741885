import { css } from '@emotion/react';
import { Logger } from '@mobalytics/shared';
import { BraintreePayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { BraintreeTokenizePayload } from '@paypal/react-paypal-js/dist/types/types/braintree/commonsTypes';
import * as React from 'react';
import { AccountsButton } from '../../../../../../ui/buttons/accounts-button/accounts-button.component';

interface Props {
  className?: string;
  onApprove: (payload: BraintreeTokenizePayload) => void;
}

export const PaypalButton: React.FC<Props> = props => {
  const { onApprove } = props;
  const [status] = usePayPalScriptReducer();

  return (
    <>
      {/* @ts-ignore - braintree do not exist in the type but it is required for the paypal button and it takes a while to load */}
      {!status.braintreePayPalCheckoutInstance && (
        <AccountsButton analyticsButtonName="Paypal loading" loading css={loadingButtonCss}>
          Loading...
        </AccountsButton>
      )}
      <BraintreePayPalButtons
        fundingSource="paypal"
        style={styles}
        createBillingAgreement={(_data, actions) => {
          return actions.braintree.createPayment({
            flow: 'vault',
            enableShippingAddress: true,
          });
        }}
        onApprove={(data, actions) => {
          return actions.braintree.tokenizePayment(data).then(
            payload => onApprove(payload),
            (error: any) => Logger.error('[PaypalButton:onApprove] error', error)
          );
        }}
        onCancel={() => Logger.warn('[PaypalButton:onCancel] payment canceled')}
        onError={err => Logger.error('[PaypalButton:onError] payment error', err)}
      />
    </>
  );
};

const styles = {
  height: 40,
};

const loadingButtonCss = css`
  width: 100%;
`;
