import { css } from '@emotion/react';
import { TextBody2RRMixin, TextSubtitle2RBMixin, WidgetLayout } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { paymentFormHeaderCss } from '../../../../payment-details.styles';

interface Props {
  className?: string;
  last4: string;
}

export const SavedCardInfo: React.FC<Props> = props => {
  const { className, last4 } = props;
  const { t } = useTranslation();
  return (
    <div className={className}>
      <p css={paymentFormHeaderCss}>{t('Your payment details')}</p>
      <WidgetLayout>
        <p css={headerCss}>{t('Credit/debit card')}</p>
        <p css={labelCss}>**** **** **** {last4}</p>
      </WidgetLayout>
    </div>
  );
};

const headerCss = css`
  ${TextSubtitle2RBMixin};
  margin-bottom: 12px;
`;

const labelCss = css`
  ${TextBody2RRMixin};
`;
