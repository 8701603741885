import { css } from '@emotion/react';
import { TextBody2RRMixin, TextH1OBMixin } from '@mobalytics/shared';
import * as React from 'react';
import { ReactNode } from 'react';
import { MascotEmotions, mascotEmotions } from '../../../utils/formatter/images';

interface Props {
  className?: string;
  title: string;
  description: ReactNode;
  mascotEmotion: MascotEmotions;
  children?: React.ReactNode;
}

export const MascotLayout: React.FC<Props> = props => {
  const { className, children, title, description, mascotEmotion } = props;
  return (
    <div className={className} css={wrapperCss}>
      <img src={mascotEmotions(mascotEmotion)} alt="" css={mascotEmotionCss} />
      <h1 css={headerCss}>{title}</h1>
      <div css={subTitleCss}>{description}</div>
      <div css={buttonsContainerCss}>{children}</div>
    </div>
  );
};

const wrapperCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const headerCss = css`
  ${TextH1OBMixin};
  text-transform: uppercase;
  margin-bottom: 16px;
  text-align: center;
  margin-top: 0;
`;

const subTitleCss = css`
  ${TextBody2RRMixin};
  text-align: center;
  margin-bottom: 24px;
`;

const buttonsContainerCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const mascotEmotionCss = css`
  width: 128px;
  height: 128px;
`;
