import * as React from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';
import { Loader } from '@mobalytics/shared';
import { PaymentConfirmationLayout } from '../../../payment-confirmation-layout/payment-confirmation-layout.component';
import { StripeConfirmationInfo } from '../stripe-flow.types';
import { TreasuryPaymentsGame } from '../../../../../../__generated/treasury/types';
import { useStripeNewFlowPaymentConfirmation } from './stripe-new-flow-payment-confirmation.hook';

interface Props {
  className?: string;
  submitButtonLabel: string;
  confirmationInfo: StripeConfirmationInfo;
  game: TreasuryPaymentsGame;
}

export const StripeNewFlowConfirmation: React.FC<Props> = props => {
  const { submitButtonLabel, confirmationInfo, game } = props;
  const { loading, confirmPayment, error } = useStripeNewFlowPaymentConfirmation(confirmationInfo, game);
  const [paymentElementLoading, setPaymentElementLoading] = React.useState(true);

  return (
    <PaymentConfirmationLayout
      loading={loading}
      disabled={paymentElementLoading}
      buttonLabel={submitButtonLabel}
      onSubmit={confirmPayment}
      errorMessage={error}
    >
      {paymentElementLoading && <Loader />}
      <PaymentElement
        onLoaderStart={() => setPaymentElementLoading(false)}
        options={{
          wallets: {
            applePay: 'never',
            googlePay: 'never',
          },
        }}
      />
    </PaymentConfirmationLayout>
  );
};
