import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStore } from '../../../../store/app-store.hook';
import { ConnectAccountInfo } from '../../../../types/app.types';
import { FormError } from '../../../../ui/layout/form-layout';
import { FormSubTitle } from '../../../../ui/layout/form-layout/form-subtitle.component';
import { FormTitle } from '../../../../ui/layout/form-layout/form-title.component';
import { Radio } from '../../../../ui/radio/radio.component';
import { ConnectAccountBlock } from '../connect-account-block/connect-account-block.component';

const MultipleEmailsFormEmail = (props: { email: string; isActive?: boolean; onClick: (email: string) => void }) => {
  const { email, isActive, onClick } = props;
  const onClickHandler = React.useCallback(() => onClick && onClick(email), [email, onClick]);
  return <Radio id={email} label={email} isActive={isActive} onChange={onClickHandler} />;
};

export const MultipleEmailsForm = observer((props: { connectInfo: ConnectAccountInfo[] }) => {
  const { connectInfo } = props;
  const { t } = useTranslation();
  const { inputStore } = useAppStore();
  const [selectedEmail, setSelectedEmail] = React.useState<string | null>(null);
  const { generalError, emailError } = inputStore;
  const formError = generalError || emailError;
  const selectedConnect = selectedEmail ? connectInfo.find(info => info.email === selectedEmail) || null : null;

  return (
    <>
      <FormTitle>{t('ui-connect-moba-account')}</FormTitle>
      <FormSubTitle>{t('ui-connect-moba-account-multiple-description')}</FormSubTitle>
      {formError && <FormError>{t(formError)}</FormError>}
      <div>
        {connectInfo.map((info: ConnectAccountInfo, index: number) => {
          const isActive = selectedEmail === info.email;
          return (
            <MultipleEmailsFormEmail key={index} email={info.email} isActive={isActive} onClick={setSelectedEmail} />
          );
        })}
      </div>
      {selectedConnect && <ConnectAccountBlock connectInfo={selectedConnect} />}
    </>
  );
});
