import * as React from 'react';
import { PasswordResetForm } from '../../components/forms/password-reset-form/password-reset-form.component';
import { PageMeta } from '../../components/page-meta';
import { AuthPageLayout } from '../../ui/layout/auth-page-layout/auth-page-layout.component';

export const PasswordReset: React.FC = () => {
  return (
    <AuthPageLayout>
      <PageMeta title="ui-new-password" description="ui-new-password-description" />
      <PasswordResetForm />
    </AuthPageLayout>
  );
};
