import { css } from '@emotion/react';
import { isDefAndNotEmpty, NString } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { media } from '../../../ui/global/media-query';
import { TreasuryPaymentsGame, TreasuryPaymentsProvider } from '../../../__generated/treasury/types';
import { AllowedActionsT, BillingInfoT, PlanT } from '../payment.types';
import { getActivatePlanMessage } from '../utils/get-activate-plan.message';
import { usePaymentMethod } from './payment-method-provider/payment-method-provider.hook';
import { PlanDetails } from './plan-details/plan-details.component';
import { StripeExistingFlow } from './payment-flow/stripe-flow/stripe-existing-flow/stripe-existing-flow.component';
import { StripeNewFlow } from './payment-flow/stripe-flow/stripe-new-flow/stripe-new-flow.component';
import { PaymentMethodSelector } from './payment-method-selector/payment-method-selector.component';
import { PaypalFlowExisting } from './payment-flow/paypal-flow/paypal-flow-existing/paypal-flow-existing.component';
import { PaypalFlowNew } from './payment-flow/paypal-flow/paypal-flow-new/paypal-flow-new.component';

interface Props {
  className?: string;
  game: TreasuryPaymentsGame;

  selectedPlan: PlanT;
  currentPlanId: NString;
  plansWithDiscount: PlanT[] | null;
  plans: PlanT[];

  allowedActions: AllowedActionsT;
  stripePaymentMethods: BillingInfoT['stripe'];
  paypal: BillingInfoT['paypal'];
}

export const PaymentDetails: React.FC<Props> = props => {
  const {
    className,
    game,
    allowedActions,
    currentPlanId,
    plansWithDiscount,
    plans,
    selectedPlan,
    stripePaymentMethods,
    paypal,
  } = props;
  const { paymentMethod } = usePaymentMethod();
  const { t } = useTranslation();
  const { setPaymentMethod } = usePaymentMethod();

  const shouldPrepareBillingInfoFirst = selectedPlan.isTrial || (allowedActions.renew && !stripePaymentMethods?.card);
  const buttonLabel = getActivatePlanMessage(t, allowedActions, selectedPlan.planType);

  return (
    <div css={wrapperCss} className={className}>
      <div css={contentCss}>
        <div css={leftColumn}>
          {paymentMethod === TreasuryPaymentsProvider.STRIPE && (
            <>
              {stripePaymentMethods?.card ? (
                <StripeExistingFlow
                  stripeCard={stripePaymentMethods.card}
                  submitButtonLabel={buttonLabel}
                  planId={selectedPlan.id}
                  coupon={selectedPlan.appliedCoupon}
                  game={game}
                  allowedActions={allowedActions}
                />
              ) : (
                <PaymentMethodSelector
                  onChangeProvider={setPaymentMethod}
                  paymentMethod={TreasuryPaymentsProvider.STRIPE}
                >
                  <StripeNewFlow
                    allowedActions={allowedActions}
                    planId={selectedPlan.id}
                    coupon={selectedPlan.appliedCoupon}
                    game={game}
                    shouldPrepareBillingInfoFirst={shouldPrepareBillingInfoFirst}
                    submitButtonLabel={buttonLabel}
                  />
                </PaymentMethodSelector>
              )}
            </>
          )}
          {paymentMethod === TreasuryPaymentsProvider.BRAINTREE && (
            <>
              {paypal ? (
                <PaypalFlowExisting
                  planId={selectedPlan.id}
                  game={game}
                  coupon={selectedPlan.appliedCoupon}
                  allowedActions={allowedActions}
                  submitButtonLabel={buttonLabel}
                  paypal={paypal}
                  className={className}
                />
              ) : (
                <PaymentMethodSelector
                  onChangeProvider={setPaymentMethod}
                  paymentMethod={TreasuryPaymentsProvider.BRAINTREE}
                >
                  <PaypalFlowNew
                    planId={selectedPlan.id}
                    game={game}
                    coupon={selectedPlan.appliedCoupon}
                    allowedActions={allowedActions}
                    submitButtonLabel={buttonLabel}
                    className={className}
                  />
                </PaymentMethodSelector>
              )}
            </>
          )}
        </div>
        {selectedPlan && (
          <PlanDetails
            game={game}
            plan={selectedPlan}
            plans={isDefAndNotEmpty(plansWithDiscount) ? plansWithDiscount : plans}
            currentPlanId={currentPlanId}
          />
        )}
      </div>
    </div>
  );
};

const wrapperCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
`;

const contentCss = css`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 80px;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  ${media.medium} {
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
    padding-top: 24px;
    padding-bottom: 40px;
  }
`;

const leftColumn = css`
  max-width: 320px;
  flex: 1 1 0%;
  ${media.medium} {
    max-width: 400px;
    width: 100%;
  }
`;
