import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { shade, tint, transparentize } from 'polished';
import { media } from '../global/media-query';
import { placeholderInputStyle } from '../global/mixins';
import { ErrorSign } from '../icons/common/error-sign';

export const ErrorTooltip = styled.div`
  position: absolute;
  display: none;
  padding: 12px;
  max-width: 200px;
  pointer-events: none;

  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.base1};
  border: solid 1px ${({ theme }) => theme.colors.base3};
  box-shadow: 0 6px 20px 4px ${({ theme }) => transparentize(0.8, theme.colors.base1)};

  top: 50%;
  right: -2px;
  transform: translate(100%, -50%);

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: -7px;
    top: 50%;
    margin-top: -7px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid ${({ theme }) => theme.colors.base3};
  }

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: -5px;
    transform: translate(-0.5px, 0);
    top: 50%;
    margin-top: -6px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid ${({ theme }) => theme.colors.base1};
  }
  ${media.small} {
    top: -4px;
    right: 0;
    transform: translate(0, -100%);

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: unset;
      bottom: -7px;
      left: 50%;
      margin-top: unset;
      margin-left: -7px;
      border-bottom: unset;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid ${({ theme }) => theme.colors.base3};
    }

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: unset;
      bottom: -5px;
      transform: translate(0, 0.5px);
      left: 50%;
      margin-top: unset;
      margin-left: -6px;
      border-bottom: unset;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid ${({ theme }) => theme.colors.base1};
    }
  }
`;

export const ErrorSignStyled = styled(ErrorSign)`
  color: ${({ theme }) => theme.colors.accent2};
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  text-align: center;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.25px;
`;

const WrapperDisabled = css`
  opacity: 0.2;
  pointer-events: none;
`;

export const Wrapper = styled.div<{ error?: string | null; isDisabled?: boolean }>`
  display: grid;
  grid-template-areas: 'input';
  grid-template-columns: 1fr;
  align-items: center;
  position: relative;
  border-radius: 3px;
  background: ${({ theme, error }) => (!error ? theme.colors.primary : tint(0.9, theme.colors.accent2))};
  border: 1px solid ${({ theme, error }) => (!error ? theme.colors.primary : theme.colors.accent2)};
  min-width: 180px;
  padding: 8px 40px;
  box-sizing: border-box;
  transition: background-color 0.2s ease-in, border 0.2s ease-in, opacity 0.2s ease-in;
  opacity: 1;
  ${({ isDisabled }) => isDisabled && WrapperDisabled};
  &:hover {
    ${ErrorTooltip} {
      display: block;
    }
  }
  &:focus {
    ${media.small} {
      ${ErrorTooltip} {
        display: block;
      }
    }
  }
`;

export const IconWrapper = styled.div`
  color: ${({ theme }) => tint(0.3, theme.colors.base3)};
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translate(0, -50%);
  width: 20px;
  text-align: center;

  svg {
    display: block;
  }
`;

export const InputElement = styled.input<any>`
  ${({ theme }) => placeholderInputStyle(theme.colors.secondary, '14px')};
  grid-area: input;
  text-align: center;
  background: none;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  margin: 0;
  width: 100%;
  padding: 0;
  color: ${({ theme }) => shade(0.1, theme.colors.base1)};
  font-weight: 500;
`;

export const InputWrapperMixin = css`
  display: flex;
  align-items: stretch;
  border: 1px solid var(--kinky-shark);
  border-radius: 4px;
  background: var(--marine);
  height: 4rem;
  transition: border 0.3s ease;

  &:hover,
  &:focus-within {
    border-color: #ccad70;
  }
`;

export const InputMixin = css`
  width: 100%;
  padding: 1rem;
  background: transparent;
  border: none;
  outline: none;
  color: var(--general-white-100);
  text-align: left;
  ${placeholderInputStyle('var(--general-grey-300)', '14px')};
`;

export const IconContainer = styled.div``;
