import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useAppStore } from '../../../store/app-store.hook';
import { ProvidersList } from '../../../ui/providers-list';

interface ConnectProviderFormProps {
  className?: string;
}

export const ConnectProviderForm: React.FC<ConnectProviderFormProps> = observer((props: ConnectProviderFormProps) => {
  const { className } = props;
  const { authStore, inputStore } = useAppStore();
  const { isLoading, reset, termsAndServiceAgreement } = inputStore;
  const { connectProvider, authProviders } = authStore;

  const providerClickHandler = React.useCallback(
    provider => {
      connectProvider(provider);
    },
    [connectProvider]
  );

  React.useEffect(() => reset, [reset]);

  return (
    <ProvidersList
      providers={authProviders}
      isDisabled={isLoading || !termsAndServiceAgreement}
      onItemClick={providerClickHandler}
      className={className}
    />
  );
});
