import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { AccountApi } from '../../../api/account-api';
import { useAppStore } from '../../../store/app-store.hook';
import { AccountsButton } from '../../../ui/buttons/accounts-button/accounts-button.component';
import { FormError } from '../../../ui/layout/form-layout';
import { FormBody } from '../../../ui/layout/form-layout/form-body.component';
import { FormLayout } from '../../../ui/layout/form-layout/form-layout.component';
import { FormSubTitle } from '../../../ui/layout/form-layout/form-subtitle.component';
import { FormTitle } from '../../../ui/layout/form-layout/form-title.component';
import { genSignInPath } from '../../../utils/formatter/path.formatter';

type FormLoadingState = 'loading' | 'signed-in' | 'signed-out';

interface DesktopSignOutFormProps {
  className?: string;
}

export const DesktopSignOutForm: React.FC<DesktopSignOutFormProps> = observer(props => {
  const { t } = useTranslation();
  const { authStore, inputStore } = useAppStore();
  const { signOut } = authStore;
  const { isLoading, generalError } = inputStore;
  const { className } = props;
  const [formState, setFormState] = React.useState<FormLoadingState>('loading');
  const { search } = useLocation();

  React.useEffect(() => {
    AccountApi.getAuthSession()
      .then(([result]) => setFormState(result.session ? 'signed-in' : 'signed-out'))
      .catch(() => setFormState('signed-out'));
  }, [setFormState]);

  let content = null;

  if (generalError) {
    content = (
      <FormBody>
        <FormError>{t(generalError)}</FormError>
      </FormBody>
    );
  } else if (formState === 'signed-in') {
    content = (
      <FormBody>
        <FormTitle>{t('ui-desktop-signed-out')}</FormTitle>
        <FormSubTitle>{t('ui-desktop-signed-out-description')}</FormSubTitle>
        <AccountsButton analyticsButtonName="Sign out from browser" disabled={isLoading} onClick={signOut}>
          {t('ui-sign-out-from-browser')}
        </AccountsButton>
      </FormBody>
    );
  } else if (formState === 'signed-out') {
    content = (
      <FormBody>
        <FormTitle>{t('ui-you-signed-out-of-mobalytics')}</FormTitle>
        <FormSubTitle>{t('ui-you-signed-out-of-mobalytics-description')}</FormSubTitle>
        <Link to={genSignInPath(search)}>
          <AccountsButton analyticsButtonName="Sign In">{t('ui-sign-in')}</AccountsButton>
        </Link>
      </FormBody>
    );
  }

  return content ? <FormLayout className={className}>{content}</FormLayout> : null;
});
