import { css } from '@emotion/react';
import { Tag, TagSize, TagType, TextBody1RRMixin, TextSubtitle4RMMixin } from '@mobalytics/shared';
import * as React from 'react';
import { getMoneyAsString } from '../../../../utils/get-money-as-string.utils';

interface Props {
  className?: string;
  oldPrice: number;
  newPrice: number;
  currency: string;
  discount: string;
}

export const PriceWithDiscount: React.FC<Props> = props => {
  const { className, currency, discount, newPrice, oldPrice } = props;
  return (
    <div className={className} css={wrapperCss}>
      <p css={oldPriceCss}>{getMoneyAsString(currency, oldPrice)}</p>
      <div>
        <p css={newPriceCss}>{getMoneyAsString(currency, newPrice)}</p>
        <Tag type={TagType.CUSTOM} value={`-${discount}`} size={TagSize.M} css={tagCss} />
      </div>
    </div>
  );
};

const wrapperCss = css`
  ${TextBody1RRMixin};
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  text-align: right;
`;

const oldPriceCss = css`
  color: var(--general-grey-100);
  text-decoration: line-through;
`;

const newPriceCss = css`
  color: var(--general-orange-200);
  white-space: nowrap;
`;

const tagCss = css`
  ${TextSubtitle4RMMixin};
  background-color: var(--general-orange-200);
  width: 100%;
`;
