import { css } from '@emotion/react';
import { TextH4RMMixin } from '@mobalytics/shared';
import * as React from 'react';
import { FormTitle } from './form-title.component';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const FormTitleWithLink: React.FC<Props> = props => {
  const { className, children } = props;
  return (
    <FormTitle css={wrapperCss} className={className}>
      {children}
    </FormTitle>
  );
};

const wrapperCss = css`
  ${TextH4RMMixin};
  padding-left: 24px;
  position: relative;
  text-align: left;
`;
