import * as Types from '../../../../__generated/treasury/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaymentSubscriptionQueryVariables = Types.Exact<{ [key: string]: never }>;

export type PaymentSubscriptionQuery = {
  __typename: 'Query';
  treasury?: {
    __typename: 'TreasuryQuery';
    id: string;
    payments?: {
      __typename: 'TreasuryPaymentsQuery';
      customer?:
        | {
            __typename: 'TreasuryPaymentsCustomer';
            id: string;
            subscriptions: Array<
              | {
                  __typename: 'TreasuryPaymentsSubscription';
                  id: string;
                  state: Types.TreasuryPaymentsSubscriptionState;
                }
              | { __typename: 'TreasuryPaymentsUnknownError' }
            >;
          }
        | { __typename: 'TreasuryPaymentsUnauthenticatedError' }
        | { __typename: 'TreasuryPaymentsUnknownError' }
        | null;
    } | null;
  } | null;
};

export const PaymentSubscriptionQueryDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PaymentSubscriptionQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'treasury' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'TreasuryPaymentsCustomer' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subscriptions' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'filter' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'state' },
                                              value: {
                                                kind: 'ListValue',
                                                values: [
                                                  { kind: 'EnumValue', value: 'ACTIVE' },
                                                  { kind: 'EnumValue', value: 'TRIAL' },
                                                ],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'TreasuryPaymentsSubscription' },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;

/**
 * __usePaymentSubscriptionQuery__
 *
 * To run a query within a React component, call `usePaymentSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentSubscriptionQuery(
  baseOptions?: Apollo.QueryHookOptions<PaymentSubscriptionQuery, PaymentSubscriptionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentSubscriptionQuery, PaymentSubscriptionQueryVariables>(
    PaymentSubscriptionQueryDocument,
    options
  );
}
export function usePaymentSubscriptionQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentSubscriptionQuery, PaymentSubscriptionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentSubscriptionQuery, PaymentSubscriptionQueryVariables>(
    PaymentSubscriptionQueryDocument,
    options
  );
}
export type PaymentSubscriptionQueryHookResult = ReturnType<typeof usePaymentSubscriptionQuery>;
export type PaymentSubscriptionQueryLazyQueryHookResult = ReturnType<typeof usePaymentSubscriptionQueryLazyQuery>;
export type PaymentSubscriptionQueryQueryResult = Apollo.QueryResult<
  PaymentSubscriptionQuery,
  PaymentSubscriptionQueryVariables
>;
