import { formatDate } from '@mobalytics/shared';
import dayjs from 'dayjs';
import { TreasuryPaymentsPlanInterval } from '../../../__generated/treasury/types';

export const getNextPaymentDate = (interval: TreasuryPaymentsPlanInterval, count: number) => {
  const currentDate = dayjs();
  switch (interval) {
    case TreasuryPaymentsPlanInterval.DAILY:
      return formatDate(currentDate.add(count, 'days').valueOf(), 'MMMM D, YYYY');
    case TreasuryPaymentsPlanInterval.MONTHLY:
      return formatDate(currentDate.add(count, 'months').valueOf(), 'MMMM D, YYYY');
    case TreasuryPaymentsPlanInterval.YEARLY:
      return formatDate(currentDate.add(count, 'years').valueOf(), 'MMMM D, YYYY');
  }
};
