import { css } from '@emotion/react';
import { TextSubtitle1RMMixin } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getPlanDuration } from '../../../pages/payment/utils/get-plan-duration.utils';
import { TreasuryPaymentsPlanInterval } from '../../../__generated/treasury/types';

interface Props {
  className?: string;
  interval: TreasuryPaymentsPlanInterval;
  intervalCount: number;
}

export const Duration: React.FC<Props> = props => {
  const { className, interval, intervalCount } = props;
  const { t } = useTranslation();
  return (
    <p className={className} css={wrapperCss}>
      {getPlanDuration(interval, intervalCount, t)}
    </p>
  );
};

const wrapperCss = css`
  ${TextSubtitle1RMMixin};
  text-transform: capitalize;
`;
