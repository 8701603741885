import { css } from '@emotion/react';
import { TextBody2RRMixin, TextSubtitle2RBMixin } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from '../../../../../../ui/radio/radio.component';
import { PlanT } from '../../../../payment.types';
import { getMoneyAsString } from '../../../../utils/get-money-as-string.utils';
import { getIntervalName } from '../../../../utils/get-interval-name-utils';
import { getPlanDuration } from '../../../../utils/get-plan-duration.utils';

interface Props {
  className?: string;
  plan: PlanT;
  selected: boolean;
  onSelect: (plan: PlanT) => void;
}

export const PlanItem: React.FC<Props> = props => {
  const { className, plan, selected, onSelect } = props;
  const { t } = useTranslation();
  const duration = `${getPlanDuration(plan.interval, plan.intervalCount, t)} ${t('plus')}`;
  const pricePerMonth = getMoneyAsString(plan.currency, plan.finalPricePerMonth);
  const pricePerInterval = getMoneyAsString(plan.currency, plan.finalPrice);
  const intervalName = getIntervalName(plan.interval, plan.intervalCount, t);
  return (
    <div className={className} css={[wrapperCss]} onClick={() => onSelect(plan)}>
      <div>
        <div css={mainInfoCss}>
          <span css={durationCss}>{duration}</span>
          <span>{pricePerMonth}</span>
        </div>
        <div css={subInfo}>
          <span>{t('Billed {{price}} {{interval}}', { price: pricePerInterval, interval: intervalName })}</span>
        </div>
      </div>
      <Radio isActive={selected} onChange={() => onSelect(plan)} id={plan.id} label="" />
    </div>
  );
};

const wrapperCss = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;
  &:hover {
    border-color: var(--general-gold-100);
  }
  &:first-child {
    border-radius: 6px 6px 0 0;
  }
  &:last-child {
    border-radius: 0 0 6px 6px;
  }
`;

const mainInfoCss = css`
  ${TextSubtitle2RBMixin};
  margin-bottom: 4px;
  display: flex;
  gap: 8px;
`;

const durationCss = css`
  text-transform: capitalize;
`;

const subInfo = css`
  ${TextBody2RRMixin};
  color: var(--general-grey-100);
`;
