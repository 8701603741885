import * as i18next from 'i18next';
import { Logger, Nullable } from '@mobalytics/shared';
import { TreasuryPaymentsProduct } from '../../../__generated/treasury/types';
import { AllowedActionsT } from '../payment.types';

export const getActivatePlanMessage = (
  t: i18next.TFunction,
  allowedActions: AllowedActionsT,
  planType: Nullable<TreasuryPaymentsProduct>
) => {
  if (allowedActions.create) {
    return planType === TreasuryPaymentsProduct.AD_FREE ? t('Activate ad-free') : t('Activate plus');
  }
  if (allowedActions.renew) {
    return t('Renew plan');
  }
  if (allowedActions.change) {
    return t('Change plan');
  }
  Logger.error('[getActivatePlanMessage]: no allowed actions');
  return '';
};
