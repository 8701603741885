import { figmaIcon, Input } from '@mobalytics/shared';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStore } from '../../../store/app-store.hook';
import { AccountsButton } from '../../../ui/buttons/accounts-button/accounts-button.component';
import { FormError } from '../../../ui/layout/form-layout';
import { FormBody } from '../../../ui/layout/form-layout/form-body.component';
import { FormLayout } from '../../../ui/layout/form-layout/form-layout.component';
import { FormSubTitle } from '../../../ui/layout/form-layout/form-subtitle.component';
import { FormTitle } from '../../../ui/layout/form-layout/form-title.component';

interface PasswordResetFormProps {
  className?: string;
}

export const PasswordResetForm: React.FC<PasswordResetFormProps> = observer((props: PasswordResetFormProps) => {
  const { className } = props;
  const { t } = useTranslation();
  const { inputStore, authStore } = useAppStore();
  const { isLoading, generalError, reset } = inputStore;
  const { password, passwordError, onChangePassword } = inputStore;
  const { passwordConfirmation, passwordConfirmationError, onChangePasswordConfirmation } = inputStore;
  const { tokenError, setTokenFromUrl } = inputStore;
  const { resetPassword } = authStore;
  const formError = generalError || tokenError;

  const submitHandler = React.useCallback(
    (e: React.FormEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      resetPassword();
    },
    [resetPassword]
  );

  React.useEffect(() => {
    setTokenFromUrl();
    return reset;
  }, [reset, setTokenFromUrl]);

  return (
    <FormLayout className={className}>
      <FormTitle>{t('ui-new-password')}</FormTitle>
      <FormSubTitle>{t('ui-new-password-description')}</FormSubTitle>
      {formError && <FormError>{t(formError)}</FormError>}
      <FormBody onSubmit={submitHandler}>
        <Input
          id="password"
          type="password"
          iconUrl={figmaIcon('system-icons', '24-locked')}
          value={password}
          placeholder={t('ui-password')}
          onChange={onChangePassword}
          errorMessage={passwordError ? t(passwordError) : undefined}
          autoComplete="current-password"
          name="current-password"
          disabled={isLoading}
        />
        <Input
          id={'passwordConfirmation'}
          type="password"
          iconUrl={figmaIcon('system-icons', '24-locked')}
          value={passwordConfirmation}
          placeholder={t('ui-confirm-new-password')}
          onChange={onChangePasswordConfirmation}
          errorMessage={passwordConfirmationError ? t(passwordConfirmationError) : undefined}
          autoComplete="new-password"
          name="confirm-new-password"
          disabled={isLoading}
        />
        <AccountsButton
          analyticsButtonName="Setup password"
          type="submit"
          disabled={!!(passwordError || tokenError || isLoading)}
        >
          {t('ui-setup-password')}
        </AccountsButton>
      </FormBody>
    </FormLayout>
  );
});
