import { gql } from '@apollo/client';
import { AccountFragment } from '../fragments/account-fragment.gql';

export const AccountQueryGql = gql`
  query AccountQuery {
    account {
      ...AccountFragment
    }
  }
  ${AccountFragment}
`;
