import * as React from 'react';
import { css } from '@emotion/react';
import { NString } from '@mobalytics/shared';
import { useTranslation } from 'react-i18next';
import { contentLoadingCss } from '../../payment.styles';
import { paymentFormHeaderCss } from '../payment-details.styles';
import { AccountsButton } from '../../../../ui/buttons/accounts-button/accounts-button.component';
import { ErrorMessage } from '../../../../ui/error-message/error-message.component';
import { SecurePayments } from '../../secure-payments/secure-payments.component';

interface Props {
  className?: string;
  header?: string;
  loading: boolean;
  disabled?: boolean;
  buttonLabel: string;
  onSubmit: () => void;
  children: React.ReactNode;
  errorMessage: NString;
}

export const PaymentConfirmationLayout = (props: Props) => {
  const { className, loading, header, buttonLabel, errorMessage, disabled, onSubmit, children } = props;
  const { t } = useTranslation();
  return (
    <div className={className}>
      <div css={loading && contentLoadingCss}>
        {header && <p css={paymentFormHeaderCss}>{header}</p>}
        {children}
      </div>
      <AccountsButton
        analyticsButtonName="Purchase"
        type="submit"
        css={buttonCss}
        loading={loading}
        disabled={disabled}
        onClick={onSubmit}
      >
        {buttonLabel}
      </AccountsButton>
      {errorMessage && <ErrorMessage css={ErrorMessageCss}>{t(errorMessage)}</ErrorMessage>}
      <SecurePayments />
    </div>
  );
};

const buttonCss = css`
  width: 100%;
  margin-top: 24px;
`;

const ErrorMessageCss = css`
  margin-top: 24px;
`;
