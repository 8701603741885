import * as React from 'react';
import pRetry from 'p-retry';
import { firstItem, Logger, Nullable, processUnionEntry } from '@mobalytics/shared';
import { useHistory, useLocation } from 'react-router';
import { AccountLevel } from '../../../__generated/accounts/types';
import { CustomerT, SubscriptionT } from '../payment.types';
import { TreasuryPaymentsProduct } from '../../../__generated/treasury/types';
import { genPaymentErrorPath, genRedirectUrl } from '../../../utils/formatter/path.formatter';
import { useAccountQueryLazyQuery } from '../../../api/accounts/query/__generated/account-query.gql.generated';
import { oldPaymentsApolloClient } from '../../../old-payments-apollo-client';
import { usePaymentSubscriptionQueryLazyQuery } from '../_gql/__generated/payment-subscription.query.gql.generated';
import { treasuryApolloClient } from '../../../treasury-apollo-client';
import { PaymentError } from '../payment-error.types';

export const useThankYou = () => {
  const { search } = useLocation();
  const { replace } = useHistory();
  const [fetchAccount] = useAccountQueryLazyQuery({ client: oldPaymentsApolloClient });
  const [loading, setLoading] = React.useState(true);
  const [isSurveyOpen, setIsSurveyOpen] = React.useState(false);
  const [wasSurveyClosed, setWasSurveyClosed] = React.useState(false);

  const [fetchSubscriptions] = usePaymentSubscriptionQueryLazyQuery({
    client: treasuryApolloClient,
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {},
  });

  const fetchAccountWithRetry = React.useCallback(async () => {
    return pRetry(
      async () => {
        const { data } = await fetchAccount({
          fetchPolicy: 'no-cache',
        });
        const level = data?.account?.level;
        if (level && [AccountLevel.Regular, AccountLevel.Churn].includes(level)) {
          throw new Error(level);
        }
      },
      {
        retries: 7,
        onFailedAttempt: error => {
          Logger.warn(`[fetchAccountWithRetry]: failed attempt: ${error.message} retries left: ${error.retriesLeft}`);
          if (error.retriesLeft === 0) {
            Logger.error(`[fetchAccountWithRetry]: failed attempt: ${error.message}`);
            replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
          }
        },
      }
    );
  }, [fetchAccount, replace, search]);

  const validateSubscriptionWithRetry = React.useCallback(async () => {
    return pRetry(
      async () => {
        const { data } = await fetchSubscriptions({});
        const customer = processUnionEntry<CustomerT>(data?.treasury?.payments?.customer, 'TreasuryPaymentsCustomer');

        const subscription = processUnionEntry<SubscriptionT>(
          firstItem(customer?.subscriptions),
          'TreasuryPaymentsSubscription'
        );

        if (!subscription) {
          throw new Error('[validateSubscriptionWithRetry]: No active or trial subscription');
        }
      },
      {
        retries: 7,
        onFailedAttempt: error => {
          Logger.warn(
            `[validateSubscriptionWithRetry]: failed attempt: ${error.message} retries left: ${error.retriesLeft}`
          );
          if (error.retriesLeft === 0) {
            Logger.error(`[validateSubscriptionWithRetry]: failed attempt: ${error.message}`);
            replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
          }
        },
      }
    );
  }, [fetchSubscriptions, replace, search]);

  const checkStatus = React.useCallback(async () => {
    try {
      setLoading(true);
      await validateSubscriptionWithRetry();
      const { data } = await fetchAccount({
        fetchPolicy: 'no-cache',
      });
      if (data?.account?.level && [AccountLevel.Regular, AccountLevel.Churn].includes(data?.account.level)) {
        await fetchAccountWithRetry();
      }
    } catch (e) {
      Logger.error('[usePaymentFlow]:[handleRetries]: unknown error', e);
      replace(genPaymentErrorPath(search, PaymentError.INTERNAL_ERROR));
    } finally {
      setLoading(false);
    }
  }, [fetchAccount, fetchAccountWithRetry, replace, search, validateSubscriptionWithRetry]);

  const backToApp = () => {
    setIsSurveyOpen(false);
    window.location.href = genRedirectUrl();
    setWasSurveyClosed(true);
  };

  const onButtonClick = (planType: Nullable<TreasuryPaymentsProduct>) => {
    if (planType === TreasuryPaymentsProduct.PLUS) {
      setIsSurveyOpen(true);
    } else {
      window.location.href = genRedirectUrl();
    }
  };

  return {
    loading,
    isSurveyOpen,
    wasSurveyClosed,
    checkStatus,
    onButtonClick,
    backToApp,
  };
};
