import styled from '@emotion/styled';
import * as React from 'react';
import { Region } from '@mobalytics/shared';

interface Props {
  summonerName: string;
  region: Region;
  imageSrc: string;
  aka: string;
  onProSummonerClick: (summonerName: string, region: Region) => void;
}

export const ProSummonerCard: React.FC<Props> = props => {
  const { summonerName, region, onProSummonerClick, imageSrc, aka } = props;

  const onClick = React.useCallback(() => {
    onProSummonerClick(summonerName, region);
  }, [onProSummonerClick, summonerName, region]);

  return (
    <Wrapper onClick={onClick}>
      <ProPlayerImg src={imageSrc} />
      <ProPlayerName>{summonerName}</ProPlayerName>
      <ProPlayerAkaName>
        {aka} ({region})
      </ProPlayerAkaName>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  width: 260px;
  height: 260px;
  opacity: 0.8;
  border-radius: 12px;
  border: solid 1px ${({ theme }) => theme.colors.base4};

  background-image: linear-gradient(
    to bottom,
    ${({ theme }) => theme.colors.base6},
    ${({ theme }) => theme.colors.base7}
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: transform ease 0.2s;

  &:hover {
    border-color: ${({ theme }) => theme.colors.action};
    transform: scale(1.03);
  }
`;

export const ProPlayerImg = styled.img`
  display: block;
  width: 140px;
  height: 160px;
`;

export const ProPlayerName = styled.div`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.colors.primary};
  margin: 8px auto;
`;

export const ProPlayerAkaName = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.colors.accent3};
`;
