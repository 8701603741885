import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getIntervalName } from '../../../pages/payment/utils/get-interval-name-utils';
import { getMoneyAsString } from '../../../pages/payment/utils/get-money-as-string.utils';
import { TreasuryPaymentsPlanInterval } from '../../../__generated/treasury/types';

interface Props {
  className?: string;
  finalPrice: number;
  currency: string;
  interval: TreasuryPaymentsPlanInterval;
  intervalCount: number;
}

export const Price: React.FC<Props> = props => {
  const { className, finalPrice, currency, intervalCount, interval } = props;
  const { t } = useTranslation();
  const pricePerInterval =
    interval === TreasuryPaymentsPlanInterval.MONTHLY && intervalCount === 1
      ? ''
      : getMoneyAsString(currency, finalPrice);
  const intervalName = getIntervalName(interval, intervalCount, t);

  return (
    <span className={className}>
      {t('Billed {{price}} {{interval}}', { price: pricePerInterval, interval: intervalName })}
    </span>
  );
};
