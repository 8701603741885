import React from 'react';
import { css } from '@emotion/react';

interface Props {
  className?: string;
  children: React.ReactNode;
}
export const ModalBody = (props: Props) => {
  const { children, className } = props;
  return (
    <div className={className} css={modalBodyCss}>
      {children}
    </div>
  );
};

const modalBodyCss = css`
  padding: 32px 40px;
`;
