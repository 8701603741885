import { css } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import * as queryString from 'query-string';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NString } from '@mobalytics/shared';
import { AccountApi } from '../../../api/account-api';
import { useAppStore } from '../../../store/app-store.hook';
import { AccountsAppPath } from '../../../types/app.types';
import { AccountsButton } from '../../../ui/buttons/accounts-button/accounts-button.component';
import { FormError } from '../../../ui/layout/form-layout';
import { FormBody } from '../../../ui/layout/form-layout/form-body.component';
import { FormFooter } from '../../../ui/layout/form-layout/form-footer.component';
import { FormLayout } from '../../../ui/layout/form-layout/form-layout.component';
import { formLinkButtonCss } from '../../../ui/layout/form-layout/form-link-button.component';
import { FormSubTitle } from '../../../ui/layout/form-layout/form-subtitle.component';
import { FormTitle } from '../../../ui/layout/form-layout/form-title.component';

interface DesktopSignInFormState {
  userName?: string;
  redirectUri: string;
}

interface DesktopSignInFormProps {
  className?: string;
}

export const DesktopSignInForm: React.FC<DesktopSignInFormProps> = observer(props => {
  const { className } = props;
  const { t } = useTranslation();
  const { routerStore, authStore, inputStore } = useAppStore();
  const { signOut } = authStore;
  const { isLoading, generalError } = inputStore;
  const [state, setState] = React.useState<DesktopSignInFormState | null>(null);
  const userName = state ? state.userName : null;
  const redirectUrl = state ? state.redirectUri : null;

  React.useEffect(() => {
    AccountApi.fetchAccount()
      .then(response => {
        const query = queryString.parse(window.location.search) as { redirect_uri: NString };
        const account = response[0].account;
        if (account && query.redirect_uri) {
          setState({ userName: account.login, redirectUri: query.redirect_uri });
        } else {
          routerStore.history.replace({ pathname: AccountsAppPath.SIGN_IN, search: routerStore.location.search });
        }
      })
      .catch(() =>
        routerStore.history.replace({ pathname: AccountsAppPath.SIGN_IN, search: routerStore.location.search })
      );
  }, [setState, routerStore]);

  const onContinueClick = React.useCallback(() => {
    routerStore.history.push({
      pathname: AccountsAppPath.DESKTOP_SIGN_IN_REDIRECT,
      search: routerStore.location.search,
    });
  }, [routerStore]);

  if (userName && redirectUrl) {
    return (
      <FormLayout className={className}>
        <FormTitle>{t('ui-desktop-sign-in')}</FormTitle>
        <FormSubTitle>
          <>
            {t('ui-desktop-sign-in-description')}
            <p css={userNameCss}>{userName}</p>
            {t('ui-to-proceed-press-continue')}
          </>
        </FormSubTitle>
        {generalError && <FormError>{t(generalError)}</FormError>}
        <FormBody>
          <AccountsButton analyticsButtonName="Continue" type="submit" loading={isLoading} onClick={onContinueClick}>
            {t('ui-continue')}
          </AccountsButton>
        </FormBody>
        <FormFooter>
          <p>
            {t('ui-want-to-use-another-account')}
            <span onClick={signOut} css={formLinkButtonCss}>
              {t('ui-sign-out')}
            </span>
          </p>
        </FormFooter>
      </FormLayout>
    );
  }
  return null;
});

export const userNameCss = css`
  font-size: 14px;
  font-weight: 800;
  color: var(--general-white-100);
  margin: 12px 0;
`;
