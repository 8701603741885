import * as React from 'react';

export const Cross: React.FC<React.BaseHTMLAttributes<SVGElement>> = props => {
  const path =
    'M8.234 6.392l3.385 3.384c.559.559.503 1.501-.168 1.984-.527.38-1.266.268-1.724-.19L6.392 8.233a.' +
    '555.555 0 0 0-.783 0l-3.385 3.385c-.56.559-1.501.503-1.985-.168-.379-.526-.267-1.266.192-1.724l3.335-3.335a.' +
    '553.553 0 0 0 0-.783L.382 2.224A1.303 1.303 0 0 1 .549.24c.527-.379 1.266-.267 1.725.192L5.61 3.766a.553.553 ' +
    '0 0 0 .783 0L9.776.382a1.303 1.303 0 0 1 1.984.167c.38.527.268 1.266-.19 1.725L8.233 5.61a.555.555 0 0 0 0 .783';
  return (
    <svg width="12px" height="12px" viewBox="0 0 12 12" fill="currentColor" {...props}>
      <path d={path} />
    </svg>
  );
};
