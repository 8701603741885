import { figmaIcon, Input } from '@mobalytics/shared';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStore } from '../../../../store/app-store.hook';
import { ConnectAccountInfo } from '../../../../types/app.types';
import { AccountsButton } from '../../../../ui/buttons/accounts-button/accounts-button.component';
import { FormBody } from '../../../../ui/layout/form-layout/form-body.component';
import { FormControlls } from '../../../../ui/layout/form-layout/form-controlls.component';
import { FormSeparator } from '../../../../ui/layout/form-layout/form-separator.component';
import { ProvidersList } from '../../../../ui/providers-list';
import { parseAppQueryParams } from '../../../../utils/url';
import { Provider } from '../../../../types/provider';

interface ConnectAccountBlockProps {
  connectInfo: ConnectAccountInfo;
  className?: string;
}

/* todo: cleanup component */
export const ConnectAccountBlock: React.FC<ConnectAccountBlockProps> = observer((props: ConnectAccountBlockProps) => {
  const { t } = useTranslation();
  const { inputStore, authStore } = useAppStore();
  const { connectInfo } = props;
  const { email, providers } = connectInfo;
  const { password, passwordError, onChangePassword } = inputStore;
  const { isLoading } = inputStore;
  const { connectMoba, connectProvider } = authStore;

  const continueForm = React.useMemo<string>(() => {
    const queryParams = parseAppQueryParams();
    return queryParams.continue_from || '';
  }, []);

  const submitHandler = React.useCallback(
    (e: React.FormEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      connectMoba(email, continueForm);
    },
    [email, continueForm, connectMoba]
  );

  const providerHandler = React.useCallback((provider: Provider) => connectProvider(provider, continueForm), [
    continueForm,
    connectProvider,
  ]);

  return (
    <>
      {providers && (
        <>
          <ProvidersList providers={providers} isDisabled={isLoading} onItemClick={providerHandler} />
          <FormSeparator>{t('ui-or')}</FormSeparator>
        </>
      )}
      <FormBody onSubmit={submitHandler}>
        <FormControlls>
          <Input
            id="password"
            type="password"
            iconUrl={figmaIcon('system-icons', '24-locked')}
            value={password}
            placeholder={t('ui-password')}
            onChange={onChangePassword}
            errorMessage={passwordError ? t(passwordError) : undefined}
            autoComplete="current-password"
            name="current-password"
            disabled={isLoading}
          />
        </FormControlls>
        <AccountsButton analyticsButtonName="Sign In" type="submit" disabled={!!passwordError} loading={isLoading}>
          {t('ui-log-in')}
        </AccountsButton>
      </FormBody>
    </>
  );
});
