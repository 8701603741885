import { ThemeProvider } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { hotjar } from 'react-hotjar';
import { HOTJAR_CONFIG } from './config';
import { useAppStore } from './store/app-store.hook';
import { Theme as DefaultAppTheme } from './themes/default-theme';
import { Theme as LolAppTheme } from './themes/lol-theme';
import { Theme as LostArkAppTheme } from './themes/lost-ark-theme';
import { Theme as TftAppTheme } from './themes/tft-theme';
import { Theme as ValorantAppTheme } from './themes/valorant-theme';
import { Theme as Destiny2AppTheme } from './themes/destiny-2-theme';
import { Theme as Diablo4AppTheme } from './themes/diablo-4-theme';
import { Theme as DeadlockAppTheme } from './themes/deadlock-theme';
import { DefaultTheme, LolTheme, LostArkTheme, ValorantTheme } from './ui/global/theme';
import { Theme } from './types/theme';

export const App: React.FC = observer(() => {
  React.useEffect(() => {
    hotjar.initialize(HOTJAR_CONFIG.hjid, HOTJAR_CONFIG.hjsv, true);
  }, []);

  const { configStore } = useAppStore();
  const { theme } = configStore;

  switch (theme) {
    case Theme.Lol:
      return (
        <ThemeProvider theme={LolTheme}>
          <LolAppTheme />
        </ThemeProvider>
      );
    case Theme.Tft:
      return (
        <ThemeProvider theme={LolTheme}>
          <TftAppTheme />
        </ThemeProvider>
      );
    case Theme.Valorant:
      return (
        <ThemeProvider theme={ValorantTheme}>
          <ValorantAppTheme />
        </ThemeProvider>
      );
    case Theme.LostArk:
      return (
        <ThemeProvider theme={LostArkTheme}>
          <LostArkAppTheme />
        </ThemeProvider>
      );
    case Theme.Destiny2:
      return (
        <ThemeProvider theme={DefaultTheme}>
          <Destiny2AppTheme />
        </ThemeProvider>
      );
    case Theme.Diablo4:
      return (
        <ThemeProvider theme={DefaultTheme}>
          <Diablo4AppTheme />
        </ThemeProvider>
      );
    case Theme.Deadlock:
      return (
        <ThemeProvider theme={DefaultTheme}>
          <DeadlockAppTheme />
        </ThemeProvider>
      );
    default:
    case Theme.Default:
      return (
        <ThemeProvider theme={DefaultTheme}>
          <DefaultAppTheme />
        </ThemeProvider>
      );
  }
});
