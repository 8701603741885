import { figmaIcon, Input, Link } from '@mobalytics/shared';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAppStore } from '../../../store/app-store.hook';
import { AccountsButton } from '../../../ui/buttons/accounts-button/accounts-button.component';
import { FormError } from '../../../ui/layout/form-layout';
import { FormBody } from '../../../ui/layout/form-layout/form-body.component';
import { FormControlls } from '../../../ui/layout/form-layout/form-controlls.component';
import { FormFooter } from '../../../ui/layout/form-layout/form-footer.component';
import { FormLayout } from '../../../ui/layout/form-layout/form-layout.component';
import { formLinkButtonCss } from '../../../ui/layout/form-layout/form-link-button.component';
import { FormSubTitle } from '../../../ui/layout/form-layout/form-subtitle.component';
import { FormTitleLink } from '../../../ui/layout/form-layout/form-title-link.component';
import { FormTitleWithLink } from '../../../ui/layout/form-layout/form-title-with-link.component';
import { genSignInPath, genSupportUrl } from '../../../utils/formatter/path.formatter';

interface RequestPasswordResetFormProps {
  className?: string;
}

export const RequestPasswordResetForm: React.FC<RequestPasswordResetFormProps> = observer(
  (props: RequestPasswordResetFormProps) => {
    const { className } = props;
    const { t } = useTranslation();
    const { search } = useLocation();
    const { inputStore, authStore } = useAppStore();
    const { email, emailError, onChangeEmail } = inputStore;
    const { isLoading, generalError, reset } = inputStore;
    const { requestPasswordReset } = authStore;

    const submitHandler = React.useCallback(
      (e: React.FormEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        requestPasswordReset();
      },
      [requestPasswordReset]
    );

    React.useEffect(() => reset, [reset]);

    return (
      <FormLayout className={className}>
        <FormTitleWithLink>
          <FormTitleLink to={genSignInPath(search)}>{t('Sign in')}</FormTitleLink>
          {t('ui-reset-password')}
        </FormTitleWithLink>
        <FormSubTitle>{t('ui-reset-password-description')}</FormSubTitle>
        {generalError && <FormError>{t(generalError)}</FormError>}
        <FormBody onSubmit={submitHandler}>
          <FormControlls>
            <Input
              id="email"
              value={email}
              placeholder={t('Email')}
              onChange={onChangeEmail}
              iconUrl={figmaIcon('system-icons', 'email')}
              errorMessage={emailError ? t(emailError) : undefined}
              autoComplete="current-email"
              name="current-email"
              disabled={isLoading}
            />
          </FormControlls>
          <AccountsButton analyticsButtonName="Send email" disabled={!!emailError} type="submit" loading={isLoading}>
            {t('send')}
          </AccountsButton>
        </FormBody>
        <FormFooter>
          <p>
            {t('Link is not arriving?')}
            <Link to={genSupportUrl()} css={formLinkButtonCss} className={className} isExternal={true}>
              {t('Contact support')}
            </Link>
          </p>
        </FormFooter>
      </FormLayout>
    );
  }
);
