import { css } from '@emotion/react';
import * as React from 'react';
import { Provider } from '../../types/provider';
import { ProvidersListItem } from './providers-list-item/providers-list-item.component';

interface ProvidersListProps {
  providers: Provider[];
  isDisabled?: boolean;
  className?: string;
  onItemClick: (provider: Provider) => void;
}

export const ProvidersList: React.FC<ProvidersListProps> = function(props: ProvidersListProps) {
  const { providers, isDisabled, onItemClick, className } = props;
  return (
    <div css={[wrapperCss, isDisabled && wrapperDisabledCss]} className={className}>
      {providers.map((provider: Provider) => {
        return <ProvidersListItem key={provider} provider={provider} onClick={onItemClick} />;
      })}
    </div>
  );
};

export const wrapperCss = css`
  display: flex;
  gap: 8px;
  transition: opacity 0.2s ease-in;
`;

const wrapperDisabledCss = css`
  opacity: 0.2;
  pointer-events: none;
`;
