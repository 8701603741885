import { css } from '@emotion/react';
import { VBoxMixin } from '@mobalytics/shared';
import * as React from 'react';

interface Props extends React.FormHTMLAttributes<HTMLFormElement> {
  className?: string;
  children: React.ReactNode;
}

export const FormBody: React.FC<Props> = props => {
  const { className, children, ...rest } = props;
  return (
    <form css={wrapperCss} className={className} {...rest}>
      {children}
    </form>
  );
};

const wrapperCss = css`
  ${VBoxMixin};
  gap: 20px;

  & button {
    width: 100%;
  }
`;
