import { observer } from 'mobx-react-lite';
import * as React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useAppStore } from '../../store/app-store.hook';
import { buildAbsoluteUrl } from '../../utils/url';

interface PageMetaProps {
  title: string;
  description: string;
}

export const PageMeta: React.FC<PageMetaProps> = observer((props: PageMetaProps) => {
  const { title, description } = props;
  const { t } = useTranslation();
  const { configStore, i18nStore } = useAppStore();
  if (configStore.config) {
    const { gameName, wallpaper } = configStore.config;
    // page title
    const pageTitle = (gameName ? [t(title), t(gameName), t('ui-mobalytics')] : [t(title), t('ui-mobalytics')]).join(
      ' | '
    );

    // base meta
    const meta = [
      { name: 'description', content: description },
      { property: 'og:title', content: pageTitle },
      { property: 'og:description', content: description },
      { property: 'og:type', content: 'website' },
      { property: 'og:site_name', content: t('ui-mobalytics') },
      { property: 'og:locale', content: i18nStore.language },
    ];

    // og:image
    if (wallpaper) {
      const ogImage = buildAbsoluteUrl(wallpaper);
      meta.push({ property: 'og:image', content: ogImage });
    }

    return <Helmet htmlAttributes={{ lang: i18nStore.language }} title={pageTitle} meta={meta} />;
  }
  return null;
});
