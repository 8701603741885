import { css } from '@emotion/react';
import * as React from 'react';
import { PlanT } from '../../../pages/payment/payment.types';
import { getPricePerMonthType } from '../../../pages/payment/payment.utils';
import { PriceWithDiscount } from '../price-with-discount/price-with-discount.component';
import { Price } from '../price/price.component';

interface Props {
  className?: string;
  plan: PlanT;
}

export const PriceDetailsController: React.FC<Props> = props => {
  const { className, plan } = props;
  const pricePerMonthType = getPricePerMonthType(plan);

  if (pricePerMonthType === 'discount') {
    return (
      <PriceWithDiscount
        basePrice={plan.basePrice}
        finalPrice={plan.finalPrice}
        intervalCount={plan.intervalCount}
        interval={plan.interval}
        currency={plan.currency}
        className={className}
        css={plan.bestChoiceLabel && wrapperBestChoiceCss}
      />
    );
  }

  return (
    <Price
      finalPrice={plan.finalPrice}
      currency={plan.currency}
      interval={plan.interval}
      intervalCount={plan.intervalCount}
      className={className}
    />
  );
};

const wrapperBestChoiceCss = css`
  color: var(--general-orange-200);
`;
