import i18next from 'i18next';
import { TreasuryPaymentsGame } from '../../../../../__generated/treasury/types';

export const getPromoRewardMessageByGame = (game: TreasuryPaymentsGame, t: i18next.TFunction) => {
  switch (game) {
    case TreasuryPaymentsGame.LOL:
      return t('Get a free champion skin');
    case TreasuryPaymentsGame.TFT:
      return t('Get a free random Little Legend');
    default:
      return null;
  }
};
