import { Logger } from '@mobalytics/shared';
import i18next from 'i18next';
import { action, observable } from 'mobx';
import { RouterStore } from 'mobx-react-router';
import { SUPPORTED_LANGUAGES } from '../constants';
import { getAppQueryParams } from '../utils/url';
import { AuthStore } from './auth-store';
import { ConfigStore } from './config-store';
import { InputStore } from './input-store';
import { TestimonialStore } from './testimonial-store';
import { UserSettingsStore } from './user-settings-store';

export class AppStore {
  @observable isInitialLangLoaded: boolean = false;

  authStore: AuthStore;
  userSettingsStore: UserSettingsStore;
  i18nStore: i18next.i18n;
  routerStore: RouterStore;
  inputStore: InputStore;
  configStore: ConfigStore;
  testimonialsStore: TestimonialStore;

  constructor(i18n: i18next.i18n, routerStore: RouterStore) {
    // query params
    const { redirectUri, theme, marketing, mobaReferrerId } = getAppQueryParams();
    // stores
    this.i18nStore = i18n;
    this.routerStore = routerStore;
    this.inputStore = new InputStore();
    this.configStore = new ConfigStore(theme);
    this.testimonialsStore = new TestimonialStore(this.configStore);
    this.userSettingsStore = new UserSettingsStore();
    this.authStore = new AuthStore(
      this.inputStore,
      this.routerStore,
      this.configStore,
      this.i18nStore,
      this.userSettingsStore,
      redirectUri,
      theme,
      marketing,
      mobaReferrerId
    );
  }

  @action
  setIsInitialLangLoaded = (value: boolean) => {
    this.isInitialLangLoaded = value;
  };

  changeLanguage = (lang: string) => {
    if (SUPPORTED_LANGUAGES.indexOf(lang) !== -1) {
      this.i18nStore.changeLanguage(lang).catch(error => Logger.error('Unable to change language: ', error));
    } else {
      Logger.error('Unsupported language: ', lang);
    }
  };
}
