import * as Types from '../../../../__generated/treasury/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBillingInfoMutationVariables = Types.Exact<{
  updateBillingInfoInput: Types.TreasuryPaymentsUpdateBillingInfoInput;
}>;

export type UpdateBillingInfoMutation = {
  __typename: 'Mutation';
  treasury?: {
    __typename: 'TreasuryMutation';
    id: string;
    payments?: {
      __typename: 'TreasuryPaymentsMutation';
      updateBillingInfo?: {
        __typename: 'TreasuryPaymentsUpdateBillingInfoPayload';
        setupIntentSecret?: string | null;
        error?:
          | { __typename: 'TreasuryPaymentsBadRequestError'; message?: string | null }
          | {
              __typename: 'TreasuryPaymentsPaymentError';
              code: Types.TreasuryPaymentsPaymentErrorCode;
              message: string;
            }
          | { __typename: 'TreasuryPaymentsUnauthenticatedError'; message?: string | null }
          | { __typename: 'TreasuryPaymentsUnknownError'; message?: string | null }
          | null;
      } | null;
    } | null;
  } | null;
};

export const UpdateBillingInfoMutationDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBillingInfoMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updateBillingInfoInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TreasuryPaymentsUpdateBillingInfoInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'treasury' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateBillingInfo' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'input' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'updateBillingInfoInput' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'error' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'TreasuryPaymentsPaymentError' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'code' } }],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'setupIntentSecret' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
export type UpdateBillingInfoMutationMutationFn = Apollo.MutationFunction<
  UpdateBillingInfoMutation,
  UpdateBillingInfoMutationVariables
>;

/**
 * __useUpdateBillingInfoMutation__
 *
 * To run a mutation, you first call `useUpdateBillingInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingInfoMutation, { data, loading, error }] = useUpdateBillingInfoMutation({
 *   variables: {
 *      updateBillingInfoInput: // value for 'updateBillingInfoInput'
 *   },
 * });
 */
export function useUpdateBillingInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBillingInfoMutation, UpdateBillingInfoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBillingInfoMutation, UpdateBillingInfoMutationVariables>(
    UpdateBillingInfoMutationDocument,
    options
  );
}
export type UpdateBillingInfoMutationHookResult = ReturnType<typeof useUpdateBillingInfoMutation>;
export type UpdateBillingInfoMutationMutationResult = Apollo.MutationResult<UpdateBillingInfoMutation>;
export type UpdateBillingInfoMutationMutationOptions = Apollo.BaseMutationOptions<
  UpdateBillingInfoMutation,
  UpdateBillingInfoMutationVariables
>;
