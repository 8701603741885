import { validateStrEnumValue } from '@mobalytics/shared';
import { useRouteMatch } from 'react-router';
import { PaymentSection } from '../../../types/payment.types';
import { parseAppQueryParams } from '../../../utils/url';
import { mapThemeToTreasuryGame } from '../../../utils/mapping/map-theme-to-treasury-game.utils';
import { Theme } from '../../../types/theme';

export const usePaymentFilters = () => {
  const { params } = useRouteMatch<{ planId?: string; section: string }>();
  const { planId } = params;
  const queryParams = parseAppQueryParams();
  const theme = validateStrEnumValue<Theme>(Theme, queryParams.theme);
  const game = mapThemeToTreasuryGame(theme);
  const section = validateStrEnumValue<PaymentSection>(PaymentSection, params.section);
  const couponQueryParam = queryParams.coupon;
  return {
    planId,
    game,
    section,
    couponQueryParam,
  };
};
