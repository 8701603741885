import { NString } from '@mobalytics/shared';

export const getCurrencySymbol = (currency: NString): string => {
  switch (currency) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    default:
      return '';
  }
};
