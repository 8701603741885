import { ApolloLink, ApolloClient, InMemoryCache, from, HttpLink } from '@apollo/client';
import { TREASURY_HTTP_URL } from './config';

const httpLink = new HttpLink({
  uri: TREASURY_HTTP_URL,
  credentials: 'include',
});

const link = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-moba-proxy-gql-ops-name': operation.operationName,
    },
  }));

  return forward(operation);
});

export const treasuryApolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      TreasuryPaymentsQuery: {
        merge: false,
      },
    },
  }),
  link: from([link, httpLink]),
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});

// @ts-ignore
window.treasuryApolloClient = treasuryApolloClient;
