import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { shade, tint } from 'polished';
import { Link } from 'react-router-dom';
import { LinkButton } from '../../buttons/link-button';
import { media } from '../../global/media-query';

const DisabledState = css`
  opacity: 0.2;
  pointer-events: none;
`;

/**
 * @deprecated
 */
export const FormLayout = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr min-content;
  grid-row-gap: 36px;
  align-items: center;
  max-width: 340px;
  width: 100%;
  height: 100%;

  ${media.xsmall} {
    max-width: 280px;
  }

  ${media.lowHeight} {
    padding-top: 32px;
    box-sizing: border-box;
  }
`;

/**
 * @deprecated
 */
export const FormBody = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 20px;
  width: 100%;
  & a {
    display: block;
  }
  & a button {
    width: 100%;
  }
`;
/**
 * @deprecated
 */
export const FormFooter = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 32px;
`;

export const FormFooterLine = styled.div<{ isDisabled?: boolean }>`
  ${({ isDisabled }) => isDisabled && DisabledState};
  margin-bottom: 16px;
  &:last-child {
    margin-top: 24px;
    margin-bottom: 0;
  }
`;

export const FormHeadIcon = styled.div`
  text-align: center;
  color: ${({ theme }) => shade(0.2, theme.colors.base3)};
`;

/**
 * @deprecated
 */
export const FormTitle = styled.h2`
  font-size: 24px;
  line-height: 1.4;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
`;

/**
 * @deprecated
 */
export const FormSubTitle = styled.p<{ isDisabled?: boolean }>`
  ${({ isDisabled }) => isDisabled && DisabledState};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.secondary};
  text-align: center;
`;

export const InlineBlockLink = styled(Link)`
  display: inline-block !important;
`;

export const FormError = styled.p`
  color: ${({ theme }) => tint(0.2, theme.colors.accent2)};
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
`;

export const FormErrorLinkButton = styled(LinkButton)`
  color: ${({ theme }) => tint(0.2, theme.colors.accent2)};
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => shade(0.1, theme.colors.accent2)};
  }
  &:active {
    color: ${({ theme }) => shade(0.5, theme.colors.accent2)};
  }
`;
