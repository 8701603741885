import * as React from 'react';
import { NString, TextSubtitle3RMMixin, WidgetLayout } from '@mobalytics/shared';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { paymentMethodLogo } from '../../../../../../utils/formatter/images';
import { PaypalButton } from '../paypal-button/paypal-button.component';
import { PaypalOverviewInfo } from '../paypal-overview-info/paypal-overview-info.component';
import { PaymentConfirmationLayout } from '../../../payment-confirmation-layout/payment-confirmation-layout.component';
import { TreasuryPaymentsGame } from '../../../../../../__generated/treasury/types';
import { AllowedActionsT } from '../../../../payment.types';
import { SecurePayments } from '../../../../secure-payments/secure-payments.component';
import { PaypalProvider } from './paypal-provider/paypal-provider.component';
import { usePaypalFlowNew } from './paypal-flow-new.hook';

interface Props {
  className?: string;
  planId: NString;
  game: TreasuryPaymentsGame;
  coupon: NString;
  allowedActions: AllowedActionsT;
  submitButtonLabel: string;
}

export const PaypalFlowNew: React.FC<Props> = props => {
  const { className, game, coupon, allowedActions, planId, submitButtonLabel } = props;
  const { t } = useTranslation();
  const { loading, step, tokenResponse, processPayment, onPaypalApprove } = usePaypalFlowNew({
    planId,
    game,
    coupon,
    allowedActions,
  });
  if (step === 'paypal-auth') {
    return (
      <div className={className}>
        <PaypalProvider>
          <WidgetLayout bodyOverrideCss={bodyOverrideCss} css={widgetLayoutCss}>
            <img src={paymentMethodLogo('paypal')} alt="paypal" css={logoImgCss} />
            <p css={paypalLabelCss}>{t('We will redirect you to PayPal to complete your purchase.')}</p>
          </WidgetLayout>
          <PaypalButton onApprove={onPaypalApprove} />
          <SecurePayments />
        </PaypalProvider>
      </div>
    );
  }
  if (step === 'billing-info' && tokenResponse) {
    return (
      <PaymentConfirmationLayout
        className={className}
        header={t('Your payment details')}
        loading={loading}
        buttonLabel={submitButtonLabel}
        onSubmit={processPayment}
        errorMessage={null}
      >
        <PaypalOverviewInfo
          email={tokenResponse.details.email}
          firstName={tokenResponse.details.firstName}
          lastName={tokenResponse.details.lastName}
        />
      </PaymentConfirmationLayout>
    );
  }
  return null;
};

const widgetLayoutCss = css`
  margin-bottom: 24px;
`;

const bodyOverrideCss = css`
  padding: 40px 20px;
`;

const paypalLabelCss = css`
  ${TextSubtitle3RMMixin};
  text-align: center;
`;

const logoImgCss = css`
  width: 85px;
  aspect-ratio: 84 / 24;
  margin: 0 auto 16px;
  display: block;
`;
