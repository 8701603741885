import { css } from '@emotion/react';
import { ButtonColor, Input, useOnClickOutside } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { ControllerFieldState } from 'react-hook-form';
import { AccountsButton } from '../../../../../ui/buttons/accounts-button/accounts-button.component';
import { CouponFormFields } from '../coupon.hook';

interface Props {
  className?: string;
  couponField: ControllerRenderProps<CouponFormFields, 'coupon'>;
  couponFieldState: ControllerFieldState;
  couponLoading: boolean;
  onApplyCoupon: () => void;
  onClickOutside: () => void;
}

export const ApplyCouponForm: React.FC<Props> = props => {
  const { className, couponField, couponFieldState, couponLoading, onApplyCoupon, onClickOutside } = props;
  const { t } = useTranslation();
  const ref = React.useRef<HTMLFormElement>(null);
  useOnClickOutside(ref, onClickOutside);

  return (
    <form className={className} css={wrapperCss} onSubmit={onApplyCoupon} ref={ref}>
      <Input
        id="couponId"
        errorMessage={couponFieldState.error?.message}
        placeholder={t('Enter code')}
        withError={!!couponFieldState.error?.message}
        {...couponField}
      />
      <AccountsButton
        analyticsButtonName="Apply coupon"
        color={ButtonColor.Secondary}
        loading={couponLoading && !!couponField.value}
      >
        {t('apply')}
      </AccountsButton>
    </form>
  );
};

const wrapperCss = css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`;
