import { filterNonNull, NString, validateStrEnumValue } from '@mobalytics/shared';
import * as queryString from 'query-string';
import { AppQueryParams, ConnectAccountInfo } from '../types/app.types';
import { Game, Languages, SingUpMarketingParams, Themes } from '../__generated/accounts/types';
import { Provider } from '../types/provider';
import { Theme } from '../types/theme';
import { Platform } from '../types/platform';

export function buildAbsoluteUrl(path: string) {
  return `${window.location.origin}${path}`;
}

export function getEmailsListFromUrl(): string[] | null {
  const queryParams = (queryString.parse(window.location.search) as { email: string[] | string | null | undefined })
    .email;
  if (queryParams) {
    if (typeof queryParams === 'string') {
      return [queryParams];
    }
    if (typeof queryParams === 'object' && queryParams.length) {
      return queryParams;
    }
  }
  return null;
}

function processProviders(providersStr: NString): Provider[] | null {
  if (providersStr) {
    const providers = providersStr.split(',').filter(p => validateStrEnumValue<Provider>(Provider, p));
    return providers.length ? (providers as Provider[]) : null;
  }
  return null;
}

export function getConnectInfoFromUrl(): ConnectAccountInfo[] | null {
  const infoStr = getEmailsListFromUrl();
  if (infoStr) {
    const result: ConnectAccountInfo[] = infoStr
      .map(str => {
        const [email, providersStr] = str.split(':');
        const providers = processProviders(providersStr);
        if (email) {
          return { email, providers };
        }
        return null;
      })
      .filter(filterNonNull);
    return result.length ? result : null;
  }
  return null;
}

export function parseAppQueryParams(): AppQueryParams {
  // todo: remove replace when emails templates fixed
  const searchStr = window.location.search.replace(/&amp;/g, '&');
  return (queryString.parse(searchStr, { decode: true }) as unknown) as AppQueryParams;
}

export function getAppQueryParams(): {
  redirectUri: string | null;
  theme: Theme;
  marketing: SingUpMarketingParams | null;
  mobaReferrerId: string | null;
} {
  const queryParams = parseAppQueryParams();
  const redirectUri = queryParams.redirect_uri || null;
  const theme = validateStrEnumValue<Theme>(Theme, queryParams.theme) || Theme.Default;
  const normalizedTheme = queryParams.theme === 'bacon' ? 'lor' : queryParams.theme;
  const mobaReferrerId = queryParams.mobaReferrerId || null;

  const marketing: SingUpMarketingParams = {
    game: validateStrEnumValue<Game>(Game, normalizedTheme?.toUpperCase()),
    theme: validateStrEnumValue<Themes>(Themes, normalizedTheme?.toUpperCase()),
    platform: queryParams.platform || Platform.WebApp,
    lang: validateStrEnumValue<Languages>(Languages, queryParams.lang?.toUpperCase()),
    version: queryParams.version,
  };

  return { redirectUri, theme, marketing: Object.keys(marketing).length > 0 ? marketing : null, mobaReferrerId };
}
