import { css } from '@emotion/react';
import { Tab, Tabs, TabsBasicVariant } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { AccountsAppPath } from '../../../types/app.types';
import { genSignInPath, genSignUpPath } from '../../../utils/formatter/path.formatter';

interface Props {
  className?: string;
  activeTab: AccountsAppPath;
}

export const AuthTabs: React.FC<Props> = props => {
  const { className, activeTab } = props;
  const { search } = useLocation();
  const [activeRouting, setActiveRouting] = React.useState(activeTab);
  const { t } = useTranslation();

  const tabItemClickHandler = React.useCallback(option => {
    setActiveRouting(option);
  }, []);

  return (
    <Tabs<AccountsAppPath>
      variant={TabsBasicVariant.Page}
      value={activeRouting}
      onChange={tabItemClickHandler}
      className={className}
    >
      <Tab value={AccountsAppPath.SIGN_IN} label={t('sign in')} css={tabCss} link={{ to: genSignInPath(search) }} />
      <Tab value={AccountsAppPath.SIGN_UP} label={t('sign up')} css={tabCss} link={{ to: genSignUpPath(search) }} />
    </Tabs>
  );
};

const tabCss = css`
  flex: 1 1 auto;
  justify-content: center;
  p:first-letter {
    text-transform: capitalize;
  }
`;
