import { css } from '@emotion/react';
import { HBoxMixin, NString } from '@mobalytics/shared';
import * as React from 'react';
import { media } from '../../global/media-query';

interface Props {
  children: React.ReactNode;
  className?: string;
  wallpaper?: NString;
  sideContent?: React.ReactNode;
}

export const PageLayout: React.FC<Props> = props => {
  const { children, className, wallpaper, sideContent } = props;
  return (
    <div css={wrapperCss} className={className}>
      <aside css={[sideContentCss, wallpaper && sideContentBgCss(wallpaper)]}>{sideContent}</aside>
      {children}
    </div>
  );
};

const wrapperCss = css`
  ${HBoxMixin};
  flex: 1 1 auto;
  width: 100%;
`;

const sideContentCss = css`
  width: 576px;
  flex: 0 0 auto;
  ${media.medium} {
    display: none;
  }
  ${media.xlarge} {
    width: 324px;
  }
`;

const sideContentBgCss = (bgImageUrl: string) => css`
  background: url(${bgImageUrl}) left center/cover no-repeat;
`;
