import * as React from 'react';

export const ErrorSign: React.FC<React.BaseHTMLAttributes<SVGElement>> = props => {
  return (
    <svg width={16} height={14} viewBox="0 0 16 14" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.882 12.702L8.754.432a.874.874 0 00-1.508 0L.118 12.703A.865.865 0 00.872 14h14.256c.67 0 1.09-.721.754-1.298zm-7.92-8.255c.49-.023.9.39.855.871L8.444 9.29a.432.432 0 01-.432.39.44.44 0 01-.439-.39l-.37-3.947a.814.814 0 01.758-.896zm.037 7.361a.817.817 0 01-.82-.814c0-.45.367-.814.82-.814.452 0 .82.364.82.814 0 .45-.368.814-.82.814z"
      />
    </svg>
  );
};
