import { ButtonColor } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AccountsButton } from '../../../buttons/accounts-button/accounts-button.component';
import { TreasuryPaymentsProduct } from '../../../../__generated/treasury/types';

interface Props {
  className?: string;
  planType: TreasuryPaymentsProduct;
  isBestChoice: boolean;
  onClick?: () => void;
}

export const NewButton: React.FC<Props> = props => {
  const { className, planType, isBestChoice, onClick } = props;
  const { t } = useTranslation();
  if (planType === TreasuryPaymentsProduct.AD_FREE) {
    return (
      <AccountsButton
        analyticsButtonName={'select ad-free plan'}
        className={className}
        onClick={onClick}
        color={ButtonColor.Secondary}
      >
        {t('remove ads')}
      </AccountsButton>
    );
  }
  return (
    <AccountsButton
      analyticsButtonName={'select plus plan'}
      className={className}
      onClick={onClick}
      color={isBestChoice ? ButtonColor.Plus : ButtonColor.Secondary}
    >
      {t('get plus')}
    </AccountsButton>
  );
};
