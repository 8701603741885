import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { colorTo6B6889Filter, figmaIcon, TextBody3RRMixin } from '@mobalytics/shared';

interface Props {
  prorateDiscount: string;
}

export const ProrateNotification = (props: Props) => {
  const { prorateDiscount } = props;
  const { t } = useTranslation();
  return (
    <div css={wrapperCss}>
      <img src={figmaIcon('system-icons', 'info')} alt="lock" css={iconCss} />
      {t('Switch to a higher plan? Your {{balance}} balance comes with you.', { balance: prorateDiscount })}
    </div>
  );
};

const wrapperCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  ${TextBody3RRMixin};
  color: var(--general-grey-300);
  text-align: center;
  margin-top: 24px;
`;

const iconCss = css`
  width: 16px;
  height: 16px;
  filter: ${colorTo6B6889Filter};
`;
