import { css } from '@emotion/react';
import { TextH4RMMixin } from '@mobalytics/shared';
import * as React from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const FormTitle: React.FC<Props> = props => {
  const { className, children } = props;
  return (
    <h2 css={wrapperCss} className={className}>
      {children}
    </h2>
  );
};

const wrapperCss = css`
  ${TextH4RMMixin};
  text-align: center;
`;
