import styled from '@emotion/styled';
import { shade } from 'polished';
import { Cross } from '../../icons/common/cross';
import { BaseButton } from '../base-button';

export const CloseButtonWrapper = styled(BaseButton)`
  width: 32px;
  height: 32px;
  background-color: #5e5585;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    opacity: 0.6;
  }
  &:hover:enabled {
    background-color: ${shade(0.25, '#5e5585')};
  }
`;

export const CloseIcon = styled(Cross)`
  width: 14px;
  height: 14px;
`;
