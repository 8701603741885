import { Button, ButtonProps } from '@mobalytics/shared';
import * as React from 'react';
import { AccountsButtonNameAnalytics } from './accounts-button.types';

interface Props extends ButtonProps<AccountsButtonNameAnalytics> {
  className?: string;
}

export const AccountsButton: React.FC<Props> = props => {
  const { className, ...rest } = props;
  return <Button<AccountsButtonNameAnalytics> className={className} {...rest} />;
};
