import { Region } from '@mobalytics/shared';
import { TFunction } from 'i18next';

export function formatRegionName(t: TFunction, region: Region): string {
  switch (region) {
    case Region.BR:
      return t('Brazil');
    case Region.EUNE:
      return t('Europe N&E');
    case Region.EUW:
      return t('Europe West');
    case Region.JP:
      return t('Japan');
    case Region.KR:
      return t('Korea');
    case Region.LAN:
      return t('LAN');
    case Region.LAS:
      return t('LAS');
    case Region.NA:
      return t('North America');
    case Region.OCE:
      return t('Oceania');
    case Region.PBE:
      return t('PBE');
    case Region.RU:
      return t('Russia');
    case Region.TR:
      return t('Turkey');
    case Region.SG:
      return t('Singapore');
    case Region.PH:
      return t('Philippines');
    case Region.TH:
      return t('Thailand');
    case Region.TW:
      return t('Taiwan');
    case Region.VN:
      return t('Vietnam');
    case Region.ALL:
      return t('All regions');
    case Region.UNKNOWN:
      return t('Unknown');
  }
}
