import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStore } from '../../../../store/app-store.hook';
import { ConnectAccountInfo } from '../../../../types/app.types';
import { FormError } from '../../../../ui/layout/form-layout';
import { FormSubTitle } from '../../../../ui/layout/form-layout/form-subtitle.component';
import { FormTitle } from '../../../../ui/layout/form-layout/form-title.component';
import { Radio } from '../../../../ui/radio/radio.component';
import { ConnectAccountBlock } from '../connect-account-block/connect-account-block.component';

interface Props {
  connectInfo: ConnectAccountInfo;
}

export const SingleEmailForm: React.FC<Props> = observer(props => {
  const { connectInfo } = props;
  const { email } = connectInfo;
  const { t } = useTranslation();
  const { inputStore } = useAppStore();
  const { generalError, emailError } = inputStore;
  const formError = generalError || emailError;

  return (
    <>
      <FormTitle>{t('ui-connect-moba-account')}</FormTitle>
      <FormSubTitle>{t('ui-connect-moba-account-description')}</FormSubTitle>
      {formError && <FormError>{t(formError)}</FormError>}
      {email && <Radio isActive={true} id="emailId" label={email}></Radio>}
      <ConnectAccountBlock connectInfo={connectInfo} />
    </>
  );
});
