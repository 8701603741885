import React from 'react';
import { commonIcons, formatRegionImage, Nullable, Region } from '@mobalytics/shared';

interface Props extends React.BaseHTMLAttributes<SVGElement> {
  region: Region;
}

export const RegionIcon: React.FC<Props> = props => {
  const { region, className } = props;
  const regionUrl = regionToUrl[region];
  if (!regionUrl) return null;
  return <img src={regionUrl} className={className} alt={region} />;
};

export const regionToUrl: Record<Region, Nullable<string>> = {
  [Region.BR]: formatRegionImage(Region.BR),
  [Region.EUNE]: formatRegionImage(Region.EUNE),
  [Region.EUW]: formatRegionImage(Region.EUW),
  [Region.JP]: formatRegionImage(Region.JP),
  [Region.KR]: formatRegionImage(Region.KR),
  [Region.LAN]: formatRegionImage(Region.LAN),
  [Region.LAS]: formatRegionImage(Region.LAS),
  [Region.NA]: formatRegionImage(Region.NA),
  [Region.OCE]: formatRegionImage(Region.OCE),
  [Region.RU]: formatRegionImage(Region.RU),
  [Region.TR]: formatRegionImage(Region.TR),
  [Region.SG]: formatRegionImage(Region.SG),
  [Region.PH]: formatRegionImage(Region.PH),
  [Region.TH]: formatRegionImage(Region.TH),
  [Region.TW]: formatRegionImage(Region.TW),
  [Region.VN]: formatRegionImage(Region.VN),
  [Region.PBE]: formatRegionImage(Region.PBE),
  [Region.UNKNOWN]: null,
  [Region.ALL]: commonIcons('all-option'),
};
