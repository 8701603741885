import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Marketing } from '../../../marketing/marketing';
import { genPaymentBillingPath } from '../../../utils/formatter/path.formatter';
import { getMixpanelPlanName } from '../utils/get-mixpanel-plan-name-utils';
import { PlanT } from '../payment.types';
import { usePaymentFilters } from '../hooks/payment-filters.hook';

export const usePlanSelection = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation();
  const { game } = usePaymentFilters();

  const handlePlanClick = (plan: PlanT) => {
    if (!game) return;
    const mixpanelName = getMixpanelPlanName(plan, game, t);
    Marketing.planSelected(mixpanelName);
    push(genPaymentBillingPath(plan.id, search));
  };

  return {
    handlePlanClick,
  };
};
