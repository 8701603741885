import * as React from 'react';
import { DesktopSignInRedirectForm } from '../../components/forms/desktop-sign-in-redirect-form/desktop-sign-in-redirect-form.component';
import { PageMeta } from '../../components/page-meta';
import { AuthPageLayout } from '../../ui/layout/auth-page-layout/auth-page-layout.component';

export const DesktopSignInRedirect: React.FC = () => {
  return (
    <AuthPageLayout>
      <PageMeta title="ui-desktop-signed-out" description="ui-desktop-signed-out-description" />
      <DesktopSignInRedirectForm />
    </AuthPageLayout>
  );
};
