import { css } from '@emotion/react';
import { linkButtonStyles, TextSubtitle3RMMixin } from '@mobalytics/shared';

export const formLinkButtonCss = css`
  ${linkButtonStyles};
  ${TextSubtitle3RMMixin};
  text-transform: initial;
  padding: 4px;
  transition: color 0.3s ease-out;
  cursor: pointer;

  &:hover {
    color: var(--general-gold-100);
  }
`;
