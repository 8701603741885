import { css } from '@emotion/react';
import { NString, Tag, TagSize, TagType } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Delimiter } from '../delimiter/delimiter.component';
import { DelimiterVariant } from '../delimiter/delimiter.types';

interface Props {
  className?: string;
  couponDiscount: NString;
  delimiterVariant: DelimiterVariant;
}

export const CouponController: React.FC<Props> = props => {
  const { className, couponDiscount, delimiterVariant } = props;
  const { t } = useTranslation();
  if (couponDiscount) {
    return (
      <Tag
        type={TagType.CUSTOM}
        value={t('coupon -{{discount}}', { discount: couponDiscount })}
        className={className}
        css={wrapperCss}
        size={TagSize.L}
      />
    );
  }
  return <Delimiter variant={delimiterVariant} />;
};

const wrapperCss = css`
  background: linear-gradient(90deg, #fb571e 0%, #f9942b 100%);
  align-self: center;
  margin-top: 16px;
  margin-bottom: 30px;
`;
