import { css } from '@emotion/react';
import { TextSubtitle3RMMixin } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface PromoGamersForGamersProps {
  className?: string;
}

export const PromoGamersForGamers: React.FC<PromoGamersForGamersProps> = function(props: PromoGamersForGamersProps) {
  const { className } = props;
  const { t } = useTranslation();
  return (
    <p css={wrapperCss} className={className}>
      {t('promo-games-for-gamers-start')}
      &nbsp; ❤️ &nbsp;
      {t('promo-games-for-gamers-end')}
    </p>
  );
};

const wrapperCss = css`
  ${TextSubtitle3RMMixin};
  color: var(--general-grey-300);
  text-align: center;
`;

export const iconCss = css`
  position: relative;
  top: 3px;
`;
