import { css } from '@emotion/react';
import { TextButton1CAPSRBMixin, TextH2OMMixin } from '@mobalytics/shared';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PlanT } from '../../../payment.types';
import { getCurrencySymbol } from '../../../utils/get-currency-symbol.utils';
import { getMoneyBaseUnit } from '../../../utils/get-money-base-unit.utils';

interface Props {
  className?: string;
  plan: PlanT;
}

export const PricePerMonth: React.FC<Props> = props => {
  const { className, plan } = props;
  const { t } = useTranslation();
  if (plan.isTrial) {
    return (
      <div css={wrapper} className={className}>
        <p css={wrapper}>
          <span css={priceCss}>{t('7-day free')}</span>
          <span css={durationLabel}> / {t('trial')}</span>
        </p>
      </div>
    );
  }
  return (
    <div css={wrapper} className={className}>
      <p css={priceWrapperCss}>
        <span css={currencyCss}>{getCurrencySymbol(plan.currency)}</span>
        <span css={priceCss}>{getMoneyBaseUnit(plan.finalPricePerMonth, true)}</span>
        <span css={durationLabel}> / {t('month')}</span>
      </p>
    </div>
  );
};

const wrapper = css`
  margin-top: 16px;
`;

const priceCss = css`
  ${TextH2OMMixin};
  font-weight: 500;
  margin-left: 4px;
`;

const currencyCss = css`
  ${TextButton1CAPSRBMixin};
  transform: translate(0, -8px);
`;

const priceWrapperCss = css`
  display: flex;
  align-items: center;
`;

const durationLabel = css`
  ${TextButton1CAPSRBMixin};
  text-transform: lowercase;
  transform: translateY(10px);
  margin-left: 4px;
`;
